
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';

import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import { Registration, RegistrationEventBookingNote } from '../../../store/pages/reception/types';
import { IBooking, IEvent, IEventReservation } from './types';
import { CustomerCategoryCount, CustomerSearchResult, ReservationType } from '../../../store/pages/diary/types';
import { clickHandler, generateTempId, getAge, isNullOrEmpty, parseLocalDateTime } from '../../../utils/util';
import ReservationInfoLabel from '../diary/reservationInfoLabel';
import Close from '../../icons/close';
import { Tag } from '../../../store/pages/tags/types';
import { SelectedRegistration } from '../diary/types';
import CreateEvent from '../diary/createEvent';
import ExistingBookingSelection from './existingBookingSelection';
import CompleteAddToBooking from './completeAddToBooking';

enum OverrideComponent { None, CreateEvent, SelectBooking, RegistrationCompletion }

const emptyReservation = { id: '', key: generateTempId(), startTime: new Date(), activityFormatId: '', activityFormatVariationId: '', activityFormatVariationScheduleId: '', resourceId: '', resourceConfigurationId: '', reservationType: ReservationType.NonExclusive };


interface Selection {
    registrationId: string;
    customerId: string;
    firstname: string;
    lastname: string;
    emailAddress: string;
    phoneNumber: string;
    companyName: string;
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    tags: Tag[];
}

interface ICustomerSearchResponse {
    customers: CustomerSearchResult[];
}

interface PeopleViewProps {
    venue: Venue;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
    registrations: Registration[];
    events: IEvent[];
    selectedKioskIds: string[];
    processing: boolean;
    addRegistrationsToBooking: (bookingId: string, registrationIds: string[]) => void;
    addBooking: (selectedRegistrations: SelectedRegistration[]) => void;
    findCustomers: (booking: IBooking) => void;
    showNotes: (notes: RegistrationEventBookingNote[], name: string, billNumber: string) => void;
    payIndividually: (bookingId: string, billId: string, customerId: string) => void;
    payRemaining: (bookingId: string, billId: string, amount: number) => void;
    editRegistration: (bookingId: string, registrationId: string) => void;
    checkIn: (bookingId: string, registrationId: string) => void;
    undoCheckIn: (bookingId: string, registrationId: string) => void;
    logout: () => void;
}

interface PeopleViewState {
    searchText: ct.FormValue<string>;
    customerSearchResults: CustomerSearchResult[];
    selectedCustomers: Selection[];
    searchingCustomers: boolean;
    sendingEmail: boolean;
    errorKey: string | null;
    message: string | null;
    overrideComponent: OverrideComponent;
    bookingId: string | null;
}

class PeopleView extends React.Component<PeopleViewProps, PeopleViewState> {

    saveResultTimeout: NodeJS.Timeout | null;
    saveResultVersion: number;

    constructor(props: PeopleViewProps) {
        super(props);

        this.saveResultTimeout = null;
        this.saveResultVersion = 1;

        this.state = {
            searchText: this.validateSearchText(''),
            customerSearchResults: [],
            searchingCustomers: false,
            selectedCustomers: [],
            sendingEmail: false,
            errorKey: null,
            message: null,
            overrideComponent: OverrideComponent.None,
            bookingId: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateSearchText = (val: string) => ct.asFormValue('searchText', val, true, false);

    searchCustomers = (search: string) => {
        const { venue } = this.props;
        const { t } = this.context;

        if (this.saveResultTimeout !== null) {
            clearTimeout(this.saveResultTimeout);
        }

        const searchText = this.validateSearchText(search);
        let searchMessage = null;

        this.setState({ searchingCustomers: searchText.isValid, searchText: searchText, message: searchMessage });

        if (searchText.isValid && searchMessage === null) {
            const ver = this.saveResultVersion + 1;
            this.saveResultVersion = ver;

            this.saveResultTimeout = setTimeout(() => {
                api.getWithAuth<ICustomerSearchResponse>(`api/v1/customer/search?searchTerm=${search}&venueId=${venue.id}`, this.props.logout)
                    .takeWhile(() => this.saveResultVersion === ver)
                    .subscribe(resp => {
                        this.setState({ searchingCustomers: false, customerSearchResults: resp.customers.map(c => ({ ...c, lastEvent: c.lastEvent ? { ...c.lastEvent, date: parseLocalDateTime(c.lastEvent.date) } : null, lastBooking: c.lastBooking ? { ...c.lastBooking, date: parseLocalDateTime(c.lastBooking.date) } : null })) });
                    }, e => {
                        this.setState({ searchingCustomers: false, customerSearchResults: [] });
                    });
            }, 500);
        }
    }

    selectCustomer = (customer: CustomerSearchResult) => {
        this.setState(prev => {
            var match = prev.selectedCustomers.find(c => c.customerId === customer.customerId);
            return {
                selectedCustomers: match
                    ? prev.selectedCustomers
                    : prev.selectedCustomers.concat({
                        customerId: customer.customerId,
                        firstname: customer.firstname,
                        lastname: customer.lastname,
                        companyName: customer.companyName,
                        emailAddress: customer.emailAddress,
                        phoneNumber: customer.phoneNumber,
                        registrationId: customer.lastCurrentRegistrationId || customer.lastRegistrationId || '',
                        birthDay: customer.birthDay,
                        birthMonth: customer.birthMonth,
                        birthYear: customer.birthYear,
                        tags: customer.tags
                    })
            }
        })
    }

    addSelectedRegistration = (registration: Registration) => {
        this.setState(s => ({
            selectedCustomers: s.selectedCustomers.findIndex(c => c.customerId === registration.customerId) < 0
                ? s.selectedCustomers.concat({
                    registrationId: registration.id,
                    customerId: registration.customerId,
                    firstname: registration.customerFirstname,
                    lastname: registration.customerLastname,
                    emailAddress: registration.emailAddress,
                    phoneNumber: registration.phoneNumber,
                    companyName: registration.companyName,
                    birthDay: registration.dateOfBirth ? registration.dateOfBirth.getDay() : 0,
                    birthMonth: registration.dateOfBirth ? registration.dateOfBirth.getMonth() + 1 : 0,
                    birthYear: registration.dateOfBirth ? registration.dateOfBirth.getFullYear() : 0,
                    tags: registration.customerTags
                })
                : s.selectedCustomers
        }))
    }

    removeRegistration = (customerId: string) => this.setState(s => ({ selectedCustomers: s.selectedCustomers.filter(c => c.customerId !== customerId) }))

    componentDidMount() {
        this.searchCustomers('');
    }

    customerRegistrationComplete = () => this.setState({ searchText: this.validateSearchText(''), customerSearchResults: [], selectedCustomers: [],  overrideComponent: OverrideComponent.None, bookingId: null });

    clearOverrideComponent = () => this.setState({ overrideComponent: OverrideComponent.None, bookingId: null })

    selectBooking = () => this.setState({ bookingId: null, overrideComponent: OverrideComponent.SelectBooking });

    createNewBooking = () => this.setState({ bookingId: null, overrideComponent: OverrideComponent.CreateEvent });

    addRegistrationsToBooking = (bookingId: string) => {
        const { addRegistrationsToBooking } = this.props;
        const { selectedCustomers } = this.state; 

        addRegistrationsToBooking(bookingId, selectedCustomers.map(c => c.registrationId));
        this.showCompletionStep(bookingId);
    }

    showCompletionStep = (bookingId: string) => this.setState({ bookingId: bookingId, overrideComponent: OverrideComponent.RegistrationCompletion });

    render() {
        const { registrations, events, venue, timeFormat, dateFormat, processing, showNotes, payIndividually, payRemaining, editRegistration, checkIn, undoCheckIn } = this.props;
        const { searchText, selectedCustomers, errorKey, message, overrideComponent, bookingId } = this.state;
        const { t } = this.context;

        if (overrideComponent === OverrideComponent.SelectBooking) {
            return <div className='at-panel scroll-panel flex-stretch flex '>
                <ExistingBookingSelection
                    timeFormat={timeFormat}
                    events={events}
                    registrations={registrations}
                    customersToRegister={selectedCustomers}
                    close={this.clearOverrideComponent}
                    bookingSelected={this.addRegistrationsToBooking}
                    showNotes={showNotes} />
            </div>
        } else if (overrideComponent === OverrideComponent.CreateEvent) {
            return <div className='at-panel scroll-panel flex-stretch flex'>
                <CreateEvent
                    venue={venue}
                    booking={null}
                    billItemsToReschedule={[]}
                    customerCategoryDefaults={[]}
                    bookedReservationIds={[]}
                    showSelectCustomer={false}
                    customer={selectedCustomers.length > 0 ? selectedCustomers[0] : undefined}
                    customersToRegister={selectedCustomers}
                    defaultCountryId={venue.countryId}
                    reservation={emptyReservation}
                    isSaving={false}
                    eventCreated={(eventIds: string[], bookingId: string | null) => {
                        if (bookingId) {
                            this.addRegistrationsToBooking(bookingId);
                        } else {
                            this.clearOverrideComponent();
                        }
                    }}
                    cancel={this.clearOverrideComponent} />
            </div>
        } else if (overrideComponent === OverrideComponent.RegistrationCompletion && bookingId) {
            return <div className='scroll-panel flex-stretch flex'>
                <CompleteAddToBooking
                    close={this.customerRegistrationComplete}
                    events={events}
                    registrations={registrations}
                    processing={processing}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    bookingId={bookingId}
                    showNotes={showNotes}
                    payIndividually={payIndividually}
                    payRemaining={payRemaining}
                    editRegistration={editRegistration}
                    checkIn={checkIn}
                    undoCheckIn={undoCheckIn}
                />
            </div>;
        }

        return <div className='reception-people-view'>
            <div style={{ overflow: 'hidden', marginRight: '5px', display: 'flex', flexDirection: 'column' }}>
                <h4 className='text-center' style={{ flex: '0 0 auto'}}>{t('ReceptionPage:registrationQueue')}</h4>
                <div className='scroll-panel' style={{ flex: '1 1 60%', maxHeight: '50%' }}>
                    {this.renderRegistrations(registrations)}
                </div>

                <div style={({ display: 'flex', flexDirection: 'column', margin: '10px 0 0 0', flex: '1 1 40%', overflow: 'hidden' })}>
                    <div style={{ flex: '0 0 auto' }}>
                        <h4 className='text-center' style={{ flex: '0 0 auto' }}>{t('ReceptionPage:findCustomers')}</h4>
                        <ct.TextBox id='searchText' labelKey='' placeholderKey='CustomerSearch:searchPlaceholder' value={searchText} callback={val => this.searchCustomers(val)} autoComplete='off' minimal={true} />
                    </div>
                    <div>
                        {errorKey
                            ? <div className='alert alert-danger'>{t(errorKey)}</div>
                            : message ? <div className='alert alert-info'>{message}</div> : null
                        }
                    </div>
                    {this.renderSearchResults()}
                </div>

            </div>
            <div style={{ overflow: 'hidden', marginLeft: '5px', display: 'flex', flexDirection: 'column' }}>
                <h4 className='text-center'>{t('ReceptionPage:customersToRegister')}</h4>
                <div className='scroll-panel'>
                    {this.registerSelectedCustomers()}
                    <></>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.selectBooking())} disabled={processing || selectedCustomers.length < 1}>{t('ReceptionPage:addToExistingBooking')}</button>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.createNewBooking())} disabled={processing || selectedCustomers.length < 1}>{t('ReceptionPage:addToNewBooking')}</button>
                </div>
            </div>
        </div>
    }

    renderNoResults = (searchingCustomers: boolean, searchText: string) => {
        const { t } = this.context;

        if (searchingCustomers) return <div className='alert alert-info'>{t('CustomerSearch:searching')}</div>;
        if (isNullOrEmpty(searchText)) return <div className='alert alert-info'>{t('CustomerSearch:enterSearch')}</div>;
        return null;
    }

    renderRegistrations = (registrations: Registration[]) => {
        const { t } = this.context;
        const { venue, selectedKioskIds } = this.props;

        const unassignedRegistrations = registrations.filter(r => r.bookings.length === 0 && (selectedKioskIds.length === 0 || selectedKioskIds.includes(r.kioskId))).sort(this.sortRegistrations);
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;

        return <table className='table table-hover customer-search-table table-condensed at-panel-bg'>
            <thead>
                <tr key='customer-search-header'>
                    <th></th>
                    <th>{t('CustomerSearch:nameHeading')}</th>
                    <th>{t('CustomerSearch:ageHeading')}</th>
                    <th>{t('CustomerSearch:kioskHeding')}</th>
                    <th>{t('CustomerSearch:customerCatHeding')}</th>
                </tr>
            </thead>
            <tbody>
                {unassignedRegistrations.length > 0
                    ? unassignedRegistrations.map(r => this.renderRegistration(r, {}, timeFormat))
                    : <tr key='no-unassigned-registrations'><td colSpan={5}><div className='alert alert-info text-center'>{t('ReceptionPage:emptyQueueMessage')}</div></td></tr>}
            </tbody>
        </table>
    }

    renderSearchResults = () => {
        const { customerSearchResults, searchingCustomers, searchText } = this.state;
        const { venue } = this.props;
        const { t } = this.context;

        const tableData = customerSearchResults.length === 0 && !searchingCustomers && !isNullOrEmpty(searchText.value)
            ? <tr><td colSpan={6}><div className='alert alert-warning'>{t('CustomerSearch:noCustomersFound')}</div></td></tr>
            : customerSearchResults.map(c => this.renderCustomerRow(c, venue));

        return (
            <div className='customer-search-results-wrapper'>
                <table className='table table-hover customer-search-table table-condensed at-panel-bg'>
                    <thead>
                        <tr key='customer-search-header'>
                            <th>{t('CustomerSearch:nameHeading')}</th>
                            <th>{t('CustomerSearch:ageHeading')}</th>
                            <th colSpan={2}>{t('CustomerSearch:emailHeading')}</th>
                            <th>{t('CustomerSearch:lastVisitHeading')}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </table>
            </div>
        );
    }

    renderCustomerRow = (customer: CustomerSearchResult, venue: Venue) => {

        let termsWarning: JSX.Element | null = null;
        if (!customer.hasAcceptedLatestTerms) {
            termsWarning = isNullOrEmpty(customer.lastRegistrationId) || venue.requireLatestTerms
                ? <span className='label label-danger'>{this.context.t('CustomerSearch:registrationRequired')}</span >
                : <span className='label label-warning'> {this.context.t('CustomerSearch:registrationNonCurrentWaiver')}</span >
        }

        return (
            <>
                <tr key={customer.customerId}>
                    <td>{this.formatCustomer(customer)}</td>
                    <td>{this.formatAge(customer)}</td>
                    <td colSpan={2} style={({ wordBreak: 'break-all' })}><div>{customer.emailAddress}</div></td>
                    <td>{customer.lastEvent ? customer.lastEvent.date.toShortDateString(venue.dateFormat) : null}</td>
                    <td></td>
                    {/*<td>{isNullOrEmpty(customer.lastCurrentRegistrationId) ? <button className='btn btn-info btn-xs' onClick={e => clickHandler(e, () => this.emailRegistrationLink(customer))}>{this.context.t('CustomerSearch:emailLink')}</button> : null}</td>*/}
                    <td className='text-right'>{this.canRegister(customer, venue) ? <button className='btn btn-primary btn-xs' onClick={e => clickHandler(e, () => this.selectCustomer(customer))}>{this.context.t('Global:select')}</button> : null}</td>
                </tr>
                <tr>
                    <td colSpan={3} className='table-second-row'>{[customer.addressLine1, customer.postalCode].filter(x => !isNullOrEmpty(x)).join(',')}</td>
                    <td colSpan={2} className='table-second-row text-right'>{customer.lastRegistrationTerms}</td>
                    <td colSpan={2} className='table-second-row text-right'>{termsWarning}</td>
                </tr>
            </>
        );
    }

    formatCustomer = (val: CustomerSearchResult | Selection | null) => val ? `${val.firstname} ${val.lastname}` : '';
    formatAge = (val: CustomerSearchResult | Selection | null) => {
        if (!val || val.birthYear === 0 || val.birthMonth === 0 || val.birthDay === 0)
            return '';

        const age = getAge(new Date(val.birthYear, val.birthMonth - 1, val.birthDay));
        return age === 0 ? '' : age.toString();
    }

    canRegister = (customer: CustomerSearchResult, venue: Venue) => {
        return !isNullOrEmpty(customer.lastCurrentRegistrationId) || (!venue.requireLatestTerms && !isNullOrEmpty(customer.lastRegistrationId));
    }

    registerSelectedCustomers = () => {
        const { selectedCustomers } = this.state;
        const { t } = this.context;
        return (
            <div className='customer-search-selected-customers-wrapper'>
                <table className='table table-hover customer-search-table table-condensed at-panel-bg'>
                    <thead>
                        <tr>
                            <th>{t('CustomerSearch:nameHeading')}</th>
                            <th>{t('CustomerSearch:ageHeading')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedCustomers.length > 0
                            ? selectedCustomers.map(c => (
                            <tr key={c.customerId}>
                                <td style={{ verticalAlign: 'middle' }}>{this.formatCustomer(c)}</td>
                                <td style={{ verticalAlign: 'middle' }}>{this.formatAge(c)}</td>
                                <td className='text-right'><button type='button' className='btn btn-plain' style={{ padding: '0', lineHeight: '1' }} onClick={e => clickHandler(e, () => this.removeRegistration(c.customerId))}><Close width={24} height={24} colour='red' /></button></td>
                            </tr>
                            )) : <tr key='no-selected-customers'><td colSpan={5}><div className='alert alert-info text-center'>{t('ReceptionPage:noCustomersSelected')}</div></td></tr>}
                    </tbody>
                </table>
            </div>
        );
    }
        
    renderRegistration = (registration: Registration, rowHeaderStyle: React.CSSProperties, timeFormat: TimeFormat) => {
        const { t } = this.context;

        return <tr key={registration.id} onClick={e => clickHandler(e, () => this.addSelectedRegistration(registration))}>
            <td style={rowHeaderStyle}>{registration.isWebRegistration ? t('Global:webBooking') : registration.registrationTime.toShortTimeString(timeFormat)}</td>
            <td>{registration.customerFirstname} {registration.customerLastname}</td>
            <td>{registration.age}</td>
            <td>{registration.kioskName}</td>
            <td>{registration.registeredForEvents.flatMap(e => e.customerCategories).reduce<string[]>((cats, e) => cats.includes(e.customerCategoryName) ? cats : cats.concat(e.customerCategoryName), []).join(" | ")}</td>
        </tr>
    }
    
    sortRegistrations = (r1: Registration, r2: Registration) => r2.registrationTime.getTime() - r1.registrationTime.getTime(); //  stringComparer(`${r1.customerFirstname} ${r1.customerLastname}`, `${r2.customerFirstname} ${r2.customerLastname}`);

    renderReservations = (event: IEvent) => {
        const { t } = this.context;
        const { timeFormat } = this.props;
        return event.reservations
            .filter(r => r.isBookable)
            .sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime())
            .map((r, ix) => <div key={`${event.id}_${r.reservationId}`} className='reception-reservation-header'>
                <div className='reception-reservation-time'>{r.startTime.toShortTimeString(timeFormat)}</div>
                <div className='reception-reservation-resource' style={{ borderLeft: `solid 3px ${r.resourceColour}` }}>{r.resourceShortName}</div>
                <div className='reception-reservation-type'>{t(r.reservationType === ReservationType.Exclusive ? 'Global:exclusiveShort' : 'Global:sharedShort')}</div>
                <div className='reception-reservation-name'>{r.activityFormat ? r.activityFormat.name : ''}</div>
            </div>)
    }

    renderReservationCounts = (r: IEventReservation) => this.renderCounts(r.bookedParticipants, r.registeredParticipants, r.checkedInParticipants, r.reservationType === ReservationType.Exclusive ? undefined : r.maxParticipants)

    renderCounts = (bookedParticipants: CustomerCategoryCount[], registeredParticipants: CustomerCategoryCount[], checkedInParticipants: CustomerCategoryCount[], maxParticipants?: number) => {

        const booked = bookedParticipants.reduce((ttl, c) => ttl + c.count, 0);
        const registered = registeredParticipants.reduce((ttl, c) => ttl + c.count, 0);
        const checkedIn = checkedInParticipants.reduce((ttl, c) => ttl + c.count, 0);

        const registeredCls = registered >= booked ? 'reception-event-count-complete' : '';
        const checkedInCls = checkedIn >= booked ? 'reception-event-count-complete' : '';

        return booked > 0
            ? <>
                <div key='booked' className='reception-reservation-booked'><ReservationInfoLabel quantity={maxParticipants ? booked : undefined} totalQuantity={maxParticipants ? maxParticipants : booked} icon='user' showWhenComplete={false} className='' /></div>
                <div key='registered' className='reception-reservation-registered'><ReservationInfoLabel quantity={registered || 0} totalQuantity={booked} icon='list-alt' showWhenComplete={false} className={registeredCls} /></div>
                <div key='checkedIn' className='reception-reservation-checked-in'><ReservationInfoLabel quantity={checkedIn || 0} totalQuantity={booked} icon='check' showWhenComplete={false} className={checkedInCls} /></div>
            </>
            : <div className='reception-counts-filler'></div>
    }
}

PeopleView.contextTypes = {
    t: PropTypes.func
}

export default PeopleView;