
import { Store, Middleware, MiddlewareAPI, Dispatch, Action } from 'redux';
import * as signalR from '@microsoft/signalr';

import { ApplicationState } from './index';
import { actionCreators as da } from './pages/diary/actions';
import { actionCreators as regAc } from './pages/reception/actions';
import { actionCreators as ta } from './pages/tags/actions';
import { actionCreators as sa } from './pages/status/actions';
import { actionCreators as mca } from './pages/campaigns/actions';
import { actionCreators as ba} from './pages/pointOfSale/actions';

import { LoginActions } from './pages/login/actions';
import * as lt from './pages/login/types';
import { mapUtcDate, parseLocalDateTime } from '../utils/util';

interface RegistrationMsg {
    venueId: string;
    eventId: string;
}

class SignalrClient {
    private store: Store<ApplicationState> | null;
    private connection: signalR.HubConnection | null;
    private clientId: number;

    constructor() {
        this.store = null;
        this.connection = null;
        this.clientId = 0;
    }

    initialize = (store: Store<ApplicationState>) => {
        this.store = store;
    }

    setClient = (clientId: number, attempt: number) => {
        if (this.connection) {
            const retryTimeout = Math.min(attempt, 10) * 2000;
            try {
                this.connection.send('setClient', clientId)
                    .then(res => {
                        console.log(`signalr: setClient succeeded (attempt ${attempt})`);
                    })
                    .catch(err => {
                        console.log(`signalr: setClient failed (attempt ${attempt}`);
                        setTimeout(() => this.setClient(clientId, attempt + 1), retryTimeout);
                    });
            } catch (err) {
                console.log(`signalr: setClient failed ${err} (attempt ${attempt}`);
                setTimeout(() => this.setClient(clientId, attempt + 1), retryTimeout);
            }
        }
    }

    handleAction = <A extends Action>(api: MiddlewareAPI, action: A) => {

        const loginAction = action as LoginActions;
        if (loginAction) {
            switch (loginAction.type) {
                case lt.LoginActionTypes.ReceiveUserClientInfo:
                    console.log('signalr: Handling ReceiveUserClientInfo');
                    if (loginAction.client) {
                        this.clientId = loginAction.client.id;
                        if (this.connection) {
                            this.setClient(loginAction.client.id, 0);
                        }
                    } else {
                        this.clientId = 0;
                    }
                    break;
            };
        }
    }

    start = (cnn: signalR.HubConnection, attempt: number) => {
        const retryTimeout = Math.min(attempt, 10) * 2000;

        try {
            if (this.store) this.store.dispatch(sa.setLastConnectionAttempt(new Date()));

            cnn.start()
                .then(res => {
                    console.log('signalr: connected');
                    if (this.store) this.store.dispatch(sa.setConnected());

                    if (this.clientId > 0) {
                        this.setClient(this.clientId, 1);
                    }
                })
                .catch(err => {
                    console.error(err.toString());
                    if (this.store) this.store.dispatch(sa.setDisconnected(err.message));

                    setTimeout(() => this.start(cnn, attempt + 1), retryTimeout);
                });
        } catch (err) {
            console.log(err);

            if (this.store) {
                const message = err instanceof Error  ? err.message : 'Unknown exception';
                this.store.dispatch(sa.setDisconnected(message));
            }

            setTimeout(() => this.start(cnn, attempt + 1), retryTimeout);
        }
    }


    connect(serverBaseUrl: string) {
        const cnn = new signalR.HubConnectionBuilder()
            .withUrl(`${serverBaseUrl}/apiEvents`)
            .configureLogging(signalR.LogLevel.Trace)
            .build();

        this.start(cnn, 1);

        cnn.onclose((err) => {
            if (this.store) this.store.dispatch(sa.setDisconnected(err && err.message ? err.message : 'Client disconncted'));

            this.start(cnn, 1)
        });

        cnn.on('event', (eventId: string, resources: string[], eventDate: Date | null) => {
            console.log(`Signalr: msg: event (${eventId}, ${resources}, ${eventDate})`);
            if (this.store) {
                this.store.dispatch(da.eventChanged(eventId, mapUtcDate(eventDate)));
                this.store.dispatch(sa.addMessage({time: new Date(), message: 'event changed' }));
            }
        });

        cnn.on('registration', (venueId: string) => {
            console.log(`Signalr: msg: registration (${venueId})`);
            if (this.store) {
                this.store.dispatch(regAc.registrationChanged(venueId));
                this.store.dispatch(sa.addMessage({ time: new Date(), message: 'registration added' }));
            }
        });

        cnn.on('tag', (tagId: string) => {
            console.log(`Signalr: msg: tag (${tagId})`);
            if (this.store) {
                this.store.dispatch(ta.tagChanged(tagId));
                this.store.dispatch(sa.addMessage({ time: new Date(), message: 'tag changed' }));
            }
        });

        cnn.on('marketingCampaign', (marketingCampaignId: string) => {
            console.log(`Signalr: msg: marketingCampaign (${marketingCampaignId})`);
            if (this.store) {
                this.store.dispatch(mca.campaigChanged());
                this.store.dispatch(sa.addMessage({ time: new Date(), message: 'marketing campaign changed' }));
            }
        });

        cnn.on('eventCustomers', (venueId: string, eventId: string, bookingDate: Date) => {
            console.log(`Signalr: msg: eventCustomers (${venueId})`);
            if (this.store) {
                const dt = mapUtcDate(bookingDate);
                this.store.dispatch(regAc.customerCheckedIn(venueId, dt));
                if (dt)
                    this.store.dispatch(regAc.eventsChanged(venueId, dt));
                this.store.dispatch(sa.addMessage({ time: new Date(), message: 'event customers changed' }));
            }
        });

        cnn.on('diaryNote', (venueId: string, date: Date) => {
            console.log(`Signalr: msg: diaryNote (${venueId}, ${date})`);
            if (this.store) {
                const dt = parseLocalDateTime(date);
                this.store.dispatch(da.diaryNoteChanged(venueId, dt));
                this.store.dispatch(sa.addMessage({ time: new Date(), message: 'diary note changed' }));
            }
        });

        cnn.on('openingTimes', (venueId: string, date: Date) => {
            console.log(`Signalr: msg: openingTimes (${venueId}, ${date})`);
            if (this.store) {
                const dt = parseLocalDateTime(date);
                this.store.dispatch(da.openingTimesChanged(venueId, dt));
                this.store.dispatch(sa.addMessage({ time: new Date(), message: 'opening times changed' }));
            }
        });

        cnn.on('bill', (billId: string) => {
            console.log(`Signalr: msg: bill (${billId})`);
            if (this.store) {
                this.store.dispatch(ba.billChanged(billId));
                this.store.dispatch(sa.addMessage({ time: new Date(), message: 'bill changed' }));
            }
        });
        this.connection = cnn;
    }
}

const client = new SignalrClient();


// https://patrickdesjardins.com/blog/how-to-create-a-typed-redux-middleware-in-typescript
export interface ExtendedMiddleware<TStateType> extends Middleware {
    <S extends TStateType>(api: MiddlewareAPI): (next: Dispatch) => Dispatch;
}

export const signalRMiddleware: ExtendedMiddleware<ApplicationState> = (api: MiddlewareAPI) =>
    (next: Dispatch) => <A extends Action>(action: A): A => {
        client.handleAction(api, action);
        return (next(action));
    }

export const initializeSignalR = (store: Store<ApplicationState>) => client.initialize(store);

export const connectSignalR = (serverBaseUrl: string) => client.connect(serverBaseUrl);