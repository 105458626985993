
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import * as api from '../../../../store/apiClient';

import { AjaxResponse } from 'rxjs/ajax';
import { BillPayment, GatewayPaymentStatus } from '../../../../store/pages/pointOfSale/types';
import { PaymentMethod } from '../../../../store/pages/paymentMethods/types';
import { clickHandler, isNullOrEmpty } from '../../../../utils/util';


interface ProcessStripePaymentProps {
    venueId: string;
    billId: string;
    payment: BillPayment;
    amount: number;
    paymentMethod: PaymentMethod;
    onSaveError: (saveError: api.ApiError) => void;
    onComplete: (billId: string) => void;
    onCancelled: (billId: string) => void;
    showAlert: (element: JSX.Element) => void;
    clearAlert: () => void;
}

interface ProcessStripePaymentState {
    initialized: boolean;
    paymentGatewayPaymentId: string | null;
    paymentAmount: number;
    token: string | null;
    publishabeKey: string | null;
    stripeUserId: string | null;
    clientSecret: string | null;
    processing: boolean;
    error: string | null;
}

interface GatewayPayment {
    paymentGatewayPaymentId: string;
}

interface ICreateStripeGatewayPaymentResponse {
    gatewayPayment: GatewayPayment;
    paymentAmount: number;
    token: string;
    publishabeKey: string;
    stripeUserId: string;
    clientSecret: string;
}

interface IGetGatewayPaymentResponse {
    status: GatewayPaymentStatus;
}

export default class ProcessStripePayment extends React.Component<ProcessStripePaymentProps, ProcessStripePaymentState> {

    constructor(props: ProcessStripePaymentProps) {
        super(props);

        this.state = {
            initialized: false,
            paymentGatewayPaymentId: null,
            paymentAmount: props.amount,
            token: null,
            publishabeKey: null,
            stripeUserId: null,
            clientSecret: null,
            processing: false,
            error: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        const { billId, payment, amount, paymentMethod } = this.props;

        this.initiateGatewayPayment(billId, payment, amount, paymentMethod);
    }

    initiateGatewayPayment = (billId: string, payment: BillPayment, amount: number, paymentMethod: PaymentMethod) => {
        const body = {
            venueId: this.props.venueId,
            billId: billId,
            billPaymentId: payment.id,
            paymentMethodId: paymentMethod.id,
            description: payment.description,
            isSecurityPayment: payment.isSecurityPayment,
            amount: amount,
            additionalInfo: payment.additionalInfo,
            offSession: true
        };

        const responseHaqndler = (response: AjaxResponse) => {
            const resp = response.response as ICreateStripeGatewayPaymentResponse;
            if (resp) {
                this.setState({
                    initialized: true,
                    paymentGatewayPaymentId: resp.gatewayPayment.paymentGatewayPaymentId,
                    paymentAmount: resp.paymentAmount,
                    token: resp.token,
                    publishabeKey: resp.publishabeKey,
                    stripeUserId: resp.stripeUserId,
                    clientSecret: resp.clientSecret
                });
            }
        }

        const errorHandler = (err: api.ApiError) => {
            this.props.onSaveError(err);
        };

        if (isNullOrEmpty(payment.id)) {
            api.postWithAuth(`api/v1/paymentGateway/payment/stripe`, body, () => ({ /* TODO: Handle auth error*/ })).subscribe(responseHaqndler, errorHandler);
        } else {
            api.putWithAuth(`api/v1/paymentGateway/payment/stripe`, body, () => ({ /* TODO: Handle auth error*/ })).subscribe(responseHaqndler, errorHandler);
        }
    }

    cancelStripePayment = () => {
        const { billId, onCancelled } = this.props;
        const { paymentGatewayPaymentId } = this.state;
        if (paymentGatewayPaymentId) {
            api.postWithAuth(`api/v1/paymentGateway/payment/stripe/${paymentGatewayPaymentId}/cancel`, {}, () => ({ /* TODO: Handle auth error*/ }))
                .subscribe(resp => onCancelled(billId), (err: api.ApiError) => this.setState({ error: err.message }));
        } else {
            onCancelled(billId);
        }
    }

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>, stripe: Stripe | null, elements: StripeElements | null) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        var cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            return;
        }

        this.setState({ processing: true, error: null });

        const { error } = await stripe.confirmCardPayment(this.state.clientSecret || '', {
            payment_method: { card: cardElement }
        });

        if (error) {
            this.setState({ error: error.message || '', processing: false })
        } else {
            this.handlePaymentComplete(this.state.token || '');
        }
    };

    handlePaymentComplete = (token: string, retryCouunt: number = 0) => {
        api.putWithAuth(`api/v1/paymentGateway/customerPayment/stripe/check`, { token: token }, () => ({ /* TODO: Handle auth error*/ }))
            .subscribe(resp => {
                const response = resp.response as IGetGatewayPaymentResponse;
                if (response.status === GatewayPaymentStatus.Success) {
                    this.props.onComplete(this.props.billId)
                } else if (response.status === GatewayPaymentStatus.InProgress) {
                    // wait a coupe of seconds for processing to complete
                    setTimeout(() => this.handlePaymentComplete(token, retryCouunt + 1), 2000);
                } else {
                    this.setState({ error: this.context.t('ProcessStripePayment:failed') })
                }
            }, err => this.setState({ error: err }));
    }

    render() {
        const { t } = this.context;
        const { initialized, error, paymentAmount, publishabeKey, stripeUserId, processing } = this.state;

        var body = initialized && publishabeKey && stripeUserId
            ? this.renderPayment(publishabeKey, stripeUserId)
            : this.renderInitializing();

        return <div className='pos-process-gateway-payment-panel'>
            <h4 className='text-center'>{t('PointOfSale:processingPaymentHeader')}</h4>

            <p />

            <div className='text-center mt-15' style={{ fontSize: '16px' }}>{`${t('PointOfSale:paymentAmount')}: ${t('Global:currencySymbol')}${paymentAmount.toFixed(2)}`}</div>

            <p />

            {body}

            {processing ? <div className='alert alert-info'>{t('ProcessStripePayment:processing')}</div> : null}
            {error ? <div className='alert alert-danger'>{error}</div> : null}
        </div>
    }

    renderInitializing = () => {
        const { t } = this.context;
        return <div className='row'><div className='col-xs-12'>{t('ProcessStripePayment:loading')}</div></div>
    }

    renderPayment = (publishableKey: string, stripeUserId: string) => {
        const { t } = this.context;
        const { processing } = this.state;
        const buttonStyle: React.CSSProperties = { margin: '0 8px' }
        return (
            <Elements stripe={loadStripe(publishableKey, { stripeAccount: stripeUserId })}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <form onSubmit={e => this.handleSubmit(e, stripe, elements)}>
                            <div className='row'>
                                <div className='col-xs-12'>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12'>
                                    <CardElement />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 btn-toolbar-centered mt-15'>
                                    <button className='btn btn-primary' type="submit" style={buttonStyle} disabled={!stripe || processing}>{t('ProcessStripePayment:pay')}</button>
                                    <button className='btn btn-default' type='button' style={buttonStyle} onClick={e => clickHandler(e, () => this.cancelStripePayment())}>{t('Global:cancel')}</button>
                                </div>
                            </div>
                        </form>

                    )}
                </ElementsConsumer>
            </Elements>
        )
    }
}