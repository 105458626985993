import { ValidationError } from '../../global/types';
import * as api from '../../apiClient';

export interface PaymentGatewaySetting {
    secret: boolean;
    optional: boolean;
}

export interface PaymentGatewaySettingsTypeMap {
    [key: string]: PaymentGatewaySetting;
}

export interface PaymentGatewayGlobalSetting {
    key: string;
    secure: boolean;
    hasValue: boolean;
    unsecuredValue: string | null;
    delete?: boolean;
}

export enum PaymentGatewayType {
    None = 0,
    Paymentsense = 1,
    Stripe = 2,
    LibertyPay = 3,
    WorldpayBg350 = 4
}

export interface PaymentGateway {
    id: number;
    name: string;
    type: PaymentGatewayType;
    settings: PaymentGatewaySettingsTypeMap;
    globalSettings: PaymentGatewayGlobalSetting[];
}

export interface PaymentGatewayState {
    isLoading: boolean;
    paymentGateways: PaymentGateway[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum PaymentGatewayActionTypes {
    RequestPaymentGateways = 'REQUEST_PAYMENT_GATEWAYS',
    ReceivedPaymentGateways = 'RECEIVE_PAYMENT_GATEWAYS',
    SavePaymentGateway = 'SAVE_PAYMENT_GATEWAY',
    PaymentGatewaySaved = 'PAYMENT_GATEWAY_SAVED',
    PaymentGatewaySaveFailed = 'PAYMENT_GATEWAY_SAVE_FAILED',
}


export interface LoadPaymentGateways {
    type: PaymentGatewayActionTypes.RequestPaymentGateways;
}


export interface ReceivePaymentGateways {
    type: PaymentGatewayActionTypes.ReceivedPaymentGateways;
    paymentGateways: PaymentGateway[];
    error: api.ApiError | null;
}

export interface SavePaymentGateway {
    type: PaymentGatewayActionTypes.SavePaymentGateway;
    paymentGatewayId: number;
    paymentGateway: PaymentGateway;
}

export interface PaymentGatewaySaved {
    type: PaymentGatewayActionTypes.PaymentGatewaySaved;
    paymentGatewayId: number;
}

export interface PaymentGatewaySaveFailed {
    type: PaymentGatewayActionTypes.PaymentGatewaySaveFailed;
    error: api.ApiError;
}