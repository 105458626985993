
import * as React from 'react';

import EventCustomerForm, { CustomerDetails } from './eventCustomerForm';
import CustomerSearch from './customerSearch';
import { generateTempId } from '../../../utils/util';
import { CustomerSearchResult, BookingCustomer } from '../../../store/pages/diary/types';
import { MarketingPreference, CustomerType, Gender } from '../../../store/pages/customer/types';

interface SearchCustomerPanelProps
{
    defaultCountryId: number;
    setAsOrganiser: boolean;
    addCustomer: (customer: BookingCustomer) => void;
    editCustomer: (customer: BookingCustomer) => void;
    close: () => void;
}

interface SearchCustomerPanelState {
    selectedCustomer: BookingCustomer;
    showCreateContact: boolean;
}

class SearchCustomerPanel extends React.Component<SearchCustomerPanelProps, SearchCustomerPanelState> {

    constructor(props: SearchCustomerPanelProps) {
        super(props);

        this.state = { selectedCustomer: this.createNewCustomer(props.setAsOrganiser), showCreateContact: false }
    }

    createNewCustomer = (isFirstContact: boolean) => {
        return {
            key: generateTempId(),
            customerId: '',
            customerType: isFirstContact ? CustomerType.Organiser : CustomerType.Competitor,
            firstname: '',
            lastname: '',
            companyName: '',
            emailAddress: '',
            phoneNumber: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            addressLine4: '',
            town: '',
            county: '',
            countryId: this.props.defaultCountryId,
            postalCode: '',
            isOrganiser: isFirstContact,
            gender: Gender.NotProvided,
            marketingPreference: MarketingPreference.None,
            resultsPreference: MarketingPreference.None,
            publicResultsConsent: true,
            emergencyContactName: '',
            emergencyContactNumber: '',
            hasActiveMembership: false,
            tags: [],
            notes: '',
            nickname: null
        }
    }

    contactSelected = (customer: CustomerSearchResult) => {
        var selectedCustomer = ({
            key: customer.customerId,
            customerId: customer.customerId,
            firstname: customer.firstname,
            lastname: customer.lastname,
            nickname: customer.nickname,
            customerType: customer.customerType,
            emailAddress: customer.emailAddress,
            phoneNumber: customer.phoneNumber,
            companyName: customer.companyName,
            addressLine1: customer.addressLine1,
            addressLine2: customer.addressLine2,
            addressLine3: customer.addressLine3,
            addressLine4: customer.addressLine4,
            town: customer.town,
            county: customer.county,
            countryId: customer.countryId,
            postalCode: customer.postalCode,
            isOrganiser: customer.isOrganiser,
            gender: customer.gender,
            marketingPreference: customer.marketingPreference,
            publicResultsConsent: customer.publicResultsConsent,
            emergencyContactName: customer.emergencyContactName,
            emergencyContactNumber: customer.emergencyContactNumber,
            hasActiveMembership: customer.hasActiveMembership,
            tags: customer.tags,
            notes: customer.notes
        });

        this.addCustomer(selectedCustomer);
    }

    createContact = () => {
        this.setState({ showCreateContact: true });
    }

    addCustomer = (customer: CustomerDetails) => this.props.addCustomer(this.customerToBooking(customer));

    editCustomer = (customer: CustomerDetails) => {
        this.props.editCustomer(this.customerToBooking(customer))
    }

    customerToBooking = (customer: CustomerDetails) => ({
        key: customer.key,
        customerId: customer.customerId,
        firstname: customer.firstname,
        lastname: customer.lastname,
        nickname: customer.nickname,
        customerType: customer.isOrganiser ? CustomerType.Organiser : CustomerType.Competitor,
        emailAddress: customer.emailAddress,
        phoneNumber: customer.phoneNumber,
        companyName: customer.companyName,
        addressLine1: customer.addressLine1,
        addressLine2: customer.addressLine2,
        addressLine3: customer.addressLine3,
        addressLine4: customer.addressLine4,
        town: customer.town,
        county: customer.county,
        countryId: customer.countryId,
        postalCode: customer.postalCode,
        gender: customer.gender,
        marketingPreference: customer.marketingPreference,
        publicResultsConsent: customer.publicResultsConsent,
        emergencyContactName: customer.emergencyContactName,
        emergencyContactNumber: customer.emergencyContactNumber,
        hasActiveMembership: customer.hasActiveMembership,
        tags: customer.tags,
        notes: customer.notes
    });

    render() {
        const {  showCreateContact, selectedCustomer } = this.state;
        const { close, setAsOrganiser } = this.props;

       if (!showCreateContact) {
            return <CustomerSearch customerSelected={this.contactSelected} createCustomer={this.createContact} cancel={close} />;
        } else {
            return <EventCustomerForm
                add={true}
                customer={({ ...selectedCustomer, isOrganiser: setAsOrganiser, birthYear: 0, birthMonth: 0, birthDay: 0 })}
                requirePhoneNumber={false}
                showIsOrganiser={true}
                defaultCountryId={this.props.defaultCountryId}
                canChangeDob={false}
                addCustomer={this.addCustomer}
                updateCustomer={this.editCustomer}
                cancel={close} />;
        }
    }
}

export default SearchCustomerPanel;