
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { ITranslationContext } from '../../../translations';
import { BillPayment, PaymentStatus } from '../../../store/pages/pointOfSale/types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface BillItemsPaymentRowProps {
    billCustomerId: string | null;
    payment: BillPayment;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    editPayment?: (payment: BillPayment) => void;
    makePayment?: (payment: BillPayment) => void;
}


const BillItemsPaymentRow = (props: BillItemsPaymentRowProps, context: ITranslationContext) => {
    const { payment, billCustomerId, timeFormat, dateFormat } = props;
    const { t } = context;

    const editPayment = props.editPayment ? props.editPayment : (p: BillPayment) => { }
    const makePayment = props.makePayment ? props.makePayment : (p: BillPayment) => { }

    const isScheduled = !payment.paid && !payment.void  && !payment.deleted && payment.scheduled && payment.status !== PaymentStatus.Success;
    const paymentDate = getPaymentDate(isScheduled, payment, timeFormat, dateFormat, context);
    const paymentButton = !payment.void && (payment.status === PaymentStatus.GatewayFailed || payment.status === PaymentStatus.GatewayCancelled || isScheduled)
        ? <div className='bill_item_actions'><button className='btn btn-primary' style={({ marginRight: '6px' })} onClick={e => clickHandler(e, () => makePayment(payment))}>{t('Global:pay')}</button></div> : null;

    const primaryText = payment.paid ? payment.paymentMethodName : payment.description;
    const additionalText = payment.paid && !isNullOrEmpty(payment.description) ? <span> - {payment.description}</span> : null;
    const amountStyle: React.CSSProperties = payment.paid && !payment.void ? {} : { color: '#bbb' };

    const isOverdue = !payment.paid && payment.paymentDueDate && payment.paymentDueDate.datePart() < new Date().datePart();
    const cls = isOverdue ? 'strong text-danger' : '';

    return <li key={payment.key} className={`pos_bill_item ${payment.void ? ' bg-danger' : ''}`}>
        <div className='bill-payment-item'>
            <button className="btn btn-link btn-no-padding full-width" onClick={e => clickHandler(e, () => editPayment(payment))}>
                <div className={`bill-payment-item-details ${cls}`}>
                    {isScheduled ? <span>{isOverdue ? t('Global:overdue') : t('Global:due')}: </span> : null}
                    <span>{paymentDate}</span>
                    <span className='bill-payment-item-name'>{primaryText}</span>
                    {payment.paidByCustomerId && payment.paidByCustomerId !== billCustomerId ? <span>({payment.paidByCustomerName})</span> : null }
                    {additionalText}
                    {renderPaymentAttemptInfo(payment, context)}
                    {payment.void ? <div className='text-danger'><span className='label label-danger'>{t('PointOfSale:paymentVoid')}</span> <strong>{payment.voidReason}</strong></div> : null}
                </div>
            </button>
        </div>
        {paymentButton}
        <div className='bill-payment-item-price'>
            <span className='bill_item_price' style={amountStyle}>{`${t('Global:currencySymbol')}${(payment.amount * -1).toFixed(2)}`}</span>
        </div>
    </li>
}

const asDate = (date: Date | null | undefined, dateFormat: DateFormat, context: ITranslationContext) => date ? date.toAbbrDateString(dateFormat, context.t) : '';
const asDateTime = (date: Date | null | undefined, timeFormat: TimeFormat, dateFormat: DateFormat, context: ITranslationContext) => date ? `${date.toAbbrDateString(dateFormat, context.t)} ${date.toShortTimeString(timeFormat)}` : '';

const getPaymentDate = (isScheduled: boolean, payment: BillPayment, timeFormat: TimeFormat, dateFormat: DateFormat, context: ITranslationContext) => {
    if (isScheduled) return asDate(payment.paymentDueDate, dateFormat, context);
    if (payment.void) return asDateTime(payment.voidedDateTime, timeFormat, dateFormat, context);
    if (payment.gatewayPayments.length > 0 && (payment.status === PaymentStatus.GatewayInProgress || payment.status === PaymentStatus.GatewayFailed || payment.status === PaymentStatus.GatewayCancelled)) {
        return asDateTime(payment.gatewayPayments.reduce<Date>((min, gp, i) => i === 0 || min > gp.gatewayPaymentDate ? gp.gatewayPaymentDate : min, new Date()), timeFormat, dateFormat, context);
    }

    return asDateTime(payment.payentTakenDateTime, timeFormat, dateFormat, context);
}

const renderPaymentAttemptInfo = (payment: BillPayment, context: ITranslationContext) => {
    const { t } = context;
    if (payment.void) return null;
    if (payment.status === PaymentStatus.GatewayInProgress) return <div><span className='label label-warning'>{t('PaymentStatus:GatewayInProgress')}</span></div>;
    if (payment.status === PaymentStatus.GatewayFailed) return <div><span className='label label-warning'>{t('PaymentStatus:GatewayFailed')}</span></div>;
    if (payment.status === PaymentStatus.GatewayCancelled) return <div><span className='label label-danger'>{t('PaymentStatus:GatewayCancelled')}</span></div>;
    return null;
}

BillItemsPaymentRow.contextTypes = {
    t: PropTypes.func
}

export default BillItemsPaymentRow;