
import * as React from 'react';
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as api from '../../../store/apiClient';
import * as LoginActions from '../../../store/pages/login/actions';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';

import moment from 'moment';

import { ApplicationState } from '../../../store';

import { PublicWebStep, PublicWebStepCategory } from '../../../store/pages/analytics/types';
import ApiError from '../../global/apiError';
import { isNullOrEmpty } from '../../../utils/util';

interface PublicWebsiteStats {
    steps: PublicWebsiteStepStats[];
} 

interface PublicWebsiteStepStats {
    step: PublicWebStep;
    category: PublicWebStepCategory;
    sessionCount: number;
    totalCount: number;
}

interface LocalProps { }

interface MappedReduxState {
    venueId: string;
}

interface MappedReduxActions {
    logout: () => void;
}

type AnalyticsPageProps = MappedReduxState & MappedReduxActions & LocalProps;

interface AnalyticsPageState {
    dateRange: ct.FormValue<ct.DateRange>
    category: PublicWebStepCategory;
    steps: PublicWebsiteStepStats[];
    error: api.ApiError | null;
}

class AnalyticsPage extends React.Component<AnalyticsPageProps, AnalyticsPageState> {
    searchTimeout: NodeJS.Timeout | null;

    constructor(props: AnalyticsPageProps) {
        super(props);

        this.searchTimeout = null;

        const now = moment();

        this.state = {
            dateRange: ct.asFormValue('dateRange', { from: now.clone().startOf('day'), to: now }),
            category: PublicWebStepCategory.Booking,
            steps: [],
            error: null
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(previousProps: AnalyticsPageProps) {
        if (this.props.venueId !== previousProps.venueId) {
            this.QueueReload();
        }
    }

    QueueReload = () => {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.load, 750);
    }

    load = () => {
        const { dateRange, category } = this.state;
        const { venueId, logout } = this.props;

        const { from, to } = dateRange.value;

        if (isNullOrEmpty(venueId)) {
            this.QueueReload();
            return;
        }

        if (from && to) {
            api.getWithAuth<PublicWebsiteStats>(`api/v1/analytics/${venueId}/pubwebstats?from=${from.toDate().toYMDHMDateString()}&to=${to.toDate().toYMDHMDateString()}&category=${category}`, logout)
                .subscribe(res => {
                    this.setState({ steps: res.steps, error: null });
                }, err => {
                    this.setState({ error: err });
                });
        }
    }

    onDateRangeChanged = (val: ct.DateRange) => this.setState({ dateRange: ct.asFormValue('dateRange', val) }, this.QueueReload);
    onCategoryChanged = (val: string) => {
        const intVal = parseInt(val);
        if (isNaN(intVal))
            return;

        this.setState({ category: intVal }, this.QueueReload);
    }
    validateDateRange = (val: ct.DateRange) => v.validate(val, 'dateRange', [], []);

    render() {
        const { t } = this.context;
        const { dateRange, category, steps, error } = this.state;

        const categoryOptions = [PublicWebStepCategory.Booking, PublicWebStepCategory.Registration].map(k => ({ key: k.toString(), name: t(`PublicWebStepCategory:${PublicWebStepCategory[k]}`) }));

        return <div>
            <h1>{t('AnalyticsPage:heading')}</h1>

            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <ct.DateRangePicker id='dateRange' labelKey='Analytics:dateRange' value={dateRange} dateFormat={'D/MM/YYYY'} timeFormat={'HH:mm'} callback={this.onDateRangeChanged} />
                <ct.Select id='category' labelKey='Analytics:category' style={{ marginLeft: '10px' }} value={ct.asFormValue('category', category.toString())} callback={this.onCategoryChanged} options={categoryOptions} />
            </div>

            { this.renderResults(steps, error)}
           
        </div>
    }

    renderResults = (steps: PublicWebsiteStepStats[], error: api.ApiError | null) => {
        const { t } = this.context;

        if (error) {
            return <ApiError error={ error} />
        }

        if (!steps || steps.length === 0)  {
            return <div className='alert alert-info'>{t('Analytics:noResults')}</div>
        }

        const max = steps.reduce((max, s) => Math.max(max, s.sessionCount), 0);

        return <table className='table table-condensed'>
            <tbody>
                {steps.sort((s1, s2) => s1.step - s2.step).map((s, ix) => <tr key={ix}>
                    <td className='shrink'>{t(`PublicWebStep:${PublicWebStep[s.step]}`)}</td>
                    <td className='expand'><div style={this.buildBarStyle(s, max)}>{s.sessionCount}</div></td>
                </tr>)}
            </tbody>
        </table>
    }

    buildBarStyle = (s: PublicWebsiteStepStats, max: number) => {
        const percentage = s.sessionCount / max * 100;
        return { width: '100%', color: '#FFF', paddingLeft: '8px', backgroundImage: `linear-gradient(to right, rgb(31,133,222) ${percentage}%, transparent ${percentage}% 100%)` }
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    venueId: state.venues.selectedVenueId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch),
});

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
