
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import Dropzone from 'react-dropzone';

import moment from 'moment';

import { VoucherProduct, VoucherProductRestrictionType } from '../../../store/pages/vouchers/types';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';
import { ApplicationState } from '../../../store';
import * as VoucherActions from '../../../store/pages/vouchers/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import { TaxRate } from '../../../store/pages/taxRates/types';
import { clickHandler, generateTempId, isNullOrEmpty } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { ImageFile, getImageProps } from '../../../utils/images';
import { Days, Interval, IntervalUnit, Time, ValidationError } from '../../../store/global/types';
import { Venue } from '../../../store/pages/venues/types';
import TagSelection from '../../global/tagSelection';
import { SpecialTagType, Tag } from '../../../store/pages/tags/types';
import { Product, ProductPricePoint, ProductTag } from '../../../store/pages/products/types';
import { ClientEmailTemplate, EmailType } from '../../../store/pages/emailTemplates/types';
import { stringComparer } from '../../../utils/comparers';
import { VenuePublicWebsiteSettings } from '../../../store/pages/publicWebsiteSettings/types';
import StandardProductPrice from '../venues/products/standardProductPrice';
import { hasPriceOverlap, validatePricing } from '../venues/products/pricingValidation';
import { ProdPrice, ProductFixedPricing } from '../venues/products/types';
import { ProductCategory } from '../../../store/pages/productCategories/types';
import { uniq } from 'lodash';

interface VenueSettings {
    venue: Venue;
    publicWebsiteIdsToSellOn: number[];
    sellOnPointOfSale: boolean;
}

interface MappedReduxState {
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    taxRates: TaxRate[];
    venues: Venue[];
    publicWebsiteSettings: VenuePublicWebsiteSettings[];
    voucherEmailTemplates: ClientEmailTemplate[];
    products: Product[];
    productCategories: ProductCategory[];
    tags: Tag[];
}

interface LocalProps {
    isNew: boolean;
    voucherProduct: VoucherProduct | null;
}

interface Actions {
    closeModal: () => void;
    saveVoucherProduct: (isNew: boolean, voucherProduct: VoucherProduct, productImg: File | null, voucherImg: File | null) => void;
}

type VoucherProductFormProps = MappedReduxState & Actions & LocalProps;

interface VoucherProductFormState {
    name: ct.FormValue<string>;
    description: ct.FormValue<string>;
    priceEffectiveDatesPricePoint: ct.FormValue<ProductPricePoint>
    pricing: ProdPrice[];
    taxRateId: ct.FormValue<string>;
    archived: ct.FormValue<boolean>;
    accountingCategory: ct.FormValue<string>;
    accountingDepartment: ct.FormValue<string>;
    nominalCode: ct.FormValue<string>;
    productImageUrl: string | null;
    productImage: ImageFile | null;
    redemptionAmount: ct.FormValue<number>;
    voucherValidFor: ct.FormValue<Interval>;
    showOnWebShop: ct.FormValue<boolean>;
    canRedeemOnline: ct.FormValue<boolean>;
    allowCustomPrice: ct.FormValue<boolean>;
    minPrice: ct.FormValue<number>;
    maxPrice: ct.FormValue<number>;
    priceIncrement: ct.FormValue<number>;
    maxQuantity: ct.FormValue<number>;
    activeFromDate: ct.FormValue<moment.Moment>;
    productInformation: ct.FormValue<string>;
    clientEmailTemplateId: ct.FormValue<string>;
    mustBeRedeemedBySingleCustomer: ct.FormValue<boolean>;
    productRestrictionType: ct.FormValue<VoucherProductRestrictionType>;
    restrictionIds: string[];
    tags: ProductTag[];
    venueSettings: VenueSettings[];
    productImageError: string | null;
    voucherImageUrl: string | null;
    voucherImage: ImageFile | null;
    voucherImageError: string | null;
    validationError: string | null;
    standardPricingError: string | null;
}

class VoucherProductForm extends React.Component<VoucherProductFormProps, VoucherProductFormState> {

    constructor(props: VoucherProductFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: VoucherProductFormProps): VoucherProductFormState {

        const { isNew, voucherProduct } = props;

        const allowCustomPrice = (isNew || !voucherProduct) ? false : voucherProduct.allowCustomPrice;
        const minPrice = (isNew || !voucherProduct) ? 1 : voucherProduct.minPrice;
        const maxPrice = (isNew || !voucherProduct) ? 100 : voucherProduct.maxPrice;
        const priceIncrement = (isNew || !voucherProduct) ? 0 : voucherProduct.priceIncrement;
        const maxQuantity = (isNew || !voucherProduct) ? 30 : voucherProduct.maxQuantity;

        return {
            name: this.validateName((isNew || !voucherProduct) ? '' : voucherProduct.name),
            description: this.validateDescription((isNew || !voucherProduct) ? '' : voucherProduct.description),
            priceEffectiveDatesPricePoint: this.validatePriceEffectiveDatesPricePoint(ProductPricePoint.PurchaseDate),
            pricing: (isNew || !voucherProduct) ? [this.createDefaultPrice(false, ProductPricePoint.PurchaseDate)] : voucherProduct.pricing.map(pp => ({
                key: pp.id,
                id: pp.id,
                isOverride: pp.isOverride,
                unitPrice: this.validatePricingUnitPrice(pp.unitPrice),
                fixedPricing: this.validatePricingFixedPricing(pp.fixedPricing.sort((p1, p2) => p1.minQuantity - p2.minQuantity).map(p => ({
                    minQuantity: p.minQuantity,
                    maxQuantity: p.maxQuantity,
                    price: p.price,
                    errorKey: null
                }))),
                effectiveFrom: this.validatePricingEffectiveFrom(moment(pp.effectiveFrom)),
                effectiveTo: this.validatePricingEffectiveTo(pp.effectiveTo ? moment(pp.effectiveTo) : null),
                effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pp.effectiveDatesPricePoint),
                tagId: this.validatePricingTagId(pp.tagId),
                appliesOnPricePoint: this.validatePricingAppliesOnPricePoint(pp.appliesOnPricePoint),
                applyPriceOnDays: pp.applyPriceOnDays,
                applyPriceFrom: this.validatePricingApplyPriceFrom(pp.applyPriceFrom),
                applyPriceTo: this.validatePricingApplyPriceTo(pp.applyPriceTo),
                archived: this.validatePricingArchived(pp.archived),
            })),
            taxRateId: this.validateTaxRate((isNew || !voucherProduct) ? '' : voucherProduct.taxRateId),
            archived: this.validateArchived((isNew || !voucherProduct) ? false : voucherProduct.archived),
            accountingCategory: this.validateAccountingCategory((isNew || !voucherProduct) ? '' : voucherProduct.accountingCategory),
            accountingDepartment: this.validateAccountingDepartment((isNew || !voucherProduct) ? '' : voucherProduct.accountingDepartment),
            nominalCode: this.validateNominalCode((isNew || !voucherProduct) ? '' : voucherProduct.nominalCode),
            redemptionAmount: this.validateRedemptionAmount((isNew || !voucherProduct) ? 0 : voucherProduct.redemptionAmount),
            voucherValidFor: this.validateVoucherValidFor((isNew || !voucherProduct) ? { value: 1, unit: IntervalUnit.Year } : voucherProduct.voucherValidFor),
            showOnWebShop: this.validateShowOnWebShop((isNew || !voucherProduct) ? false : voucherProduct.showOnWebShop),
            canRedeemOnline: this.validateCanRedeemOnline((isNew || !voucherProduct) ? true : voucherProduct.canRedeemOnline),
            allowCustomPrice: this.validateAllowCustomPrice(allowCustomPrice),
            minPrice: this.validateMinPrice(allowCustomPrice, minPrice),
            maxPrice: this.validateMaxPrice(allowCustomPrice, minPrice, maxPrice),
            priceIncrement: this.validatePriceIncrement(allowCustomPrice, priceIncrement),
            maxQuantity: this.validateMaxQuantity(allowCustomPrice, maxQuantity),
            productInformation: this.validateProductInformation((isNew || !voucherProduct) ? '' : voucherProduct.productInformation || ''),
            activeFromDate: this.validateActiveFromDate((isNew || !voucherProduct || !voucherProduct.activeFromDate) ? moment() : moment(voucherProduct.activeFromDate)),
            tags: (isNew || !voucherProduct) ? [] : voucherProduct.tags.filter(t => !t.archived),
            venueSettings: props.venues.filter(v => !v.archived).map(v => {
                const voucherVenueSetting = isNew || !voucherProduct ? null : voucherProduct.venueSettings.filter(s => s.venueId === v.id)[0];
                return {
                    venue: v,
                    sellOnPointOfSale: isNew || !voucherProduct || voucherProduct.venueSettings.findIndex(s => s.venueId === v.id && s.sellOnPointOfSale) >= 0,
                    publicWebsiteIdsToSellOn: voucherVenueSetting ? voucherVenueSetting.publicWebsiteIdsToSellOn : []
                };
            }),
            productImageUrl: (isNew || !voucherProduct) ? null : voucherProduct.productImageUrl,
            clientEmailTemplateId: this.validateClientEmailTemplateId((isNew || !voucherProduct) ? '' : voucherProduct.clientEmailTemplateId),
            mustBeRedeemedBySingleCustomer: this.validateMustBeRedeemedBySingleCustomer((isNew || !voucherProduct) ? false : voucherProduct.mustBeRedeemedBySingleCustomer),
            productRestrictionType: this.validateProductRestrictionType((isNew || !voucherProduct) ? VoucherProductRestrictionType.None : voucherProduct.productRestrictionType),
            restrictionIds: (isNew || !voucherProduct) ? [] : voucherProduct.restrictionIds,
            productImage: null,
            productImageError: null,
            voucherImageUrl: (isNew || !voucherProduct) ? null : voucherProduct.voucherImageUrl,
            voucherImage: null,
            voucherImageError: null,
            validationError: null,
            standardPricingError: null
        };
    }

    componentDidUpdate(prevProps: VoucherProductFormProps) {
        // Only update state is resource has changed
        const { voucherProduct: prevVoucherProduct, saveComplete: prevSaveComplete } = prevProps;
        const { voucherProduct, saveComplete } = this.props;
        if ((!prevVoucherProduct && voucherProduct) || (prevVoucherProduct && !voucherProduct) || (prevVoucherProduct && voucherProduct && prevVoucherProduct.id !== voucherProduct.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private saveProduct = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ validationError: null });
            // TODO: Show error message!
        } else {
            const voucherProductId = this.props.isNew || !this.props.voucherProduct ? null : this.props.voucherProduct.id;
            const productId = this.props.isNew || !this.props.voucherProduct ? null : this.props.voucherProduct.productId;
            const { isNew, saveVoucherProduct, publicWebsiteSettings } = this.props;
            const { name, description, taxRateId, archived, accountingCategory, accountingDepartment, nominalCode, redemptionAmount, voucherValidFor,
                showOnWebShop, allowCustomPrice, minPrice, maxPrice, priceIncrement, maxQuantity, productImage, voucherImage, venueSettings, activeFromDate,
                productInformation, canRedeemOnline, tags, clientEmailTemplateId, pricing, mustBeRedeemedBySingleCustomer, productRestrictionType, restrictionIds } = this.state;

            const publicWebsiteCount = publicWebsiteSettings.filter(s => !s.archived).length;

            if (pricing.filter(p => !v.isValid(p)).length > 0) {
                this.setState({ validationError: this.context.t('Global:formNotValid') });
                return;
            }

            // check for overlapping prices
            const pricingValidation = validatePricing(pricing.filter(p => !p.isOverride && !p.archived.value));
            if (!isNullOrEmpty(pricingValidation)) {
                this.setState({ standardPricingError: this.context.t(pricingValidation) });
                return;
            }

            saveVoucherProduct(isNew,
                {
                    id: voucherProductId || '',
                    productId: productId || '',
                    name: name.value,
                    description: description.value,
                    pricing: pricing.map(p => ({
                        id: p.id || '',
                        isOverride: p.isOverride,
                        unitPrice: p.unitPrice.value || 0,
                        fixedPricing: p.fixedPricing.map(fp => ({ minQuantity: fp.minQuantity, maxQuantity: fp.maxQuantity, price: fp.price })),
                        effectiveFrom: p.effectiveFrom.value.toDate(),
                        effectiveTo: p.effectiveTo.value ? p.effectiveTo.value.toDate() : null,
                        effectiveDatesPricePoint: p.effectiveDatesPricePoint.value,
                        tagId: p.tagId.value,
                        appliesOnPricePoint: p.appliesOnPricePoint.value,
                        applyPriceOnDays: p.applyPriceOnDays,
                        applyPriceFrom: p.applyPriceFrom.value,
                        applyPriceTo: p.applyPriceTo.value,
                        archived: p.archived.value,
                    })),
                    taxRateId: taxRateId.value,
                    accountingDepartment: accountingDepartment.value,
                    accountingCategory: accountingCategory.value,
                    nominalCode: nominalCode.value,
                    redemptionAmount: redemptionAmount.value,
                    voucherValidFor: voucherValidFor.value,
                    showOnWebShop: showOnWebShop.value,
                    canRedeemOnline: canRedeemOnline.value,
                    allowCustomPrice: allowCustomPrice.value,
                    minPrice: minPrice.value,
                    maxPrice: maxPrice.value,
                    priceIncrement: priceIncrement.value,
                    maxQuantity: maxQuantity.value,
                    activeFromDate: activeFromDate.value ? activeFromDate.value.toDate() : null,
                    venueSettings: venueSettings.map(s => ({ venueId: s.venue.id, publicWebsiteIdsToSellOn: publicWebsiteCount < 2 ? publicWebsiteSettings.filter(s => showOnWebShop.value).map(s => s.publicWebsiteId) : s.publicWebsiteIdsToSellOn, sellOnPointOfSale: s.sellOnPointOfSale })),
                    productInformation: productInformation.value,
                    mustBeRedeemedBySingleCustomer: mustBeRedeemedBySingleCustomer.value,
                    productRestrictionType: productRestrictionType.value,
                    restrictionIds: restrictionIds,
                    tags: tags,
                    clientEmailTemplateId: clientEmailTemplateId.value,
                    archived: archived.value,
                    productImageUrl: '',
                    voucherImageUrl: '',
                    taxRate: 0,
                    taxRateName: '',
                    version: 0
                },
                productImage ? productImage.file : null,
                voucherImage ? voucherImage.file : null);

            this.setState({ validationError: null, standardPricingError: null });
        }
    }

    taxRateChanged = (newValue: string) => {
        this.setState({ taxRateId: this.validateTaxRate(newValue) });
    }

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateDescription = (val: string) => v.validate(val, 'description', [v.required], this.props.validationErrors);
    validateUnitPrice = (val: number) => v.validate(val, 'unitPrice', [v.numeric, v.requiredNonZero], this.props.validationErrors);
    validateTaxRate = (val: string) => v.validate(val, 'taxRate', [v.required], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);
    validateAccountingCategory = (val: string) => v.validate(val, 'accountingCategory', [], this.props.validationErrors);
    validateAccountingDepartment = (val: string) => v.validate(val, 'accountingDepartment', [], this.props.validationErrors);
    validateNominalCode = (val: string) => v.validate(val, 'nominalCode', [], this.props.validationErrors);
    validateRedemptionAmount = (val: number) => v.validate(val, 'redemptionAmount', [v.numeric, v.requiredNonZero], this.props.validationErrors);
    validateVoucherValidFor = (val: Interval) => v.validate(val, 'voucherValidFor', [v.required], this.props.validationErrors);
    validateShowOnWebShop = (val: boolean) => v.validate(val, 'showOnWebShop', [], this.props.validationErrors);
    validateCanRedeemOnline = (val: boolean) => v.validate(val, 'canRedeemOnline', [], this.props.validationErrors);
    validateAllowCustomPrice = (val: boolean) => v.validate(val, 'allowCustomPrice', [], this.props.validationErrors);
    validateMinPrice = (allowCustomPrice: boolean, val: number) => v.validate(val, 'minPrice', allowCustomPrice ? [v.requiredNonZero] : [], this.props.validationErrors);
    validateMaxPrice = (allowCustomPrice: boolean, minPrice: number, val: number) => v.validate(val, 'maxPrice', allowCustomPrice ? [v.required, (x: number) => val <= minPrice ? 'VoucherProductForm:maxPriceLessThanMinPrice' : undefined] : [], this.props.validationErrors);
    validatePriceIncrement = (allowCustomPrice: boolean, val: number) => v.validate(val, 'priceIncrement', allowCustomPrice ? [v.requiredNonZero] : [], this.props.validationErrors);
    validateMaxQuantity = (allowCustomPrice: boolean, val: number) => v.validate(val, 'maxQuantity', allowCustomPrice ? [] : [v.requiredNonZero], this.props.validationErrors);
    validateActiveFromDate = (val: moment.Moment) => v.validate(val, 'activeFromDate', [], this.props.validationErrors);
    validateProductInformation = (val: string) => v.validate(val, 'productInformation', [], this.props.validationErrors);
    validateClientEmailTemplateId = (val: string) => v.validate(val, 'clientEmailTemplateId', [v.required], this.props.validationErrors);
    validateMustBeRedeemedBySingleCustomer = (val: boolean) => v.validate(val, 'mustBeRedeemedBySingleCustomer', [], this.props.validationErrors);
    validateProductRestrictionType = (val: VoucherProductRestrictionType) => v.validate(val, 'productRestrictionType', [], this.props.validationErrors);
    validatePriceEffectiveDatesPricePoint = (val: ProductPricePoint) => v.validate(val, 'priceEffectiveDatesPricePoint', [], this.props.validationErrors);
    validatePricingUnitPrice = (val: number | null) => v.validate(val, 'pricingUnitPrice', [v.numeric, v.required], this.props.validationErrors);
    validatePricingEffectiveFrom = (val: moment.Moment) => v.validate(val, 'pricingEffectiveFrom', [v.required], this.props.validationErrors);
    validatePricingEffectiveTo = (val: moment.Moment | null) => v.validate(val, 'pricingEffectiveTo', [], this.props.validationErrors);
    validatePricingEffectiveDatesPricePoint = (val: ProductPricePoint) => v.validate(val, 'pricingEffectiveDatesPricePoint', [], this.props.validationErrors);
    validatePricingTagId = (val: string | null) => v.validate(val, 'pricingTagId', [], this.props.validationErrors);
    validatePricingAppliesOnPricePoint = (val: ProductPricePoint) => v.validate(val, 'pricingAppliesOnPricePoint', [], this.props.validationErrors);
    validatePricingApplyPriceFrom = (val: Time | null) => v.validate(val, 'pricingApplyPriceFrom', [], this.props.validationErrors);
    validatePricingApplyPriceTo = (val: Time | null) => v.validate(val, 'pricingApplyPriceTo', [], this.props.validationErrors);
    validatePricingArchived = (val: boolean) => v.validate(val, 'pricingArchived', [], this.props.validationErrors);
    validatePricingFixedPricing = (fixedPricing: ProductFixedPricing[]) => {
        return fixedPricing.reduce<ProductFixedPricing[]>((result, p) => {
            let errorKey: string | null = null;

            if (p.maxQuantity < p.minQuantity) {
                errorKey = 'ProductForm:maxMustBeGreaterThanMin';
            } else if (p.price === 0) {
                errorKey = 'ProductForm:priceMustBeGreaterThanZero';
            }

            if (result.length > 0) {
                const prev = result[result.length - 1];
                if (p.minQuantity <= prev.maxQuantity) {
                    errorKey = 'ProductForm:quantitiesCannotOverlap'
                }
            }

            result.push({ ...p, errorKey: errorKey });
            return result;
        }, []);
    }

    onProductImageDrop = (files: File[]) => {
        const file = files[0];

        getImageProps(file).then(img => {
            if (img.width > 640 || img.height > 640) {
                this.setState({ productImageError: this.context.t('Global:imageWrongSize', { w: 640, h: 640 }) });
            } else {
                this.setState({ productImageError: null, productImage: { file: file, preview: URL.createObjectURL(file) } });
            }
        });
    }

    onVoucherImageDrop = (files: File[]) => {
        const file = files[0];

        getImageProps(file).then(img => {
            if (img.width > 640 || img.height > 640) {
                this.setState({ voucherImageError: this.context.t('Global:imageWrongSize', { w: 640, h: 640 }) });
            } else {
                this.setState({ voucherImageError: null, voucherImage: { file: file, preview: URL.createObjectURL(file) } });
            }
        });
    }

    onVenuePosSettingChanged = (venueId: string, checked: boolean) => {
        this.setState(s => ({ venueSettings: s.venueSettings.map(vs => vs.venue.id === venueId ? { ...vs, sellOnPointOfSale: checked } : vs) }))
    }

    onVenueWebShopSettingChanged = (venueId: string, publicWebsiteId: number, checked: boolean) => {
        this.setState(s => ({
            venueSettings: s.venueSettings.map(vs => {
                if (vs.venue.id !== venueId) return vs;
                let publicWebsiteIds = vs.publicWebsiteIdsToSellOn.filter(x => x !== publicWebsiteId);
                if (checked) publicWebsiteIds = publicWebsiteIds.concat(publicWebsiteId);
                return { ...vs, publicWebsiteIdsToSellOn: publicWebsiteIds }
            })
        }))
    }

    onActiveFromDateChanged = (date: moment.Moment | null) => {
        if (moment.isMoment(date)) {
            this.setState({ activeFromDate: this.validateActiveFromDate(date) });
        }
    }

    voucherEmailTemplateChanged = (val: string) => this.setState({ clientEmailTemplateId: this.validateClientEmailTemplateId(val) });
    productRestrictionTypeChanged = (val: string) => this.setState({ productRestrictionType: this.validateProductRestrictionType(parseInt(val)), restrictionIds: [] });

    effectiveFromChanged = (key: string, newDate: moment.Moment) => this.setState(s => {
        const newPricing = s.pricing.map(pp => pp.key === key ? { ...pp, effectiveFrom: this.validatePricingEffectiveFrom(newDate) } : pp);
        const standardPricingError = validatePricing(newPricing.filter(p => !p.isOverride && !p.archived.value));
        return { pricing: newPricing, standardPricingError: standardPricingError };
    })
    effectiveToChanged = (key: string, newDate: moment.Moment | null) => this.setState(s => {
        const newPricing = s.pricing.map(pp => pp.key === key ? { ...pp, effectiveTo: this.validatePricingEffectiveTo(newDate) } : pp);
        const standardPricingError = validatePricing(newPricing.filter(p => !p.isOverride && !p.archived.value));
        return { pricing: newPricing, standardPricingError: standardPricingError };
    })
    effectivePricePointChanged = (key: string, pricePoint: ProductPricePoint) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pricePoint) } : pp) }))
    unitPriceChanged = (key: string, unitPrice: number) => this.setState(s => ({ pricing: s.pricing.map(pp => pp.key === key ? { ...pp, unitPrice: this.validatePricingUnitPrice(unitPrice) } : pp) }))
    removePrice = (key: string) => this.setState(s => ({ pricing: s.pricing.filter(pp => pp.key !== key) }))

    restrictedItemChanged = (itemId: string, checked: boolean) => this.setState(s => ({ restrictionIds: checked ? s.restrictionIds.concat(itemId) : s.restrictionIds.filter(cid => cid !== itemId) }));

    createDefaultPrice = (isOverride: boolean, pricePoint: ProductPricePoint) => ({
        key: generateTempId(),
        id: '',
        isOverride: isOverride,
        unitPrice: this.validatePricingUnitPrice(null),
        fixedPricing: [],
        effectiveFrom: this.validatePricingEffectiveFrom(moment(new Date())),
        effectiveTo: this.validatePricingEffectiveTo(null),
        effectiveDatesPricePoint: this.validatePricingEffectiveDatesPricePoint(pricePoint),
        tagId: this.validatePricingTagId(null),
        appliesOnPricePoint: this.validatePricingAppliesOnPricePoint(pricePoint),
        applyPriceOnDays: Days.None,
        applyPriceFrom: this.validatePricingApplyPriceFrom(null),
        applyPriceTo: this.validatePricingApplyPriceTo(null),
        archived: this.validatePricingArchived(false)
    }) 

    addPrice = (isOverride: boolean) => this.setState(s => {
        const newPricing = s.pricing.concat(this.createDefaultPrice(isOverride, ProductPricePoint.PurchaseDate))
        const standardPricingError = hasPriceOverlap(newPricing.filter(p => !p.isOverride && !p.archived.value)) ? 'ProductForm:standardPricesCannotOverlap' : null;
        return { pricing: newPricing, standardPricingError: standardPricingError };
    })

    addTag = (tag: Tag) => this.setState(s => ({ tags: s.tags.concat([{ productTagId: '', tagId: tag.id, name: tag.name, colour: tag.colour, archived: tag.archived }]) }))

    removeTag = (tagId: string) => this.setState(s => ({ tags: s.tags.filter(t => t.tagId !== tagId) }))

    render() {

        let message: any;
        const { t } = this.context;
        const { name, description, taxRateId, archived, accountingCategory, accountingDepartment, nominalCode, productImageUrl, productImage,
            redemptionAmount, voucherValidFor, showOnWebShop, allowCustomPrice, minPrice, maxPrice, priceIncrement, maxQuantity, venueSettings,
            productImageError, voucherImageUrl, voucherImage, voucherImageError, validationError, activeFromDate, productInformation,
            canRedeemOnline, tags, clientEmailTemplateId, pricing, standardPricingError, mustBeRedeemedBySingleCustomer, productRestrictionType, restrictionIds } = this.state;
        const { saveError, saveComplete, taxRates, voucherEmailTemplates, isNew, publicWebsiteSettings } = this.props;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (validationError) {
            message = (<div className='alert alert-danger'>{validationError}</div>);
        }
        else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const productRestrictionTypeOptions = Object.keys(VoucherProductRestrictionType).filter(key => typeof VoucherProductRestrictionType[key as any] === 'number').map(key => ({ key: VoucherProductRestrictionType[key as any].toString(), name: t(`VoucherProductRestrictionType:${key}`) }));
        const taxRateOptions = [{ key: '', name: t('VoucherProductForm:selectTaxRate') }].concat(taxRates.map(c => ({ key: c.id, name: c.name })));

        let previewProductImg: JSX.Element | null = null;
        const prodImageUrl = productImage ? productImage.preview : productImageUrl !== null ? productImageUrl : null;
        if (prodImageUrl) {
            previewProductImg = <img src={prodImageUrl} className='file-preview' alt='preview' style={({ width: '100px'})}></img>;
        }

        let previewVoucherImg: JSX.Element | null = null;
        const newVoucherImageUrl = voucherImage ? voucherImage.preview : voucherImageUrl !== null ? voucherImageUrl : null;
        if (newVoucherImageUrl) {
            previewVoucherImg = <img src={newVoucherImageUrl} className='file-preview' alt='preview' style={({ width: '100px' })}></img>;
        }

        const showVenueWebShopSetting = showOnWebShop.value && (venueSettings.length > 1 || publicWebsiteSettings.filter(s => !s.archived).length > 1);
        const voucherEmailTemplateOptions = [{ key: '', name: t('Global:selectTemplate') }].concat(voucherEmailTemplates.map(et => ({ key: et.clientEmailTemplateId || '', name: et.name || '' })).filter(x => !isNullOrEmpty(x.key) && !isNullOrEmpty(x.name)));
        const today = moment();
        const currentlyActiveUnitPrice = pricing.length > 0
            ? pricing.filter(p => {
                return !p.archived.value && (!p.effectiveFrom.value || p.effectiveFrom.value.diff(today, 'days') <= 0) && (!p.effectiveTo.value || p.effectiveTo.value.diff(today, 'days') >= 0)
            })[0].unitPrice
            : this.validatePricingUnitPrice(0);

        return <div className='VoucherProductForm'>
            <h2 className='voucher_product_title'>{isNew ? t('VoucherProductForm:addVoucherProduct') : name.value}</h2>

            <form className='data-form' onSubmit={this.saveProduct} autoComplete='off'>
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <ct.TextArea id='description' labelKey='Global:description' placeholderKey='Global:description' value={description} callback={val => this.setState({ description: this.validateDescription(val) })} rows={4} />

                <label>{t('ProductForm:pricing')}</label>
                <table style={{ margin: '15px 0' }}>
                    <thead>
                        <tr>
                            <th>{t('ProductForm:priceEffectiveFrom')}</th>
                            <th style={{ minWidth: '140px' }}>{t('ProductForm:priceEffectiveTo')}</th>
                            <th>{t('Global:price')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pricing.filter(p => !p.isOverride).map(p => <StandardProductPrice
                            key={p.key}
                            isFixed={false}
                            productPrice={p}
                            effectiveFromChanged={this.effectiveFromChanged}
                            effectiveToChanged={this.effectiveToChanged}
                            unitPriceChanged={this.unitPriceChanged}
                            removePrice={this.removePrice}
                            fixedPricingChanged={(key: string, fp: ProductFixedPricing[]) => { } }
                            ref={null} />)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4}>
                                <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.addPrice(false))}>{t('ProductForm:addPrice')}</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                {standardPricingError ? <div className='alert alert-danger'>{t(standardPricingError)}</div> : null}

                <ct.NumberBox id='redemptionAmount' labelKey='VoucherProductForm:redemptionAmount' placeholderKey='VoucherProductForm:redemptionAmount' value={redemptionAmount} callback={val => this.setState({ redemptionAmount: this.validateRedemptionAmount(val || 0) })} step='0.01' min='0' />

                <ct.Select id='clientEmailTemplateId' labelKey='VoucherProductForm:voucherEmailTemplate' value={clientEmailTemplateId} callback={this.voucherEmailTemplateChanged} options={voucherEmailTemplateOptions} />


                <div style={{ borderBottom: 'solid 1px #999', margin: '15px 0', maxWidth: '400px' }}>
                    <h4>{t('VoucherProductForm:voucherRestrictionOptions')}</h4>
                </div>

                <ct.Checkbox id='mustBeRedeemedBySingleCustomer' labelKey='VoucherProductForm:mustBeRedeemedBySingleCustomer' value={mustBeRedeemedBySingleCustomer} callback={val => this.setState({ mustBeRedeemedBySingleCustomer: this.validateMustBeRedeemedBySingleCustomer(val) })} />
                <ct.Select id='productRestrictionType' labelKey='VoucherProductForm:productRestrictionType' value={{ ...productRestrictionType, value: productRestrictionType.value.toString() }} callback={this.productRestrictionTypeChanged} options={productRestrictionTypeOptions} />

                {this.renderProductRestrictionOptions(productRestrictionType.value, restrictionIds)}

                <div style={{ borderBottom: 'solid 1px #999', margin: '15px 0', maxWidth: '400px' }}>
                    <h4>{t('VoucherProductForm:webshopOptions')}</h4>
                </div>

                    <ct.Checkbox id='showOnWebShop' labelKey='VoucherProductForm:showOnWebShop' value={showOnWebShop} callback={val => this.setState({ showOnWebShop: this.validateShowOnWebShop(val) })} />

                    {this.renderWebShopOptions(showOnWebShop.value, currentlyActiveUnitPrice, redemptionAmount, allowCustomPrice, minPrice, maxPrice, priceIncrement, maxQuantity)}

                <ct.DatePicker id='activeFromDate' labelKey='VoucherProductForm:activeFrom' value={activeFromDate} callback={val => this.onActiveFromDateChanged(val)} />

                <ct.TimeInterval id='voucherValidFor' labelKey='VoucherProductForm:voucherValidFor' value={voucherValidFor} callback={val => this.setState({ voucherValidFor: this.validateVoucherValidFor(val) })} />

                <ct.Checkbox id='canRedeemOnline' labelKey='VoucherProductForm:canRedeemOnline' value={canRedeemOnline} callback={val => this.setState({ canRedeemOnline: this.validateCanRedeemOnline(val) })} />

                {this.renderVenueSettings(showVenueWebShopSetting, venueSettings)}

                <ct.HtmlInput id='productInformation' labelKey='VoucherProductForm:productInformation' value={productInformation} callback={val => this.setState({ productInformation: this.validateProductInformation(val)}) } />

                <p />

                <ct.Select id='taxRate' labelKey='VoucherProductForm:taxRate' value={({ ...taxRateId, value: taxRateId.value.toString() })} callback={this.taxRateChanged} options={taxRateOptions} />

                <label>{t('Global:tags')}</label>

                <div className='row'>
                    <div className='col-xs-12'>
                        <TagSelection selectedTags={tags.map(t => ({ id: t.tagId, name: t.name, colour: t.colour, archived: t.archived, specialTagType: SpecialTagType.None }))} canCreateTag={true} specialTagType={SpecialTagType.None} tagAdded={this.addTag} removeTag={this.removeTag} />
                    </div>
                </div>

                <ct.TextBox id='accountingCategory' labelKey='VoucherProductForm:accountingCategory' placeholderKey='VoucherProductForm:accountingCategory' value={accountingCategory} callback={val => this.setState({ accountingCategory: this.validateAccountingCategory(val) })} />

                <ct.TextBox id='accountingDepartment' labelKey='VoucherProductForm:accountingDepartment' placeholderKey='VoucherProductForm:accountingDepartment' value={accountingDepartment} callback={val => this.setState({ accountingDepartment: this.validateAccountingDepartment(val) })} />

                <ct.TextBox id='nominalCode' labelKey='VoucherProductForm:nominalCode' placeholderKey='VoucherProductForm:nominalCode' value={nominalCode} callback={val => this.setState({ nominalCode: this.validateNominalCode(val) })} />

                <label>{t('VoucherProductForm:productImage')}</label>
                <div>
                    <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onProductImageDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='file-drop'>
                                <input {...getInputProps()} />
                                <p>{t('Global:imageDropText')}</p>
                            </div>
                        )}
                    </Dropzone>
                    {previewProductImg}
                    {isNullOrEmpty(productImageError) ? null : <div className='alert alert-danger'>{productImageError}</div>}
                </div>

                <label>{t('VoucherProductForm:voucherImage')}</label>
                <div>
                    <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }} onDrop={this.onVoucherImageDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='file-drop'>
                                <input {...getInputProps()} />
                                <p>{t('Global:imageDropText')}</p>
                            </div>
                        )}
                    </Dropzone>
                    {previewVoucherImg}
                    {isNullOrEmpty(voucherImageError) ? null : <div className='alert alert-danger'>{voucherImageError}</div>}
                </div>

                <ct.Checkbox id='archived' labelKey='Global:archive' value={archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }

    renderWebShopOptions = (showOnWebShop: boolean, unitPrice: ct.FormValue<number | null>, redemptionAmount: ct.FormValue<number>, allowCustomPrice: ct.FormValue<boolean>, minPrice: ct.FormValue<number>, maxPrice: ct.FormValue<number>, priceIncrement: ct.FormValue<number>, maxQuantity: ct.FormValue<number>) => {
        if (!showOnWebShop) return null;

        if (unitPrice.value === redemptionAmount.value) {
            return <>
                <ct.Checkbox id='allowCustomPrice' labelKey='VoucherProductForm:allowCustomPrice' value={allowCustomPrice} callback={val => this.setState({ allowCustomPrice: this.validateAllowCustomPrice(val) })} />

                {allowCustomPrice.value
                    ? <>
                        <ct.NumberBox id='minPrice' labelKey='VoucherProductForm:minPrice' placeholderKey='VoucherProductForm:minPrice' value={minPrice} callback={val => this.setState({ minPrice: this.validateMinPrice(allowCustomPrice.value, val || 0) })} step='1' min='1' />
                        <ct.NumberBox id='maxPrice' labelKey='VoucherProductForm:maxPrice' placeholderKey='VoucherProductForm:maxPrice' value={maxPrice} callback={val => this.setState({ maxPrice: this.validateMaxPrice(allowCustomPrice.value, minPrice.value, val || 0) })} step='1' min='1' />
                        <ct.NumberBox id='priceIncrement' labelKey='VoucherProductForm:priceIncrement' placeholderKey='VoucherProductForm:priceIncrement' value={priceIncrement} callback={val => this.setState({ priceIncrement: this.validatePriceIncrement(allowCustomPrice.value, val || 0) })} step='1' min='1' />
                    </>
                    : <ct.NumberBox id='maxQuantity' labelKey='VoucherProductForm:maxQuantity' placeholderKey='VoucherProductForm:maxQuantity' value={maxQuantity} callback={val => this.setState({ maxQuantity: this.validateMaxQuantity(allowCustomPrice.value, val || 0) })} step='1' min='1' />}
            </>
        }

        return <ct.NumberBox id='maxQuantity' labelKey='VoucherProductForm:maxQuantity' placeholderKey='VoucherProductForm:maxQuantity' value={maxQuantity} callback={val => this.setState({ maxQuantity: this.validateMaxQuantity(allowCustomPrice.value, val || 0) })} step='1' min='1' />
    }

    renderProductRestrictionOptions = (productRestrictionType: VoucherProductRestrictionType, restrictionIds: string[]) => {
        switch (productRestrictionType) {
            case VoucherProductRestrictionType.None:
                return null;
            case VoucherProductRestrictionType.Categories:
                return this.renderProductCategoryRestrictionOptions(restrictionIds);
            case VoucherProductRestrictionType.Tags:
                return this.renderProductTagRestrictionOptions(restrictionIds);
            case VoucherProductRestrictionType.IndividualSelections:
                return this.renderSelectedProductRestrictionOptions(restrictionIds);
        }
    }

    renderProductCategoryRestrictionOptions = (restrictionIds: string[]) => {
        const { t } = this.context;
        const { productCategories, venues } = this.props;

        const activeVenues = venues.filter(v => !v.archived);
        const buildCategoryStyle = (category: ProductCategory) => ({ marginLeft: '5px', paddingLeft: '3px', borderLeft: `4px solid ${category.colour}` });

        return <div>
            <label>{t('VoucherProductForm:restrictedProductCategories')}</label>
            <ul className='plain-list'>
                {activeVenues.sort((v1, v2) => stringComparer(v1.name, v2.name)).map(v => <li key={`venue_${v.id}`}>
                    <label>{t('VoucherProductForm:venueCategories', {venueName: v.name}) }</label>
                    <ul className='plain-list' style={{paddingLeft: '15px'}}>
                    {productCategories.filter(c => !c.archived && c.venueId === v.id).sort((c1, c2) => stringComparer(c1.name, c2.name))
                    .map(c =>
                        <li key={c.id}>
                            <input id={`restriction_${c.id}`} type='checkbox' checked={restrictionIds.includes(c.id)} onChange={e => this.restrictedItemChanged(c.id, e.currentTarget.checked)} />
                            <label htmlFor={`restriction_${c.id}`} style={buildCategoryStyle(c)}>{c.name}</label>
                        </li>)}
                    </ul>
                 </li>)}
            </ul>
        </div>
    }

    renderProductTagRestrictionOptions = (restrictionIds: string[]) => {
        const { t } = this.context;
        const { products, tags } = this.props;
        var productTags = products
            .filter(p => !p.archived)
            .flatMap(p => p.tags.map(t => t.tagId).concat(p.pricing.filter(pr => pr.isOverride && !isNullOrEmpty(pr.tagId)).map(pr => pr.tagId as string)))
            .reduce<Tag[]>((uniqueTags, tagId) => {
                const tag = tags.find(t => t.id == tagId);
                return tag && !tag.archived && uniqueTags.findIndex(t => t.id === tag.id) < 0 ? uniqueTags.concat(tag) : uniqueTags;
            }, [])

        return <div>
            <label>{t('VoucherProductForm:restrictedProductTags')}</label>
            <ul className='plain-list'>
                {productTags.sort((t1, t2) => stringComparer(t1.name, t2.name))
                    .map(t =>
                        <li key={t.id}>
                            <input id={`restriction_${t.id}`} type='checkbox' checked={restrictionIds.includes(t.id)} onChange={e => this.restrictedItemChanged(t.id, e.currentTarget.checked)} />
                            <label htmlFor={`restriction_${t.id}`}><span className='label tag-label' style={({ backgroundColor: t.colour, marginLeft: '10px' })}>{t.name}</span></label>
                        </li>)}
            </ul>
        </div>
    }

    renderSelectedProductRestrictionOptions = (restrictionIds: string[]) => {
        const { t } = this.context;
        const { products, venues } = this.props;

        const activeVenues = venues.filter(v => !v.archived);

        return <div>
            <label>{t('VoucherProductForm:restrictedProducts')}</label>
            <ul className='plain-list'>
                {activeVenues.sort((v1, v2) => stringComparer(v1.name, v2.name)).map(v => <li key={`venue_${v.id}`}>
                    <label>{t('VoucherProductForm:venueProducts', { venueName: v.name })}</label>
                    <ul className='plain-list' style={{ paddingLeft: '15px' }}>
                        {products.filter(p => !p.archived && p.venueId === v.id).sort((p1, p2) => stringComparer(p1.name, p2.name))
                            .map(p =>
                                <li key={p.id}>
                                    <input id={`restriction_${p.id}`} type='checkbox' checked={restrictionIds.includes(p.id)} onChange={e => this.restrictedItemChanged(p.id, e.currentTarget.checked)} />
                                    <label htmlFor={`restriction_${p.id}`} style={{ marginLeft: '10px'}}>{p.name}</label>
                                </li>)}
                    </ul>
                </li>)}
            </ul>
        </div>
    }

    renderVenueSettings = (showVenueWebShopSetting: boolean, venueSettings: VenueSettings[]) => {
        const { t } = this.context;
        const { publicWebsiteSettings } = this.props;

        if (venueSettings.length === 1 && (!showVenueWebShopSetting || publicWebsiteSettings.filter(ws => !ws.archived).length < 2)) {
            const vs = venueSettings[0];
            return <ct.Checkbox id='sellOnPointOfSale' labelKey='VoucherProductForm:SellOnPointOfSaleHeader' value={ct.asFormValue('sellOnPointOfSale', vs.sellOnPointOfSale)} callback={val => this.onVenuePosSettingChanged(vs.venue.id, val)} />
        }

        return <table className='table table-condensed' style={{ maxWidth: '400px' }}>
            <thead>
                <tr key='heading'>
                    <th colSpan={showVenueWebShopSetting ? 3 : 2} className='text-center'>{t('VoucherProductForm:VenueSettingsHeader')}</th>
                </tr>
                <tr key='headers'>
                    <th>{t('VoucherProductForm:VenueHeader')}</th>
                    <th className='text-center'>{t('VoucherProductForm:SellOnPointOfSaleHeader')}</th>
                    {showVenueWebShopSetting ? <th className='text-center'>{t('VoucherProductForm:SellOnWebShopHeader')}</th> : null}
                </tr>
            </thead>
            <tbody>
                {venueSettings.map(v => {
                    const venuePublicWebsites = publicWebsiteSettings.filter(ws => ws.venueId === v.venue.id && !ws.archived) 
                    return <tr key={v.venue.id}>
                        <td>{v.venue.name}</td>
                        <td className='text-center'><input id={`${v.venue.id}_pos`} type='checkbox' checked={v.sellOnPointOfSale} onChange={e => this.onVenuePosSettingChanged(v.venue.id, e.currentTarget.checked)} /></td>
                        <td>
                            {showVenueWebShopSetting && venuePublicWebsites.length > 0
                                ? venuePublicWebsites.map(ws => <div>
                                    <label>
                                        <input id={`${v.venue.id}_${ws.publicWebsiteId}_webshop`} type='checkbox' checked={v.publicWebsiteIdsToSellOn.indexOf(ws.publicWebsiteId) >= 0} onChange={e => this.onVenueWebShopSettingChanged(v.venue.id, ws.publicWebsiteId, e.currentTarget.checked)} />
                                        {venuePublicWebsites.length > 1 ? <span style={{ marginLeft: 10 }}>{ws.publicWebsiteName}</span> : null}
                                    </label>
                                </div>)
                                : null  }
                        </td>
                    </tr>
                })}
            </tbody>
        </table>

    }
};


const mapStateToProps = (state: ApplicationState) => ({
    saveComplete: state.vouchers.saveComplete,
    saveError: state.vouchers.saveError,
    validationErrors: state.vouchers.validationErrors,
    taxRates: state.taxRates.taxRates,
    venues: state.venues.venues,
    publicWebsiteSettings: state.publicWebsiteSettings.publicWebsiteSettings,
    voucherEmailTemplates: state.emailTemplates.emailTemplates.filter(t => t.emailType === EmailType.Voucher && !t.archived).sort((t1,t2) => stringComparer(t1.name || '', t2.name || '')),
    products: state.products.products,
    productCategories: state.productCategories.productCategories,
    tags: state.tags.tags
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveVoucherProduct: bindActionCreators(VoucherActions.actionCreators.saveVoucherProduct, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch)
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(VoucherProductForm);
