
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../store';
import * as PropTypes from 'prop-types'

import moment from 'moment';

import * as ModalActions from '../../../store/global/modal/actions';
import * as LoginActions from '../../../store/pages/login/actions';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as gt from '../../../store/global/types';
import * as api from '../../../store/apiClient';

import * as bookingSvc from './services/bookingService';

import { ReservationType, CustomerSearchResult, DiaryReservation, EventStatus, OpeningTimes } from '../../../store/pages/diary/types';
import { Resource, buildResourceSelectionId, deconstructResourceSelectionId } from '../../../store/pages/resources/types';
import { clickHandler, generateTempId, isNullOrEmpty } from '../../../utils/util';
import { ActivityFormat, ActivityFormatVariation } from '../../../store/pages/activityFormats/types';
import { Product, ProductPricingMode } from '../../../store/pages/products/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import CustomerSearch from './customerSearch';
import EventCustomerForm, { CustomerDetails } from '../diary/eventCustomerForm';
import { Gender, MarketingPreference } from '../../../store/pages/customer/types';
import { DateFormat, getCloseHour, getOpenHour, TimeFormat, Venue } from '../../../store/pages/venues/types';
import { Time } from '../../../store/global/types';
import { getPricesForProduct, IActivityFormatProduct, sortPrices } from './helpers';
import { Booking, BookingCustomeField, BookingCustomFieldValue, CustomerCategoryDefault, ICustomer, IReservation, SelectedRegistration, WebBookingAvailabilitySlot } from './types';

import ApiError from '../../global/apiError';
import ReservationTypePanel from './reservationTypePanel';
import InlineCalendarResource from './inlineCalendarResource';
import ReservationSummary from './reservationSummary';
import { BillItem } from '../../../store/pages/pointOfSale/types';
import BookingExtraFields from './bookingExtraFields';
import { Membership } from '../../../store/pages/memberships/types';

enum Step {
    Selection = 1,
    Scheduling = 2
}

interface CustomerCategorySelection {
    customerCategoryId: string;
    customerCategoryName: string;
    quantity: ct.FormValue<number>;
    activityFormatProductId: string;
    productId: string;
    productPriceId: string;
    placesPerUnit: number;
    products: IActivityFormatProduct[];
}

interface MappedState {
    activityFormats: ActivityFormat[];
    resources: Resource[];
    openingTimes: OpeningTimes[];
    products: Product[];
    customerCategories: CustomerCategory[];
    memberships: Membership[];
}

interface Actions {
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    logout: () => void;
}

interface LocalProps {
    venue: Venue;
    showSelectCustomer: boolean;
    customer?: ICustomer;
    customersToRegister: SelectedRegistration[];
    reservation: IReservation;
    booking: Booking | null;
    bookedReservationIds: string[];
    billItemsToReschedule: BillItem[];
    customerCategoryDefaults: CustomerCategoryDefault[];
    defaultCountryId: number;
    createButtonTextKey?: string;
    isSaving: boolean;
    cancel: () => void;
    eventCreated: (eventIds: string[], bookingId: string | null) => void;
}

type CreateEventProps = MappedState & Actions & LocalProps;


interface CreateEventState {
    step: Step;
    reservationKey: string;
    formErrorKey: string;
    customerCategorySelections: CustomerCategorySelection[];
    resourceSelectionId: ct.FormValue<string>;
    startTime: ct.FormValue<moment.Moment | null>;
    activityFormatId: ct.FormValue<string>;
    activityFormatVariationId: ct.FormValue<string>;
    notes: ct.FormValue<string>;
    customer: ICustomer | null;
    customerWarning: string | null;
    selectedScheduleItemKey: string | null;
    processing: boolean;
    createReservationsError: api.ApiError | null;
    createReservationsErrorMessage: string | null;
    bookingId: string | null;
    availability: WebBookingAvailabilitySlot[];
    alternateSlots: WebBookingAvailabilitySlot[];
    reservations: DiaryReservation[];
    showCheckAvailability: boolean;
    bookingQuestions: BookingCustomFieldValue[];
}

const getStartTime = (billItemsToReschedule: BillItem[], reservation: IReservation) => {
    if (billItemsToReschedule.length > 0) {
        const reservationItems = billItemsToReschedule.filter(r => r.reservationStartTime !== null);
        if (reservationItems.length === 0) {
            return null;
        }
        const firstReservationItem = reservationItems.sort((i1, i2) => {
            if (i1.reservationStartTime !== null && i2.reservationStartTime !== null) {
                return i1.reservationStartTime.getTime() - i2.reservationStartTime.getTime()
            } else if (i1.reservationStartTime === null) {
                return 1;
            } else {
                return -1;
            }
        })[0];
        return firstReservationItem ? firstReservationItem.reservationStartTime : null;
    } else {
        return reservation.startTime;
    }
}

class CreateEvent extends React.Component<CreateEventProps, CreateEventState> {

    constructor(props: CreateEventProps) {
        super(props);

        const activityFormat = this.findActivityFormat(props.activityFormats, props.reservation.activityFormatId);
        const variation = this.findVariationForParticipants(activityFormat, props.customerCategories ? props.customerCategoryDefaults.reduce((ttl, c) => ttl + c.quantity, 0) : 0);

        const customer = props.customer || (props.booking ? props.booking.customer : null);
        var st = getStartTime(props.billItemsToReschedule, props.reservation);
        const startTime = this.validateStartTime(moment(st));

        const [categorySelections, , ,] = activityFormat && customer && !props.showSelectCustomer
            ? this.buildCustomerCategorySelections(activityFormat, [], customer, props.customerCategories.filter(c => c.venueId === props.venue.id && !c.archived), props.products.filter(p => p.venueId == props.venue.id && !p.archived), props.customerCategoryDefaults, props.billItemsToReschedule, startTime)
            : [[]];

        const showCheckAvailability = customer && activityFormat ? true : false;

        this.state = {
            step: Step.Selection,
            customer: customer,
            customerWarning: this.getCustomerWarning(customer, activityFormat),
            reservationKey: generateTempId(),
            formErrorKey: '',
            customerCategorySelections: categorySelections,
            resourceSelectionId: this.validateResourceSelection(this.buildResourceSelectionId(props.reservation.resourceId, props.reservation.resourceConfigurationId)),
            startTime: startTime,
            activityFormatId: this.validateActivityFormat(props.reservation.activityFormatId),
            activityFormatVariationId: this.validateActivityFormatVariation(variation ? variation.id : ''),
            notes: this.validateNotes(''),
            selectedScheduleItemKey: null,
            processing: false,
            createReservationsError: null,
            createReservationsErrorMessage: null,
            bookingId: null,
            showCheckAvailability: showCheckAvailability,
            availability: [],
            alternateSlots: [],
            reservations: [],
            bookingQuestions: []
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    // TODO: May need to move this to the avaiability check stage so we can check for active membership on the day of the event
    getCustomerWarning = (customer: ICustomer | null, activityFormat: ActivityFormat | undefined | null) => {
        return activityFormat
            && activityFormat.membershipTypeId
            && customer
            && customer.tags
            && customer.tags.filter(t => t.membershipTypeId === activityFormat.membershipTypeId).length === 0
            ? 'CreateEvent:customerNotMemberWarning'
            : null
    }

    componentDidMount() {
        const latestStartTime = this.state.startTime;
        if (latestStartTime.isValid && latestStartTime.value) {
            this.loadReservations(latestStartTime.value);
        }

        if (this.props.showSelectCustomer) {
            this.showSelectCustomer(true);
        }
    }

    componentDidUpdate(prevProps: CreateEventProps, prevState: CreateEventState) {
        const latestStartTime = this.state.startTime;
        const prevStartTime = prevState.startTime;

        if (latestStartTime.value && latestStartTime.isValid && latestStartTime.value.isValid()) {
            const prevDate = prevStartTime.value && prevStartTime.value.isValid() ? prevStartTime.value : moment({ year: 0, month: 0, day: 0 });

            if (latestStartTime.value.year() !== prevDate.year() || latestStartTime.value.dayOfYear() !== prevDate.dayOfYear()) {
                this.loadReservations(latestStartTime.value);
            }
        }
    }

    cancel = () => this.props.cancel();

    createEvent = () => {
        const { isSaving, logout, venue, eventCreated, booking, activityFormats, billItemsToReschedule } = this.props;
        const { availability, customerCategorySelections, customer, processing, bookingQuestions } = this.state;

        console.log(`Creating event isSaving: ${isSaving}, processing: ${processing}`)


        if (isSaving || processing) {
            console.log('Skipping createEvent ' + (isSaving ? 'Already saving' : 'Already processing'))
            return;
        }

        if (!v.isValid(this.state)) {
            this.setState({ formErrorKey: 'Global:formNotValid' });
        }
        else if (!availability.reduce((valid, a) => valid && a.isValid, true)) {
            this.setState({ formErrorKey: 'Global:formNotValid' });
        } else if (customer && customerCategorySelections.reduce((ttl, c) => ttl + c.quantity.value, 0) === 0) {
            this.setState({ createReservationsErrorMessage: 'CreateEvent:selectCustomerQuantities' });
        }
        else {
            const { resourceSelectionId, startTime, activityFormatId, activityFormatVariationId, notes } = this.state;
            const { resourceId, resourceConfigId } = deconstructResourceSelectionId(resourceSelectionId.value);

            const activityFormat = activityFormats.find(af => af.id === activityFormatId.value);
            if (!activityFormat) {
                this.setState(s => ({
                    createReservationsErrorMessage: 'CreateEvent:selectActibityFormat',
                    activityFormatId: { ...s.activityFormatId, isValid: false, errorMessageKey: 'CreateEvent:selectActibityFormat', hasValidation: true }
                }));
                return;
            }

            let variation = activityFormat ? activityFormat.variations.find(v => v.id === activityFormatVariationId.value) : null;
            if (!variation && activityFormat.variations.length === 1) {
                variation = activityFormat.variations[0];
            }

            if (!variation) {
                this.setState(s => ({
                    createReservationsErrorMessage: 'CreateEvent:selectActibityFormatVariation',
                    activityFormatVariationId: { ...s.activityFormatVariationId, isValid: false, errorMessageKey: 'CreateEvent:selectActibityFormatVariation', hasValidation: true }
                }));
                return;
            }

            if (!startTime.value) {
                this.setState(s => ({
                    createReservationsErrorMessage: 'CreateEvent:selectStartTime',
                    startTime: { ...s.startTime, isValid: false, errorMessageKey: 'CreateEvent:selectStartTime', hasValidation: true }
                }));
                return;
            }

            this.setState({ processing: true, createReservationsError: null })

            const customerId = customer ? customer.customerId : null;

            const body = {
                venueId: venue.id,
                resourceId: resourceId,
                resourceConfigurationId: resourceConfigId,
                activityFormatId: activityFormatId.value,
                activityFormatVariationId: activityFormatVariationId.value,
                date: startTime.value.toDate().toYMDDateString(),
                customerId: customerId,
                bookingId: booking ? booking.id : null,
                billItemIdsToReschedule: billItemsToReschedule.map(i => i.id),
                bookingNotes: notes.value,
                customerCategorySelections: customer ? customerCategorySelections.map(c => {
                    const prd = c.products.find(p => p.id === c.activityFormatProductId);
                    const price = prd ? prd.prices.find(pr => pr.productPrice.id === c.productPriceId) : null;
                    return {
                        customerCategoryId: c.customerCategoryId,
                        quantity: c.quantity.value,
                        placesToBookPerUnit: c.placesPerUnit,
                        activityFormatProductId: c.activityFormatProductId,
                        productId: c.productId,
                        productPriceId: c.productPriceId,
                        unitPrice: price ? price.unitPrice : null
                    }
                }) : [],
                reservations: availability.map(a => ({
                    eventName: a.name,
                    startTime: a.slotTime.toShortTimeString(),
                    duration: a.duration.toShortTimeString(),
                    bookingScheduleSequence: a.variationScheduleSequence,
                    eventId: a.eventId,
                    reservationId: a.reservationId,
                    reservationType: a.reservationType,
                    maxParticipants: a.maxParticipants
                })),
                customFieldValues: bookingQuestions.map(q => ({ customFieldName: q.customFieldName, type: q.type, value: q.value }))
            };

            api.postWithAuth(`api/v1/reservation`, body, logout)
                .subscribe(res => {
                    const { eventIds, bookingId } = res.response;
                    this.setState({ processing: false, createReservationsError: null }, () => eventCreated(eventIds, bookingId));
                }, err => {
                    this.setState({ processing: false, createReservationsError: err });
                })
        }
    }

    checkAvailability = () => {

        const { venue, logout, booking, activityFormats, reservation } = this.props;
        const { resourceSelectionId, activityFormatId, activityFormatVariationId, startTime, customerCategorySelections, customer } = this.state;

        const { resourceId, resourceConfigId } = deconstructResourceSelectionId(resourceSelectionId.value);

        const filteredActivities = this.filterActivities(activityFormats, resourceId, resourceConfigId, activityFormatId.value);
        const activityFormat = filteredActivities.find(f => f.id === activityFormatId.value);
        const showVariations = activityFormat && activityFormat.variations.length > 1;
        if (!resourceSelectionId.isValid || !startTime.isValid || !activityFormatId.isValid || (showVariations && !activityFormatVariationId.isValid)) {
            this.setState({ createReservationsErrorMessage: 'Global:formNotValid' });
            return;
        }

        if (customer && customerCategorySelections.reduce((qty, c) => qty + c.quantity.value, 0) === 0) {
            this.setState({ createReservationsErrorMessage: 'CreateEvent:selectCustomerQuantities' });
            return;
        }

        if (!startTime.value) {
            this.setState(s => ({
                createReservationsErrorMessage: 'CreateEvent:selectStartTime',
                startTime: { ...s.startTime, isValid: false, errorMessageKey: 'CreateEvent:selectStartTime', hasValidation: true }
            }));
            return;
        }

        this.setState({ processing: true, createReservationsError: null, bookingId: null, availability: [], selectedScheduleItemKey: null });

        const startDate = startTime.value.toDate();

        const body = {
            venueId: venue.id,
            bookingId: booking ? booking.id : null,
            resourceId: resourceId,
            resourceConfigurationId: resourceConfigId,
            activityFormatId: activityFormatId.value,
            activityFormatVariationId: activityFormatVariationId.value,
            date: startDate.toYMDDateString(),
            start: (startDate.getHours() * 60) + startDate.getMinutes(),
            customerCategorySelections: customerCategorySelections.map(c => ({ customerCategoryId: c.customerCategoryId, quantity: c.quantity.value })),
            customerId: customer ? customer.customerId : null,
            reservationId: reservation && !isNullOrEmpty(reservation.id) && reservation.activityFormatId === activityFormatId.value ? reservation.id : null
        }

        api.postWithAuth(`api/v1/booking/findManualBookingAvailability`, body, logout)
            .subscribe(res => {
                const { success, availableSlots, alternateSlots, bookingQuestions } = res.response;

                const mappedSlots: WebBookingAvailabilitySlot[] = availableSlots ? availableSlots.map((s: { slotTime: string; latestSlotTime: string | null; duration: string; reservationType: ReservationType; reservationId: string | null; }) => {
                    return {
                        ...s,
                        key: generateTempId(),
                        slotTime: Time.parse(s.slotTime),
                        latestSlotTime: s.latestSlotTime ? Time.parse(s.latestSlotTime) : null,
                        duration: Time.parse(s.duration),
                        reservationType: !s.reservationId && reservation && reservation.reservationType === ReservationType.Exclusive ? ReservationType.Exclusive : s.reservationType,
                        manualSelection: false
                    }
                }) : [];

                const mappedAlternateSlots: WebBookingAvailabilitySlot[] = alternateSlots ? alternateSlots.map((s: { slotTime: string; latestSlotTime: string | null; duration: string; }) => {
                    return {
                        ...s,
                        key: generateTempId(),
                        slotTime: Time.parse(s.slotTime),
                        latestSlotTime: s.latestSlotTime ? Time.parse(s.latestSlotTime) : null,
                        duration: Time.parse(s.duration),
                        manualSelection: false
                    }
                }) : [];

                const msg = mappedSlots.reduce((allOk, s) => allOk && s.hasRequiredSpaces, true) ? '' : 'CreateEvent:notAllSlotsAvailable';

                this.setState({
                    processing: false,
                    step: Step.Scheduling,
                    createReservationsError: null,
                    createReservationsErrorMessage: success ? null : msg,
                    availability: this.validateAvailability(mappedSlots),
                    alternateSlots: mappedAlternateSlots,
                    showCheckAvailability: false,
                    selectedScheduleItemKey: mappedSlots.length === 1 ? mappedSlots[0].key : null,
                    bookingQuestions: bookingQuestions.customFields.map((f: BookingCustomeField) => ({
                        customFieldName: f.name,
                        type: f.type,
                        description: f.description,
                        required: f.required,
                        values: f.values,
                        appliesTo: f.appliesTo,
                        value: ''
                    }))
                });
            }, err => {
                this.setState({ processing: false, createReservationsError: err, availability: [], alternateSlots: [] });
            })
    }

    filterActivities = (activityFormats: ActivityFormat[], resourceId: string, resourceConfigId: string, activityFormatId: string) =>
        activityFormats.filter(f => (f.resources.some(x => x.resourceId === resourceId && (f.id === activityFormatId || x.configurationIds.length === 0 || x.configurationIds.some(c => c === resourceConfigId)) && (f.customerCategories.length > 0) && (!f.archived))));

    validateAvailability = (availability: WebBookingAvailabilitySlot[]) => {
        return availability.map((itm, ix, arr) => {
            if (ix > 0 && itm.slotTime.val() <= arr[ix - 1].slotTime.val()) {
                return { ...itm, isValid: false, error: 'CreateEvent:timesOutOfSequence' }
            } else if (itm.duration.val() < 0) {
                return { ...itm, isValid: false, error: 'CreateEvent:endTimeBeforeStart' }
            } else {
                var rsv = this.state.reservations.find(r => r.id === itm.reservationId);
                const customerId = this.state.customer ? this.state.customer.customerId : '';
                if (rsv && this.isAlreadyBooked(rsv, customerId)) {
                    return { ...itm, isValid: false, error: 'CreateEvent:alreadyBooked' }
                }

                return { ...itm, isValid: true, error: null }
            }
        })
    }

    showOverlay = (component: JSX.Element, screenName: string) => this.props.showModal(component, screenName);

    closeOverlay = () => this.props.closeModal();

    showSelectCustomer = (preloadAvailability: boolean) => {
        this.showOverlay(<CustomerSearch customerSelected={cus => this.contactSelected(cus, preloadAvailability)} createCustomer={() => this.createContact(preloadAvailability)} cancel={this.closeOverlay} />, 'CustomerSearch');
    }

    contactSelected = (customer: CustomerSearchResult, preloadAvailability: boolean) => this.setCustomer(customer, preloadAvailability)

    setCustomer = (customer: ICustomer, preloadAvailability: boolean) => {
        this.setState((state, props) => {
            const activityFormat = this.findActivityFormat(props.activityFormats, state.activityFormatId.value);

            const [categorySelections, participants, ,] = activityFormat
                ? this.buildCustomerCategorySelections(activityFormat, [], customer, props.customerCategories.filter(c => c.venueId === props.venue.id && !c.archived), props.products.filter(p => p.venueId == props.venue.id && !p.archived), props.customerCategoryDefaults, props.billItemsToReschedule, state.startTime)
                : [[], 0, null, 0];

            return {
                customer: customer,
                customerCategorySelections: categorySelections,
                customerWarning: this.getCustomerWarning(customer, activityFormat),
                showCheckAvailability: activityFormat && (!customer || participants > 0) ? true : false,
            }
        }, () => {
            this.closeOverlay();

            if (preloadAvailability) {
                this.checkAvailability();
            }
        });
    }

    createContact = (preloadAvailability: boolean) => {
        const customer = { key: generateTempId(), customerId: '', companyName: '', firstname: '', lastname: '', emailAddress: '', phoneNumber: '', addressLine1: '', addressLine2: '', addressLine3: '', addressLine4: '', town: '', county: '', countryId: this.props.defaultCountryId, postalCode: '', isOrganiser: false, marketingPreference: MarketingPreference.None, publicResultsConsent: true, emergencyContactName: '', emergencyContactNumber: '', tags: [], notes: '', nickname: null, gender: Gender.NotProvided };
        this.showOverlay(<EventCustomerForm
            add={true}
            customer={{ ...customer, birthYear: 0, birthMonth: 0, birthDay: 0, hasActiveMembership: false }}
            requirePhoneNumber={false}
            showIsOrganiser={false}
            canChangeDob={false}
            defaultCountryId={this.props.defaultCountryId}
            addCustomer={cus => this.addCustomer(cus, preloadAvailability)}
            updateCustomer={this.editCustomer}
            cancel={this.closeOverlay} />, 'EventCustomerForm');
    }

    addCustomer = (customer: CustomerDetails, preloadAvailability: boolean) => {
        const { logout, venue } = this.props;

        const call = api.postWithAuth(`api/v1/customer/contact`, {
            venueId: venue.id,
            companyName: customer.companyName,
            firstname: customer.firstname,
            lastname: customer.lastname,
            emailAddress: customer.emailAddress,
            phoneNumber: customer.phoneNumber,
            isOrganiser: customer.isOrganiser,
            addressLine1: customer.addressLine1,
            addressLine2: customer.addressLine2,
            addressLine3: customer.addressLine3,
            addressLine4: customer.addressLine4,
            town: customer.town,
            county: customer.county,
            countryId: customer.countryId,
            postalCode: customer.postalCode,
            emergencyContactName: customer.emergencyContactName,
            emergencyContactNumber: customer.emergencyContactNumber,
            marketingPreference: customer.marketingPreference,
            notes: customer.notes,
            tags: customer.tags
        }, logout);

        call.subscribe(response => {
            const { customerId } = response.response;
            this.setCustomer({ ...customer, customerId: customerId }, preloadAvailability);
        },
            (err: api.ApiError) => {
                this.setState({ createReservationsError: err });
            });
    }

    loadReservations = (date: moment.Moment) => {
        const { logout, venue } = this.props;

        const venueId = venue.id;
        const selectedDate = date.toDate();

        bookingSvc.loadReservations(venueId, selectedDate, logout)
            .subscribe(reservations => this.setState({ reservations: reservations.filter(r => !r.cancelled && !r.deleted && !r.archived) }),
            err => { /* todo: show error */ });
    }

    editCustomer = (customer: CustomerDetails) => {

    }

    buildResourceSelectionId = (resourceId: string, configId: string | null) => isNullOrEmpty(configId) ? resourceId : `${resourceId}:${configId}`;

    findActivityFormat = (activityFormats: ActivityFormat[], activityFormatId: string) => activityFormats.find(f => f.id === activityFormatId);

    findVariationForParticipants = (activityFormat: ActivityFormat | null | undefined, participants: number) => {
        if (!activityFormat) return null;

        const sortedVariations = activityFormat.variations.sort((v1, v2) => v1.minParticipants - v2.minParticipants);
        const variation = sortedVariations.find(v => v.minParticipants <= participants && v.maxParticipants >= participants);

        return variation ? variation : sortedVariations[0];
    }

    getTypeFromActivityFormat = (activityFormat: ActivityFormat | undefined, participants: number, currentType: ReservationType) => {
        return activityFormat && participants > 0 ? activityFormat.minPlacesForExclusive ? ReservationType.Exclusive : ReservationType.NonExclusive : currentType;
    }

    numberOfParticipantsChanged = (customerCatgroryId: string, val: number) =>
        this.setState((state, props) => {
            const { customer, activityFormatId, customerCategorySelections, availability, activityFormatVariationId } = state;

            const updatedSelections = customerCategorySelections.map(c => c.customerCategoryId === customerCatgroryId ? { ...c, quantity: this.validateCategoryParticipants(c.customerCategoryId, val, false) } : c)
            const totalParticipants = updatedSelections.reduce((ttl, c) => ttl + c.quantity.value, 0);

            const activityFormat = this.findActivityFormat(props.activityFormats, activityFormatId.value);
            const variation = this.findVariationForParticipants(activityFormat, totalParticipants);
            const variationChanged = variation && variation.id !== activityFormatVariationId.value;

            const [categorySelections, , ,] = activityFormat
                ? this.buildCustomerCategorySelections(activityFormat, updatedSelections, customer, props.customerCategories.filter(c => c.venueId === props.venue.id && !c.archived), props.products.filter(p => p.venueId == props.venue.id && !p.archived), props.customerCategoryDefaults, props.billItemsToReschedule, state.startTime)
                : [[], 0, null, 0];

            return {
                showCheckAvailability: state.activityFormatId.isValid && (variationChanged || !state.customer || totalParticipants > 0),
                customerCategorySelections: categorySelections,
                activityFormatVariationId: variation ? this.validateActivityFormatVariation(variation.id) : activityFormatVariationId,
                availability: variationChanged ? [] : availability.map(a => a.reservationId ? a : { ...a, type: this.getTypeFromActivityFormat(activityFormat, totalParticipants, a.reservationType) }),
                alternateSlots: variationChanged ? [] : state.alternateSlots,
                step: variationChanged ? Step.Selection : state.step,
            };
        });

    productChanged = (customerCategoryId: string, activityFormatProductId: string) => this.setState((prev, props) => {
        const { customer, startTime } = this.state;

        const newCategorySelections: CustomerCategorySelection[] = prev.customerCategorySelections.map(c => {
            if (c.customerCategoryId === customerCategoryId) {
                const product = c.products.find(p => p.id === activityFormatProductId);
                if (product) {
                    const existingPrice = props.billItemsToReschedule ? props.billItemsToReschedule.find(i => i.productId === product.productId && i.customerCategoryId === c.customerCategoryId) : null;
                    const tags = product ? getPricesForProduct(product.product, c.quantity.value, new Date(), startTime.value ? startTime.value.toDate() : null, customer ? customer.tags : [], existingPrice ? existingPrice.unitPrice : null) : [];
                    return { ...c, activityFormatProductId: activityFormatProductId, productId: product.productId, prices: tags }
                }
            }
            return c
        });
        return ({ customerCategorySelections: newCategorySelections })
    });

    productPriceChanged = (customerCategoryId: string, activityFormatProductId: string, val: string) => this.setState(prev => ({ customerCategorySelections: prev.customerCategorySelections.map(c => c.customerCategoryId === customerCategoryId ? { ...c, productPriceId: val, products: c.products.map(pp => pp.id === activityFormatProductId ? {...pp, selectedPrice: val} : pp) } : c) }))

    resourceSelectionChanged = (resourceSelectionId: ct.FormValue<string>) => this.setState(state => this.handleSelectionChange(resourceSelectionId, state.startTime, state.activityFormatId, state.activityFormatVariationId, state))

    startTimeChanged = (startTime: moment.Moment | null) => this.setState(state => this.handleSelectionChange(state.resourceSelectionId, this.validateStartTime(startTime), state.activityFormatId, state.activityFormatVariationId, state))

    selectTime = (time: Time) => this.setState(state => {
        const newTime = state.startTime.value ? moment(state.startTime.value.clone().startOf('day').add(time.getHours() * 60 + time.getMinutes(), 'minutes')) : state.startTime.value;
        return this.handleSelectionChange(state.resourceSelectionId, this.validateStartTime(newTime), state.activityFormatId, state.activityFormatVariationId, state)
    }, () => this.checkAvailability() )

    activityFormatChanged = (activityFormatId: string) => {
        return this.setState((state, props) => {
            const { resourceSelectionId, startTime, customerCategorySelections, customer, availability } = state;

            const activityFormat = this.findActivityFormat(props.activityFormats, activityFormatId);

            const [categorySelections, participants, variation, maxParticipants] = activityFormat
                ? this.buildCustomerCategorySelections(activityFormat, customerCategorySelections, customer, props.customerCategories.filter(c => c.venueId === props.venue.id && !c.archived), props.products.filter(p => p.venueId == props.venue.id && !p.archived), props.customerCategoryDefaults, props.billItemsToReschedule, startTime)
                : [customerCategorySelections, 0, null, 0];

            const newState = this.handleSelectionChange(resourceSelectionId, startTime, this.validateActivityFormat(activityFormatId), this.validateActivityFormatVariation(variation ? variation.id : ''), state)
            return {
                ...newState,
                customerCategorySelections: categorySelections,
                showCheckAvailability: activityFormat && (!state.customer || participants > 0) ? true : false,
                availability: availability.map(a => a.reservationId ? a : { ...a, type: this.getTypeFromActivityFormat(activityFormat, participants, a.reservationType), maxParticipants: maxParticipants }),
                createReservationsError: null,
                createReservationsErrorMessage: null,
                selectedScheduleItemKey: null,
                customerWarning: this.getCustomerWarning(customer, activityFormat),
                alternateSlots: []
            };
        });
    }

    activityFormatVariationChanged = (activityFormatVariationId: string) =>
        this.setState((state, props) => {
            const { resourceSelectionId, startTime, activityFormatId, customerCategorySelections, availability } = state;
            const participants = customerCategorySelections.reduce((ttl, c) => ttl + c.quantity.value, 0);
            const activityFormat = this.findActivityFormat(props.activityFormats, activityFormatId.value);
            const variation = activityFormat ? activityFormat.variations.find(v => v.id === activityFormatVariationId) : null;
            const maxParticipants = this.calculateMaxParticipants(variation);

            const newState = this.handleSelectionChange(resourceSelectionId, startTime, activityFormatId, this.validateActivityFormatVariation(variation ? variation.id : ''), state)
            return {
                ...newState,
                availability: availability.map(a => a.reservationId ? a : { ...a, type: this.getTypeFromActivityFormat(activityFormat, participants, a.reservationType), maxParticipants: maxParticipants }),
                createReservationsError: null,
                createReservationsErrorMessage: null,
                selectedScheduleItemKey: null,
                alternateSlots: []
            };
        });

    handleSelectionChange = (resourceSelectionId: ct.FormValue<string>, startTime: ct.FormValue<moment.Moment | null>, activityFormatId: ct.FormValue<string>, activityFormatVariationId: ct.FormValue<string>, state: CreateEventState) => {
        const { customerCategorySelections, customer } = state;
        const { venue, customerCategories, products, customerCategoryDefaults, billItemsToReschedule } = this.props;

        const activityFormat = this.findActivityFormat(this.props.activityFormats, activityFormatId.value);

        const [newCustomerCategorySelections, participants, ,] = activityFormat
            ? this.buildCustomerCategorySelections(activityFormat, customerCategorySelections, customer, customerCategories.filter(c => c.venueId === venue.id && !c.archived), products.filter(p => p.venueId === venue.id && !p.archived), customerCategoryDefaults, billItemsToReschedule, startTime)
            : [customerCategorySelections, 0, null, 0];

        return {
            resourceSelectionId: resourceSelectionId,
            startTime: startTime,
            activityFormatId: activityFormatId,
            activityFormatVariationId: activityFormatVariationId,
            availability: [],
            alternateSlots: [],
            step: Step.Selection,
            customerCategorySelections: newCustomerCategorySelections,
            showCheckAvailability: activityFormat && (!state.customer || participants > 0) ? true : false,
        }
    }

    buildCustomerCategorySelections = (activityFormat: ActivityFormat, currentSelections: CustomerCategorySelection[], customer: ICustomer | null, customerCategories: CustomerCategory[], products: Product[], customerCategoryDefaults: CustomerCategoryDefault[], billItemsToReschedule: BillItem[], startTime: ct.FormValue<moment.Moment | null>)
        : [CustomerCategorySelection[], number, ActivityFormatVariation | null, number] => {
        const intermediateCategorySelections = activityFormat.customerCategories.map((cc, cix) => {
            const category = customerCategories.find(c => c.id === cc.customerCategoryId);

            const currentSelection = currentSelections.find(s => s.customerCategoryId === cc.customerCategoryId);

            let quantity = 0;
            if (currentSelection) {
                quantity = currentSelection.quantity.value;
            } else if (this.props.customersToRegister.length > 0 && cix === 0) {
                quantity = this.props.customersToRegister.length;
            } else {
                const defaultSelection = customerCategoryDefaults ? customerCategoryDefaults.find(d => d.customerCategoryId === cc.customerCategoryId) : null;
                if (defaultSelection) {
                    quantity = defaultSelection.quantity;
                } else {
                    quantity = activityFormat.customerCategories.length === 1 ? 1 : 0;
                }
            }

            const categoryProducts: IActivityFormatProduct[] = activityFormat.products
                .filter(p => p.customerCategories.indexOf(cc.customerCategoryId) >= 0)
                .map(ap => {
                    const prod = products.filter(p => p.id === ap.productId)[0];
                    const existingPrice = billItemsToReschedule && prod ? billItemsToReschedule.find(i => i.productId === prod.id && i.customerCategoryId === cc.customerCategoryId) : null;
                    const overridePrice = existingPrice
                        ? prod && prod.pricingMode === ProductPricingMode.Fixed ? existingPrice.totalItemPrice : existingPrice.unitPrice
                        : null;
                    const prices = prod ? getPricesForProduct(prod, quantity, new Date(), startTime.value ? startTime.value.toDate() : null, customer ? customer.tags : [], overridePrice) : [];

                    let selectedPrice = '';
                    const originalPriceIndex = prices.findIndex(prc => prc.isExistingSelection);
                    if (originalPriceIndex >= 0) {
                        selectedPrice = prices[originalPriceIndex].productPrice.id;
                    } else if (prices.length > 0) {
                        selectedPrice = prices.sort(sortPrices)[0].productPrice.id;
                    }

                    return {
                        id: ap.id,
                        productId: ap.productId,
                        product: prod,
                        displayName: category && prod ? `${prod.name} - ${category.name}` : prod ? prod.name : '',
                        placesPerUnit: cc.placesToBook,
                        customerCategoryId: cc.customerCategoryId,
                        customerCategoryName: category ? category.name : '',
                        prices: prices,
                        selectedPrice: selectedPrice
                    }
                })
                .filter(x => !isNullOrEmpty(x.displayName));

            const filteredProducts = categoryProducts.filter(p => p.product && (quantity == 0 || (p.product.pricingMode === ProductPricingMode.PerUnit && quantity % p.placesPerUnit === 0) || (p.product.pricingMode === ProductPricingMode.Fixed && p.product.pricing.filter(prc => prc.fixedPricing.filter(fp => fp.minQuantity <= quantity).length > 0).length > 0)));

            const sortedProducts = filteredProducts.length > 0 ? filteredProducts.sort((p1, p2) => {
                if (p1.product.pricingMode === ProductPricingMode.Fixed) {
                    const p1Max = p1.prices.sort(sortPrices).map(pr => pr.totalPrice)[0];
                    const p2Max = p2.prices.sort(sortPrices).map(pr => pr.totalPrice)[0];
                    return p1Max - p2Max;
                }
                else {
                    return p2.placesPerUnit - p1.placesPerUnit
                }
            }) : filteredProducts;


            let selectedActivityFormatProduct;

            if (currentSelection && sortedProducts.findIndex(p => currentSelection && p.productId === currentSelection.productId) >= 0) {
                selectedActivityFormatProduct = sortedProducts[sortedProducts.findIndex(p => currentSelection && p.productId === currentSelection.productId)];
            } else if (sortedProducts.length > 0) {
                const prodForExistingSelection = sortedProducts.find(p => p.prices.findIndex(pr => pr.isExistingSelection) >= 0);
                selectedActivityFormatProduct = prodForExistingSelection || sortedProducts[0];
            }

            return {
                customerCategoryId: cc.customerCategoryId,
                customerCategoryName: category ? category.name : '',
                quantity: quantity,
                products: filteredProducts,
                activityFormatProductId: selectedActivityFormatProduct ? selectedActivityFormatProduct.id : '',
                productId: selectedActivityFormatProduct ? selectedActivityFormatProduct.productId : '',
                placesPerUnit: selectedActivityFormatProduct && selectedActivityFormatProduct.product ? selectedActivityFormatProduct.placesPerUnit : 1,
                productPriceId: selectedActivityFormatProduct ? selectedActivityFormatProduct.selectedPrice ? selectedActivityFormatProduct.selectedPrice : selectedActivityFormatProduct.prices.length > 0 ? selectedActivityFormatProduct.prices[0].productPrice.id : '' : ''
            }
        }).filter(c => !isNullOrEmpty(c.activityFormatProductId))

        const participants = intermediateCategorySelections.reduce((ttl, c) => ttl + c.quantity, 0);
        const variation = this.findVariationForParticipants(activityFormat, participants);
        const maxParticipants = this.calculateMaxParticipants(variation);
        const categorySelections = intermediateCategorySelections.map(c => ({
            ...c,
            quantity: this.validateCategoryParticipants(c.customerCategoryId, c.quantity, participants === 0)
        }))

        return [categorySelections, participants, variation, maxParticipants]
    }
    
    calculateMaxParticipants = (variation: ActivityFormatVariation | null | undefined) => variation ? variation.maxParticipants : 0;

    notesChanged = (notes: ct.FormValue<string>) => this.setState({ notes: notes });

    onEventNameChanged = (key: string, name: string) => this.setState(state => {
        const item = state.availability.find(a => a.key === key);
        const eventId = item && item.eventId ? item.eventId : '';
        return { availability: state.availability.map(a => { return (a.eventId || '') === eventId ? { ...a, name: name } : a }) }
    });

    onReservationTypeChanged = (key: string, type: ReservationType) => this.setState(state => {
        const item = state.availability.find(a => a.key === key);
        const mastBookTogether = item && item.mustBookItemsTogether;

        return { availability: state.availability.map(a => { return a.key === key || mastBookTogether ? { ...a, reservationType: type } : a }) }
    });

    onMaxParticipantsChanged = (key: string, val: number) => this.setState(state => {
        const item = state.availability.find(a => a.key === key);
        const mastBookTogether = item && item.mustBookItemsTogether;

        return { availability: state.availability.map(a => a.key === key || mastBookTogether ? { ...a, maxParticipants: val } : a) }
    });

    validateName = (val: string) => v.validate(val, 'name', [], []);
    validateResourceSelection = (val: string) => v.validate(val, 'resourceSelection', [v.required], []);
    validateReservationType = (val: ReservationType) => v.validate(val, 'reservationType', [], []);
    validateStartTime = (val: moment.Moment | null) => {
        return v.validate(val, 'startTime', [v.required], []);
    }
    validateActivityFormat = (val: string) => v.validate(val, 'activityFormat', [v.required], []);
    validateActivityFormatVariation = (val: string) => v.validate(val, 'activityFormatVariation', [v.required], []);
    validateNotes = (val: string) => v.validate(val, 'notes', [], []);
    validateCategoryParticipants = (customerCategoryId: string, val: number, required: boolean) => v.validate(val, `${customerCategoryId}_quantity`, required ? [value => typeof (value) === 'number' && value > 0 ? undefined : 'validation:required'] : [], []);

    createNewReservation = (resource: Resource, startTime: Date, endTime: Date) => {
        if (this.state.availability.length === 0) {
            this.setState({ startTime: this.validateStartTime(moment(startTime))}, this.checkAvailability)
        }

        this.setState((prev, props) => {
            const { activityFormats } = props
            const { availability, selectedScheduleItemKey, activityFormatId, activityFormatVariationId } = prev;

            const af = activityFormats.find(f => f.id === activityFormatId.value);
            const afv = af ? af.variations.find(v => v.id === activityFormatVariationId.value) : null;
            const selectedScheduleItem = availability.find(a => selectedScheduleItemKey && a.key === selectedScheduleItemKey);

            const newAvailability = this.validateAvailability(availability.map(a => {
                if (selectedScheduleItem && selectedScheduleItem.key === a.key) {
                    const schedule = afv ? afv.schedule.find(s => s.sequence === a.variationScheduleSequence) : null;
                    return {
                        ...a,
                        slotTime: Time.fromDate(startTime),
                        spacesAvailable: afv ? afv.maxParticipants : 0,
                        hasRequiredSpaces: true,
                        soldOut: false,
                        duration: schedule ? schedule.runningTime : a.duration,
                        mustBookItemsTogether: afv ? afv.mustBookItemsTogether : true,
                        eventId: null,
                        reservationId: null,
                        manualSelection: true,
                        reservationScheduleId: null,
                        reservationScheduleSequence: null,
                        reservationMustBookItemsTogether: null,
                        primaryReservationId: null,
                        isMultiScheduleReservation: false
                    }
                } else if (afv && afv.mustBookItemsTogether) {
                    // If items must be kept together, then we need to clear existing mappings for other items for this booking
                    return { ...a, reservationId: null, eventId: null };
                } else {
                    return a;
                }
            }));

            const newSelectedScheduledItem = newAvailability.find(a => selectedScheduleItem && a.key === selectedScheduleItem.key);

            return {
                availability: newAvailability,
                alternateSlots: [],
                selectedScheduleItemKey: newSelectedScheduledItem ? newSelectedScheduledItem.key : null,
                createReservationsErrorMessage: null
            }
        })
    }

    isAlreadyBooked = (rsv: DiaryReservation, customerId: string) => !this.props.booking && (this.props.bookedReservationIds.findIndex(br => br === rsv.id) >= 0 || (rsv.bookedCustomerIds || []).includes(customerId));

    onReservationSelected = (reservationId: string, eventId: string) => {
        this.setState(state => {
            const { selectedScheduleItemKey, reservations, customer, customerCategorySelections } = state;

            if (!customer) {
                return {
                    availability: state.availability,
                    alternateSlots: state.alternateSlots,
                    selectedScheduleItemKey: selectedScheduleItemKey,
                    createReservationsErrorMessage: 'CreateEvent:onlyNewReservationsWhenNoCustomer'
                }
            }

            const rsv = reservations.find(r => r.id === reservationId);
            if (!rsv) {
                return { availability: state.availability, alternateSlots: state.alternateSlots, selectedScheduleItemKey: selectedScheduleItemKey, createReservationsErrorMessage: null }
            }

            const customerId = customer ? customer.customerId : '';

            const updateItem = (itm: WebBookingAvailabilitySlot, from: DiaryReservation) => {
                const booked = from ? from.bookedParticipants.reduce((ttl, c) => ttl + c.count, 0) : 0;
                const available = from ? from.maxParticipants - booked : 0;
                const required = customer && customerCategorySelections.length > 0 ? customerCategorySelections.reduce((ttl, s) => ttl + (s.quantity.value * s.placesPerUnit), 0) : 0;
                const diffInMs = from ? from.endTime.valueOf() - from.startTime.valueOf() : 0;
                const duration = from ? Time.fromSeconds(diffInMs / 1000) : Time.zero();

                const alreadyBooked = this.isAlreadyBooked(from, customerId);
 
                return {
                    ...itm,
                    slotTime: Time.fromDate(from.startTime),
                    reservationId: from.id,
                    eventId: from.eventId,
                    spacesAvailable: available,
                    hasRequiredSpaces: available >= required,
                    soldOut: available <= 0,
                    mustBookItemsTogether: from.mustBookItemsTogether ? true : itm.mustBookItemsTogether,
                    duration: duration,
                    manualSelection: true,
                    reservationScheduleId: from.activityFormatVariationScheduleId,
                    reservationScheduleSequence: from.activityFormatVariationScheduleSequence,
                    reservationMustBookItemsTogether: from.mustBookItemsTogether,
                    primaryReservationId: from.primaryReservationId,
                    isMultiScheduleReservation: from.isMultiScheduleReservation,
                    isValid: !alreadyBooked,
                    error: 'CreateEvent:alreadyBooked'
                }
            }

            const newAvailability = this.validateAvailability(state.availability.map(a => {
                if (selectedScheduleItemKey && selectedScheduleItemKey === a.key) {
                    return updateItem(a, rsv);
                } else if (rsv.mustBookItemsTogether) {
                    const schedulItemRsv = reservations.find(r => r.eventId === rsv.eventId && r.activityFormatVariationScheduleSequence === a.variationScheduleSequence);
                    if (schedulItemRsv) {
                        return updateItem(a, schedulItemRsv);
                    }
                }

                return a
            }));

            const newSelectedScheduledItem = newAvailability.find(a => selectedScheduleItemKey && a.key === selectedScheduleItemKey);

            return {
                availability: newAvailability,
                alternateSlots: [],
                selectedScheduleItemKey: newSelectedScheduledItem ? newSelectedScheduledItem.key : null,
                createReservationsErrorMessage: null
            };
        });
    }

    onFieldValueChanged = (fieldName: string, value: string) => {
        this.setState(s => ({ bookingQuestions: s.bookingQuestions.map(q => q.customFieldName === fieldName ? {...q, value: value} : q) }))
    }

    render() {
        const { t } = this.context;
        const { step, customer, resourceSelectionId, formErrorKey, startTime, customerCategorySelections, createReservationsError, createReservationsErrorMessage,
            alternateSlots, showCheckAvailability, notes, bookingQuestions, processing, customerWarning } = this.state;
        const { venue, resources, activityFormats, booking, isSaving, createButtonTextKey } = this.props;

        let message: any = null;
        if (!isNullOrEmpty(formErrorKey)) {
            message = (<div className='alert alert-danger'>{t(formErrorKey)}</div>);
        } else if (createReservationsError != null) {
            message = (<ApiError error={createReservationsError} />);
        }

        const resourceSelections = resources.filter(r => r.venueId == venue.id && !r.archived).sort((r1, r2) => r1.sequence - r2.sequence).reduce<ct.SelectOption[]>((selections, r) => selections.concat(r.configurations && r.configurations.length > 0 ? r.configurations.map(c => ({ key: buildResourceSelectionId(r.id, c.id), name: c.name })) : [{ key: r.id, name: r.shortName }]), []);
        const getBgCol = (opt: ct.SelectOption) => {
            const { resourceId } = deconstructResourceSelectionId(opt.key);
            return resources.filter(r => r.venueId == venue.id && !r.archived && r.id === resourceId).map(r => r.colour).concat(['transparent'])[0];
        }

        const inlineControlStyle = ({ minHeight: '10px', marginBottom: '0' });
        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const dateFormat = venue ? venue.dateFormat : DateFormat.DMY;

        return (
            <div className='create-event-panel flex-stretch'>
                <div className='create-event-header'>
                    {booking
                        ? <div className='at-panel' style={{ margin: '8px 0', borderRadius: '5px', border: 'solid 1px #999' }}>
                            <div className='row'>
                                <div className='col-xs-12'>
                                    <ReservationSummary
                                        venue={venue}
                                        reservations={booking.reservations.sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime()).map(r => ({
                                            key: r.id,
                                            resourceId: r.resourceId,
                                            resourceConfigurationId: r.resourceConfigurationId,
                                            activityFormatId: r.activityFormatId,
                                            activityFormatVariationId: r.activityFormatVariationId,
                                            membershipTypeId: r.membershipTypeId,
                                            membershipTypeLabel: r.membershipTypeLabel,
                                            membershipTypeLabelColour: r.membershipTypeLabelColour,
                                            reservationType: r.reservationType,
                                            bookedParticipants: r.bookedParticipants,
                                            maxParticipants: r.maxParticipants,
                                            startTime: r.startTime,
                                            endTime: r.endTime
                                        }))}
                                        resources={resources}
                                        activityFormats={activityFormats}
                                    />
                                </div>
                            </div>
                        </div>
                        : null}


                    <div className='at-panel' style={{ margin: '8px 0', borderRadius: '5px', border: 'solid 1px #999' }}>
                        <div className='row'>
                            <div className='col-xs-12 col-md-4'>
                                <ct.Select
                                    id='resourceSelection'
                                    labelKey='ReservationDetailsPage:resource'
                                    value={resourceSelectionId}
                                    callback={val => this.resourceSelectionChanged(this.validateResourceSelection(val))}
                                    options={resourceSelections}
                                    renderOption={o => <div key={o.key}><div style={({ width: '8px', backgroundColor: getBgCol(o), marginRight: '6px', display: 'inline-block' })}>&nbsp;</div>{o.name}</div>}
                                />
                            </div>

                            <div className='col-xs-12 col-md-3'>
                                <ct.DateTimePicker id='startTime' labelKey='ReservationDetailsPage:startTime' value={startTime} timeFormat={timeFormat} dateFormat={dateFormat} callback={this.startTimeChanged} />
                            </div>

                            <div className='col-xs-12 col-md-5'>
                                {this.renderActivityFormat()}
                            </div>
                        </div>
                    </div>

                    <div className='at-panel' style={{ margin: '8px 0', borderRadius: '5px', border: 'solid 1px #999' }}>
                        <div className='flex flex-column flex-nowrap'>
                            <div className='flex-stretch'>
                                <div className='flex flex-column flex-nowrap'>
                                    <div className='flex-stretch'>
                                        {customer
                                            ? this.renderCustomerDetails(customer)
                                            : <div>{t('ReservationDetailsPage:noCustomerSelected')}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {customer
                                ? <div style={{ flex: '2 1 auto', margin: '0 15px' }}>
                                    <table className='table table-condensed no-border' style={{ marginBottom: '0' }}>
                                        <tbody>
                                            {customerCategorySelections.map(c => {
                                                const productOptions = c.products.map(p => ({ key: p.id, name: p.displayName }));
                                                const selectedProduct = c.products.find(p => p.id === c.activityFormatProductId);

                                                const sortedPrices = selectedProduct ? selectedProduct.prices.sort(sortPrices) : []
                                                const priceOptions = sortedPrices.map(p => ({ key: p.productPrice.id, name: `${t('Global:currencySymbol')}${p.unitPrice.toFixed(2)}` }));

                                                const renderPrice = (o : ct.SelectOption) => {
                                                    const pr = sortedPrices.find(p => p.productPrice.id === o.key);
                                                    if (!pr) return o.name;

                                                    let elements = [<span key={o.key}>{o.name}</span>]

                                                    if (pr.isActive) elements.push(<span key={`{o.key}_active`} className='label label-success' style={({ marginLeft: '6px' })}>{t('Global:active')}</span>);
                                                    if (!isNullOrEmpty(pr.tagId)) {
                                                        elements.push(<span key={`{o.key}_${pr.tagId}`} className='label tag-label' style={({ backgroundColor: pr.tagColour, marginLeft: '6px' })}>{pr.tagName}</span>);
                                                    } else if (pr.isOverride) {
                                                        elements.push(<span key={`{o.key}_override`} className='label label-warning' style={({ marginLeft: '6px' })}>{t('Global:override')}</span>);
                                                    }

                                                    return elements;
                                                }

                                                return <tr key={c.customerCategoryId}>
                                                    <td className='shrink' style={{ paddingBottom: '1px', paddingTop: '1px'}}><ct.NumberBox id={`${c.customerCategoryId}_numberOfPlacesToBook`} labelKey='' placeholderKey='' min='0' value={{ ...c.quantity, hasValidation: true }} callback={val => this.numberOfParticipantsChanged(c.customerCategoryId, val || 0)} style={({ ...inlineControlStyle, minWidth: '110px' })} minimal={true} /></td>
                                                    <td className='shrink' style={{ paddingTop: '9px' }}>X</td>
                                                    <td className='expand' style={{ paddingBottom: '1px', paddingTop: '1px' }}><ct.Select id={`${c.customerCategoryId}_product`} labelKey='' value={ct.asFormValue(`${c.customerCategoryId}_product`, c.activityFormatProductId)} callback={val => this.productChanged(c.customerCategoryId, val)} options={productOptions} style={inlineControlStyle} minimal={true} /></td>
                                                    <td className='shrink' style={{ paddingBottom: '1px', paddingTop: '1px' }}><ct.Select id={`${c.customerCategoryId}_price`} labelKey='' value={ct.asFormValue(`${c.customerCategoryId}_price`, c.productPriceId)} callback={val => this.productPriceChanged(c.customerCategoryId, c.activityFormatProductId, val)} options={priceOptions} renderOption={renderPrice} style={inlineControlStyle} minimal={true} /></td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                : null
                            }
                            <div className='flex-shrink'>
                                {booking ? null : <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.showSelectCustomer(false))}>{customer ? t('EventForm:changeCustomer') : t('EventForm:selectCustomer')}</button>}
                            </div>
                        </div>
                        {customerWarning ? <div className='alert alert-warning'>{t(customerWarning)}</div> : null}
                    </div>

                    {isNullOrEmpty(createReservationsErrorMessage) ? null : <div className='alert alert-danger'>{t(createReservationsErrorMessage)}</div>}

                    {alternateSlots.length > 0
                        ? <ul className='create-event-alternate-slots'>
                            {alternateSlots.map(a => <li key={a.slotTime.toShortTimeString()} className='create-event-alternate-slot btn btn-primary' onClick={e => clickHandler(e, () => this.selectTime(a.slotTime))}>{a.slotTime.toShortTimeString()}</li>)}
                        </ul>
                        : null}

                    {step === Step.Scheduling
                        ? <div className='row'>
                            <div className='col-xs-12'>
                                {this.renderSchedule()}
                            </div>
                        </div>
                        : null
                    }

                    {message
                        ? <div className='row'>
                            <div className='col-md-12'>
                                {message}
                            </div>
                        </div>
                        : null
                    }
                </div>

                {showCheckAvailability
                    ? <div className='create-event-actions' style={{ marginBottom: '15px' }}>
                        <div className='btn-panel'>
                            <button className='btn btn-success' onClick={e => clickHandler(e, () => this.checkAvailability())} disabled={isSaving}>{t('ReservationDetails:checkAvailability')}</button>
                        </div>
                    </div>
                    : null
                }

                {this.renderReservations()}

                {customer ? <div><ct.TextArea id='notes' labelKey='Global:notes' rows={2} value={notes} noMaxWidth={true} callback={n => this.setState({ notes: ct.asFormValue('note', n) })} /></div> : null}

                {customer ? <div><BookingExtraFields customFields={bookingQuestions} fieldValueChanged={this.onFieldValueChanged}  /></div> : null}

                <div className='create-event-actions mt-15'>
                    <div className='btn-panel'>
                        {step === Step.Scheduling ? <button key='createEvent_button' className='btn btn-primary' onClick={e => clickHandler(e, () => this.createEvent(), 'createEvent__create')} disabled={isSaving || processing}>{t(createButtonTextKey || 'Global:create')}</button> : null}
                        <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.cancel())} disabled={isSaving || processing}>{t('Global:cancel')}</button>
                    </div>
                </div>
            </div>
        );
    }

    renderCustomerDetails(customer: ICustomer): JSX.Element {
        const { t } = this.context;

        const contactInfo = [customer.phoneNumber, customer.emailAddress].filter(i => !isNullOrEmpty(i)).join(' | ');

        return <div>
            <div><label style={{ marginRight: '10px' }}>{t('Global:customer')}</label>{`${customer.firstname} ${customer.lastname}`}</div>
            {isNullOrEmpty(customer.companyName) ? null : <div>{customer.companyName}</div>}
            <div>{contactInfo}</div>
        </div>
    }

    renderSchedule = () => {
        const { t } = this.context;
        const { availability, activityFormatId, selectedScheduleItemKey, reservations } = this.state;
        const { activityFormats, venue } = this.props;

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;
        const activityFormat = activityFormats.filter(f => f.id === activityFormatId.value)[0];

        return <table className='table table-condensed' style={{ borderCollapse: 'separate', borderSpacing: '0 4px' }}>
            <tbody>
                {
                    availability.sort((i1, i2) => i1.variationScheduleSequence - i2.variationScheduleSequence)
                        .map(itm => {
                            const borderColour = activityFormat.colour;
                            const selected = selectedScheduleItemKey && itm.key === selectedScheduleItemKey;
                            const scheduled = itm.reservationId;
                            const baseCellStyle: React.CSSProperties = { borderColor: borderColour, borderStyle: 'solid', borderWidth: '1px 0', verticalAlign: 'middle' };
                            const leftCellStyle: React.CSSProperties = { ...baseCellStyle, borderLeftWidth: '6px', borderRadius: '5px 0 0 5px' };
                            const rightCellStyle: React.CSSProperties = { ...baseCellStyle, borderRightWidth: '1px' };

                            const rsv = reservations.find(r => r.id === itm.reservationId);

                            return <tr key={itm.variationScheduleSequence} className={`reschedule-booking-reservation ${selected ? 'selected' : ''}`} onClick={e => clickHandler(e, () => this.onScheduleItemSelected(itm))}>
                                <td style={leftCellStyle} className='shrink'>
                                    <div className='circle black'>{itm.variationScheduleSequence}</div>
                                </td>
                                <td style={baseCellStyle} className={'shrink ' + `reschedule-booking-reservation-heading ${scheduled ? 'rescheduled' : ''}`}>
                                    <span>{itm.slotTime.toShortTimeString(timeFormat)}</span>
                                    {itm.latestSlotTime ? <span>{` - ${itm.latestSlotTime.toShortTimeString(timeFormat)}`}</span> : null}
                                </td>
                                <td style={baseCellStyle} className='shrink'>{itm.duration.toShortTimeString()}</td>

                                {rsv
                                    ? <td style={baseCellStyle} className='shrink'>
                                        <span className="glyphicon glyphicon-user cal-reservation-icon"></span>
                                        <span>{`${rsv.bookedParticipants.reduce((ttl, p) => ttl + p.count, 0)}/${rsv.maxParticipants}`}</span>
                                    </td>
                                    : <td style={baseCellStyle} className='shrink'><span className='label label-info'>{t('Global:new')}</span></td>
                                }

                                <td style={baseCellStyle} className='shrink'>{this.renderReservationType(itm.reservationType)}</td>

                                <td style={baseCellStyle} className='shrink'>
                                    <span style={{ marginRight: '10px', fontSize: '20px', color: `${this.getIconColour(itm)}` }} className={`glyphicon glyphicon-${this.getItemIcon(itm)}`}></span>
                                </td>

                                <td style={rightCellStyle} className='expand'>{this.renderItemMessage(itm)}</td>
                            </tr>
                        })
                }
            </tbody>
        </table>
    }

    renderReservationType = (reservationType: ReservationType) => <span className={`label label-${reservationType === ReservationType.Exclusive ? 'default' : 'success'} label-xs`}>{this.context.t(reservationType === ReservationType.Exclusive ? 'Global:exclusiveShort' : 'Global:sharedShort')}</span>

    renderItemMessage = (slot: WebBookingAvailabilitySlot) => {
        const { t } = this.context;

        if (!isNullOrEmpty(slot.error)) {
            return <span className='text-danger'>{t(slot.error)}</span>
        } else if (!slot.hasRequiredSpaces) {
            return <span className='text-warning'>{t(slot.hasOverlap ? 'CreateEvent:overlappingReservation' : 'CreateEvent:insufficientSpaceForBooking')}</span>
        }

        return null;
    }

    getIconColour = (item: WebBookingAvailabilitySlot) => {
        if (!item.isValid) {
            return '#a94442';
        } else if (!item.hasRequiredSpaces) {
            return '#f3b61f';
        } else {
            return '#27d312';
        }
    }

    getItemIcon = (item: WebBookingAvailabilitySlot) => {
        if (!item.isValid) {
            return 'exclamation-sign';
        } else if (item.manualSelection) {
            return 'ok-circle';
        } else if (item.hasRequiredSpaces) {
            return 'ok-circle';
        } else {
            return 'alert';
        }
    }

    onScheduleItemSelected = (item: WebBookingAvailabilitySlot) => this.setState({ selectedScheduleItemKey: item ? item.key : null })

    getCustomerCategoryName = (customerCategoryId: string | null) => {
        const { venue, customerCategories } = this.props;

        const cat = customerCategories.find(c => c.venueId === venue.id && !c.archived && c.id === customerCategoryId);
        return cat ? cat.name : '';
    }

    renderActivityFormat = () => {

        const { t } = this.context;
        const { activityFormats } = this.props;
        const { resourceSelectionId, activityFormatId, activityFormatVariationId } = this.state;
        const { resourceId, resourceConfigId } = deconstructResourceSelectionId(resourceSelectionId.value);

        const filteredActivities = this.filterActivities(activityFormats, resourceId, resourceConfigId, activityFormatId.value);
        const sortedActivities = filteredActivities.sort((a, b) => {
            if (a.sequence !== null && b.sequence !== null && a.sequence !== b.sequence) {
                return a.sequence - b.sequence;
            }
            const aName = a.name.trim();
            const bName = b.name.trim();
            if (aName > bName) return 1;
            else if (bName > aName) return -1;
            else return 0;
        });

        const activityFormatOptions = [{ key: '', name: t('EventReservations.selectActivityFormat') }].concat(sortedActivities.map(f => ({ key: f.id, name: f.name })));
        const activityFormat = filteredActivities.find(f => f.id === activityFormatId.value);
        const showVariations = activityFormat && activityFormat.variations.length > 1;
        const getActivityColour = (opt: ct.SelectOption) => filteredActivities.filter(af => af.id === opt.key).map(af => af.colour).concat(['transparent'])[0];

        return <div className='row'>
            <div className={`col-xs-${showVariations ? 6 : 12}`}>
                <ct.Select
                    id='activityFormat'
                    labelKey='ReservationDetailsPage:activityFormat'
                    value={({ ...activityFormatId, value: activityFormatId.value.toString() })}
                    callback={this.onActivityFormatChanged}
                    options={activityFormatOptions}
                    renderOption={o => <div key={o.key}><div style={({ width: '8px', backgroundColor: getActivityColour(o), marginRight: '6px', display: 'inline-block' })}>&nbsp;</div>{o.name}</div>}
                />
            </div>
            {showVariations && activityFormat ? this.renderActivityFormatVariations(activityFormat, activityFormatVariationId) : null}
        </div>
    }

    renderActivityFormatVariations = (activityFormat: ActivityFormat, activityFormatVariationId: ct.FormValue<string>) => {
        const options = activityFormat.variations.map(v => ({ key: v.id, name: `${v.minParticipants} - ${v.maxParticipants}` }));
        return <div className='col-xs-6'>
            <ct.Select id='activityFormat' labelKey='ReservationDetailsPage:activityFormatVariation' value={({ ...activityFormatVariationId, value: activityFormatVariationId.value.toString() })} callback={this.activityFormatVariationChanged} options={options} />
        </div>
    }

    findVariation = (variationId: string, activityFormat?: ActivityFormat) => {
        if (activityFormat && activityFormat.variations.length > 0) {
            const variation = activityFormat.variations.find(v => v.id === variationId);
            return variation ? variation : activityFormat.variations[0];
        }
        return null;
    }

    onActivityFormatChanged = (val: string) => {
        const { activityFormats } = this.props;

        const activityFormat = activityFormats.find(f => f.id === val);
        this.activityFormatChanged(activityFormat ? activityFormat.id : '');
    }

    onTimesChanged = (key: string, value: ct.DateRange) =>
        this.setState(state => ({
            availability: this.validateAvailability(state.availability.map(a => {
                if (a.key !== key) return a;

                if (value.from && Time.fromDate(value.from.toDate()).val() !== a.slotTime.val()) {
                    return { ...a, slotTime: Time.fromDate(value.from.toDate()) }
                } else if (value.to) {
                    const start = value.from ? Time.fromDate(value.from.toDate()) : a.slotTime;
                    return { ...a, duration: Time.fromDate(value.to.toDate()).subtract(start) }
                }
                return a;
            }))
        }));

    renderReservations = () => {
        const { t } = this.context;
        const { activityFormats, resources, venue, reservation, openingTimes } = this.props;
        const { resourceSelectionId, startTime: selectedStartTime, reservations, availability, activityFormatId, activityFormatVariationId, selectedScheduleItemKey, customer, customerCategorySelections } = this.state;
        const { resourceId: selectedResourceId, resourceConfigId } = deconstructResourceSelectionId(resourceSelectionId.value);

        const resource = resources.find(r => r.venueId == venue.id && !r.archived && r.id === selectedResourceId);
        if (!resource) {
            return <div className='alert alert-warning'>{t('AddEventToBooking:selectResource')}</div>
        }

        const date = selectedStartTime.value;

        if (!date) {
            return <div className='alert alert-warning'>{t('CreateEvent:selectStartTime')}</div>
        }

        const dateAsDate = date.toDate();

        const openHour = getOpenHour(venue, openingTimes, dateAsDate);
        const startTime = openHour ? Math.max(0, openHour - 2) : 0;
        const endTime = Math.min(24, getCloseHour(venue, openingTimes, dateAsDate) + 2);
        const slotSizeInMinutes = 15;
        const slotHeight = 24;

        const startOfDay = new Date(dateAsDate.getFullYear(), dateAsDate.getMonth(), dateAsDate.getDate());
        const endOfDay = new Date(dateAsDate.getFullYear(), dateAsDate.getMonth(), dateAsDate.getDate(), 23, 59, 59);

        const selectedScheduleItem = availability.find(a => selectedScheduleItemKey && a.key === selectedScheduleItemKey)

        const eventId = reservations.length > 0 ? reservations[0].eventId : null;
        const filteredReservations: DiaryReservation[] = reservations
            .filter(r => (r.resourceId === selectedResourceId || r.blockedResourceIds.includes(selectedResourceId)) && ((r.startTime >= startOfDay && r.startTime <= endOfDay) || (r.endTime >= startOfDay && r.endTime <= endOfDay)) && !r.cancelled && !r.deleted)
            .map(r => ({
                ...r,
                notSelectable: !this.canSelectReservation(r, activityFormatId.value, activityFormatVariationId.value, selectedScheduleItem, availability, customer, eventId),
                deleted: false,
                cancelled: false,
                archived: false
            }));

        const activityFormat = activityFormats.find(f => f.id === activityFormatId.value);
        const variation = activityFormat && activityFormat.variations ? activityFormat.variations.find(v => v.id === activityFormatVariationId.value) : null;
        const resourceConfig = resource && resource.configurations ? resource.configurations.find(c => c.id === resourceConfigId) : null;

        const schduledItems: DiaryReservation[] = availability.map(a => {
            const endTime = a.slotTime.add(a.duration);
            return {
                id: null,
                key: generateTempId(),
                eventId: '',
                eventName: a.name,
                draft: true,
                colour: activityFormat ? activityFormat.colour : gt.colours[0],
                reservationType: a.reservationType,
                resourceId: resource.id,
                blockedResourceIds: [],
                resourceConfigurationId: resourceConfig ? resourceConfig.id : null,
                resourceConfigurationCode: resourceConfig ? resourceConfig.code : null,
                resourceConfigurationName: resourceConfig ? resourceConfig.name : null,
                resourceName: resource.name,
                shortResourceName: resource.shortName,
                eventStatus: EventStatus.Provisional,
                overdueAmount: 0,
                outstandingAmount: 0,
                activityFormatId: activityFormatId.value,
                activityFormatVariationId: variation ? variation.id : '',
                activityFormatVariationScheduleId: a.variationScheduleId,
                activityFormatVariationScheduleSequence: a.variationScheduleSequence,
                membershipTypeId: null,
                membershipTypeLabel: null,
                membershipTypeLabelColour: null,
                mustBookItemsTogether: a.mustBookItemsTogether,
                primaryReservationId: a.primaryReservationId,
                isMultiScheduleReservation: a.isMultiScheduleReservation,
                maxParticipants: variation ? variation.maxParticipants : 0,
                bookedParticipants: customerCategorySelections
                    .filter(cc => cc.quantity.value > 0)
                    .map(cc => ({ categoryId: cc.customerCategoryId, categoryName: cc.customerCategoryName, count: cc.quantity.value * cc.placesPerUnit })),
                confirmedParticipants: [],
                unconfirmedParticipants: [],
                outstandingDepositAmount: 0,
                bookedCustomerIds: [],
                lanes: [],
                depositDueDate: null,
                paymentDueDate: null,
                overduePaymentDate: null,
                startTime: new Date(date.year(), date.month(), date.date(), a.slotTime.getHours(), a.slotTime.getMinutes(), a.slotTime.getSeconds()),
                endTime: new Date(date.year(), date.month(), date.date(), endTime.getHours(), endTime.getMinutes(), endTime.getSeconds()),
                notes: '',
                archived: false,
                isPlaceholder: true,
                targetReservationId: a.reservationId,
                cancelled: false,
                deleted: false,
                notSelectable: true,
                isSelected: selectedScheduleItemKey && a.key === selectedScheduleItemKey ? true : false,
                compatibleActivityFormatVariationIds: [],
                flagged: false
            }
        })

        const topTime = reservation ? reservation.startTime : dateAsDate;

        return <div className='reschedule-booking-reservation-selection at-panel at-panel-rounded'>
            <div className='reschedule-booking-panel-calendar'>
                <InlineCalendarResource
                    slotSizeInMinutes={slotSizeInMinutes}
                    slotHeight={slotHeight}
                    date={dateAsDate}
                    startTime={startTime}
                    endTime={endTime}
                    scrollToTime={Time.fromDate(topTime).subtract(new Time(1, 0, 0))}
                    resource={resource}
                    width={100}
                    reservations={filteredReservations.concat(schduledItems)}
                    createNewReservation={this.createNewReservation}
                    onReservationSelected={this.onReservationSelected}
                    venue={venue}
                />
            </div>
            <div className='reschedule-booking-panel-reservation'>
                {this.renderSelectedItemDetails(selectedScheduleItem)}
            </div>
        </div>
    }

    canSelectReservation = (rsv: DiaryReservation, activityFormatId: string, activityFormatVariationId: string, selectedScheduleItem: WebBookingAvailabilitySlot | undefined, availability: WebBookingAvailabilitySlot[], customer: ICustomer | null, eventId: string | null) => {
        if (!selectedScheduleItem || !customer) {
            return false;
        }

        if (rsv.activityFormatId !== activityFormatId && rsv.compatibleActivityFormatVariationIds.findIndex(cv => cv === activityFormatVariationId) < 0) {
            return false;
        }

        if (rsv.id && availability.findIndex(a => !isNullOrEmpty(a.reservationId) && rsv.id == a.reservationId) >= 0) {
            return false;
        }

        if (rsv.mustBookItemsTogether && (selectedScheduleItem.variationScheduleSequence != rsv.activityFormatVariationScheduleSequence || (rsv.activityFormatVariationScheduleSequence > 1 && rsv.eventId !== eventId))) {
            return false;
        }

        if (rsv.id && this.props.bookedReservationIds.indexOf(rsv.id) >= 0) {
            return false;
        }

        return true;
    }

    renderSelectedItemDetails(selectedScheduleItem: WebBookingAvailabilitySlot | undefined): React.ReactNode {
        const { t } = this.context;
        const { reservations, availability, startTime, showCheckAvailability } = this.state;
        const { venue } = this.props;

        if (availability.length < 1) {
            return showCheckAvailability ? <div className='alert alert-info text-center'>{t('CreateEvent:clickCheckAvailability')}</div>: <div></div>;
        }

        if (!selectedScheduleItem) {
            return <div className='alert alert-info text-center' style={{ fontWeight: 'bold' }}>{t('CreateEvent:noEventSelected')}</div>;
        }

        if (!startTime.value) {
            return <div className='alert alert-warning'>{t('CreateEvent:selectStartTime')}</div>
        }

        const timeFormat = venue ? venue.timeFormat : TimeFormat.TwentyFourHour;

        const rsv = reservations.find(r => r.id === selectedScheduleItem.reservationId);

        if (rsv) {
            return <div>
                <div className='flex flex-row flex-center'>
                    <h3 style={{ flex: '0 0 auto', margin: '0 6px' }}>{selectedScheduleItem.slotTime.toShortTimeString()}</h3>
                    <div style={{ flex: '1 1 auto', margin: '0 0 0 10px' }} className='alert alert-info'>{t('CreateEvent:existingReservationDetails')}</div>
                </div>
                <div className='mt-15'>
                    <div className='flex flex-row flex-start'>
                        <span style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '6px' }}>{rsv.bookedParticipants.reduce((remaining, c) => Math.max(0, remaining - c.count), rsv.maxParticipants)}</span>
                        <span>{t('CreateEvent:remainingSpaces')}</span>
                    </div>
                    {selectedScheduleItem.hasRequiredSpaces ? null : <div className='alert alert-warning'>{t('CreateEvent:insufficientSpaceForBooking')}</div> }
                    <div>
                        {this.renderReservationType(rsv.reservationType)}
                    </div>
                </div>
            </div>
        }

        const selectedStartAsDate = startTime.value.toDate();
        const start = new Date(selectedStartAsDate.getFullYear(), selectedStartAsDate.getMonth(), selectedStartAsDate.getDate(), selectedScheduleItem.slotTime.getHours(), selectedScheduleItem.slotTime.getMinutes(), 0);
        const end = start.addMinutes((selectedScheduleItem.duration.getHours() * 60) + selectedScheduleItem.duration.getMinutes());

        const dateErr = start >= end ? 'ReservationDetailsPage:dateRangeOutOfOrder' : null;

        return <div>
            <div className='flex flex-row flex-center'>
                <div style={{ flex: '1 1 auto', margin: '0 0 0 10px' }} className='alert alert-info'>{t('CreateEvent:newReservationDetails')}</div>
            </div>
            <div className='flex flex-row flex-center'>
                <ct.TextBox id='eventName' labelKey='ReservationDetailsPage:eventName' value={ct.asFormValue('eventName', selectedScheduleItem.name, !isNullOrEmpty(selectedScheduleItem.name), true)} callback={val => this.onEventNameChanged(selectedScheduleItem.key, val)} />
            </div>
            <div className='flex flex-row flex-center'>
                <ct.DateRangePicker id='dateRange' labelKey='ReservationDetailsPage:dateRange' value={ct.asFormValue('dateRange', { from: moment(start), to: moment(end) }, isNullOrEmpty(dateErr), true, dateErr || undefined)} dateFormat={'D/MM/YYYY'} timeFormat={timeFormat} viewMode='time' callback={val => this.onTimesChanged(selectedScheduleItem.key, val)} />;
            </div>
            <div>
                <form className='data-form'>
                    <ReservationTypePanel
                        reservationType={selectedScheduleItem.reservationType}
                        maxParticipants={ct.asFormValue('max_participants', selectedScheduleItem.maxParticipants)}
                        reservationTypeChanged={rt => this.onReservationTypeChanged(selectedScheduleItem.key, rt)}
                        maxParticipantsChanged={val => this.onMaxParticipantsChanged(selectedScheduleItem.key, val)}
                    />
                </form>
            </div>
        </div>
    }

}

const mapStateToProps = (state: ApplicationState) => ({
    resources: state.resources.resources,
    openingTimes: state.diary.openingTimes,
    activityFormats: state.activityFormats.activityFormats,
    products: state.products.products.filter(x => !x.archived),
    customerCategories: state.customerCategories.customerCategories,
    memberships: state.memberships.memberships
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
    logout: bindActionCreators(LoginActions.actionCreators.logout, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
