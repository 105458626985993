
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';

import * as PropTypes from 'prop-types'
import moment from 'moment';

import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as api from '../../../store/apiClient';
import { ApplicationState } from '../../../store';
import * as CampaignActions from '../../../store/pages/campaigns/actions';
import { DayOfMonth, MarketingCampaign, MarketingCampaignFilter, MarketingCampaignFilterComparison, MarketingCampaignFilterGroup, MarketingCampaignFilterGroupType, MarketingCampaignFilterType, MarketingCampaignFilterUnit, MarketingCampaignRunInterval, MarketingCampaignState } from '../../../store/pages/campaigns/types';
import { clickHandler, generateTempId, isNullOrEmpty } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { Days, Interval, IntervalUnit, Time, ValidationError } from '../../../store/global/types';
import { FilterGroup, Filter } from './types';
import CampaignFilterGroup from './campaignFilterGroup'
import CampaignVenueSelection from './campaignVenueSelection';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { TimeInterval } from '../../global/controls';
import { Tag } from '../../../store/pages/tags/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { DateFormat, TimeFormat, Venue } from '../../../store/pages/venues/types';
import { MembershipType } from '../../../store/pages/memberships/types';

interface EditCampaignPageRouteProps {
    campaignId: string;
}

interface EditCampaignPageRouteState {
    copyFrom?: MarketingCampaign | null;
}

interface MappedReduxState {
    clientId: number;
    campaigns: MarketingCampaign[];
    saveComplete: boolean;
    saveError: api.ApiError | null;
    venues: Venue[];
    validationErrors: ValidationError[];
    activityFormats: ActivityFormat[];
    customerTags: Tag[];
    customerCategories: CustomerCategory[];
    membershipTypes: MembershipType[];
    dateFormat: DateFormat,
    timeFormat: TimeFormat
}

interface Actions {
    saveCampaign: (isNew: boolean, campaignId: string | null, campaign: MarketingCampaign) => void;
}

type EditCampaignPageProps = MappedReduxState & Actions & RouteComponentProps<EditCampaignPageRouteProps, {}, EditCampaignPageRouteState>;

interface EditCampaignPageState {
    isNew: boolean;
    campaignId: string | null;
    name: ct.FormValue<string>;
    runInterval: ct.FormValue<MarketingCampaignRunInterval>;
    dayOfWeekToRun: ct.FormValue<Days>;
    dailyRunStartTime: ct.FormValue<Time>;
    dailyRunEndTime: ct.FormValue<Time>;
    dayOfMonthToRun: ct.FormValue<DayOfMonth>;
    dayOfMonthValue: ct.FormValue<number>;
    campaignState: ct.FormValue<MarketingCampaignState>;
    startDate: ct.FormValue<moment.Moment | null>;
    endDate: ct.FormValue<moment.Moment | null>;
    minimumTimeIntervalBeforeResend: ct.FormValue<Interval>;
    selectedVenueIds: string[];
    filters: FilterGroup;
    emailSubjectTemplate: ct.FormValue<string>;
    emailTemplateId: string;
    emailTemplateKey: string;
    sendFromEmailAddressOverride: ct.FormValue<string>;
    sendFromEmailNameOverride: ct.FormValue<string>;
    validationError: string | null;
    error: string | null;
    showTemplateLoading: boolean;
}

class EditCampaignPage extends React.Component<EditCampaignPageProps, EditCampaignPageState> {

    constructor(props: EditCampaignPageProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: EditCampaignPageProps): EditCampaignPageState {

        const campaignId = props.match.params.campaignId === 'new' ? null : props.match.params.campaignId;
        let campaign = campaignId && campaignId !== 'new' ? props.campaigns.find(c => c.id === campaignId) : null;
        const isNew = campaignId && campaign ? false : true;

        if (props.location && props.location.state && props.location.state.copyFrom) {
            campaign = { ...props.location.state.copyFrom, id: '', state: MarketingCampaignState.Draft, name: '' }
        }

        const runInterval = !campaign ? MarketingCampaignRunInterval.Manual : campaign.runInterval;
        const startDate = campaign && campaign.startTime ? moment(campaign.startTime) : moment();
        const endDate = campaign && campaign.endTime ? moment(campaign.endTime) : null;

        const emailTemplateId = generateTempId();
        const emailTemplateKey = `template-${emailTemplateId}`;
        const data = { template: campaign ? campaign.emailJsonTemplate : '', html: campaign ? campaign.emailHtmlTemplate : '' }
        const dataStr = JSON.stringify(data);
        sessionStorage.setItem(emailTemplateKey, dataStr);

        const minTimeIntervalBeforeResend = campaign ? campaign.minimumTimeIntervalBeforeResend : { value: 30, unit: IntervalUnit.Day };
        const startTime = campaign ? campaign.dailyRunStartTime : Time.zero();

        return {
            isNew: isNew,
            campaignId: campaign ? campaignId : '',
            name: this.validateName(campaign ? campaign.name : ''),

            runInterval: this.validateRunInterval(runInterval),
            dayOfWeekToRun: this.validateDayOfWeekToRun(runInterval, campaign ? campaign.dayOfWeekToRun : Days.None),
            dailyRunStartTime: this.validateDailyRunStartTime(runInterval, startTime),
            dailyRunEndTime: this.validateDailyRunEndTime(runInterval, campaign ? campaign.dailyRunEndTime || Time.zero() : Time.zero(), startTime),
            dayOfMonthToRun: this.validateDayOfMonthToRun(runInterval, campaign ? campaign.dayOfMonthToRun : DayOfMonth.None),
            dayOfMonthValue: this.validateDayOfMonthValue(runInterval, campaign ? campaign.dayOfMonthValue : 0),

            campaignState: this.validateCampaignState(campaign ? campaign.state : MarketingCampaignState.Draft),
            startDate: this.validateStartDate(startDate, runInterval),
            endDate: this.validateEndDate(startDate, endDate ? moment(endDate) : null),
            minimumTimeIntervalBeforeResend: this.validateMinimumTimeIntervalBeforeResend(minTimeIntervalBeforeResend),
            selectedVenueIds: campaign ? campaign.venueFilter : [],
            filters: campaign ? this.mapFilterGroup(campaign.filters) : this.createFilterGroup(MarketingCampaignFilterGroupType.And),
            emailSubjectTemplate: this.validateEmailSubjectTemplate(campaign ? campaign.emailSubjectTemplate : ''),
            emailTemplateId: emailTemplateId,
            emailTemplateKey: emailTemplateKey,
            sendFromEmailAddressOverride: this.validateSendFromEmailAddressOverride(campaign ? campaign.sendFromEmailAddressOverride || '' : ''),
            sendFromEmailNameOverride: this.validateSendFromEmailNameOverride(campaign ? campaign.sendFromEmailNameOverride || '' : ''),
            validationError: null,
            error: null,
            showTemplateLoading: true
        };
    }

    createFilterGroup = (type: MarketingCampaignFilterGroupType) : FilterGroup => {
        const key = generateTempId();

        return {
            key: key,
            type: this.validateFilterGroupType(key, type),
            filters: [this.createFilter()],
        }
    }

    createFilter = () : Filter => {
        const key = generateTempId();
        const comparison = MarketingCampaignFilterComparison.EqualTo;

        return {
            key: key,
            type: this.validateFilterType(key, 0),
            comparison: this.validateFilterComparison(key, comparison),
            filterUnit: this.validateFilterUnit(key, MarketingCampaignFilterUnit.None),
            value1: this.validateFilterValue(key, ''),
            value2: this.validateFilterValue2(key, null, comparison),
            subFilters: null
        }
    }

    mapFilterGroup = (group: MarketingCampaignFilterGroup): FilterGroup => {
        const key = generateTempId();

        return {
            key: key,
            type: this.validateFilterGroupType(key, group.type),
            filters: group.filters.map(this.mapFilter),
        }
    }

    mapFilter = (filter: MarketingCampaignFilter) : Filter => {
        const key = generateTempId();
        return {
            key: key,
            type: this.validateFilterType(key, filter.type),
            comparison: this.validateFilterComparison(key, filter.comparison),
            filterUnit: this.validateFilterUnit(key, filter.filterUnit),
            value1: this.validateFilterValue(key, filter.value1),
            value2: this.validateFilterValue2(key, filter.value2, filter.comparison),
            subFilters: filter.subFilters ? this.mapFilterGroup(filter.subFilters) : null
        }
    }

    componentDidUpdate(prevProps: EditCampaignPageProps) {
        // Only update state is resource has changed
        const campaignId = this.props.match.params.campaignId;
        const prevCampaignId = prevProps.match.params.campaignId;

        if ((!prevCampaignId && campaignId) || (prevCampaignId && !campaignId) || (prevCampaignId !== campaignId) || (this.state.campaignId !== campaignId && this.props.campaigns.findIndex(c => c.id === campaignId) >= 0)) {
            this.setState(s => this.buildStateFromProps(this.props));
        }

        const { saveComplete } = this.props;
        if (!prevProps.saveComplete && saveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    componentWillUnmount() {
        const { emailTemplateKey } = this.state;
        sessionStorage.removeItem(emailTemplateKey);
    }

    saveCampaign = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    close = () => {
        const { history } = this.props;
        const { isNew, campaignId } = this.state;

        if (isNew) history.push({ pathname: '/campaigns/' });
        else history.push({ pathname: `/campaign/${campaignId}/view` });
    }


    isFilterGroupValid = (group: FilterGroup): boolean => {
        var allFiltersValid = group.filters.reduce((agg, f) => agg && this.isFilterValid(f), true);
        return allFiltersValid;
    }

    isFilterValid = (filter: Filter) => {
        const filterValid = filter.comparison.isValid
            && filter.filterUnit.isValid
            && filter.type.isValid
            && filter.value1.isValid
            && filter.value2.isValid
            && (filter.subFilters == null || this.isFilterGroupValid(filter.subFilters));
        return filterValid;
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            this.setState({ validationError: null, error: 'Global:formNotValid' });
        } else {
            const { isNew, name, campaignState, runInterval, dayOfWeekToRun, dailyRunStartTime, dailyRunEndTime, dayOfMonthToRun,
                dayOfMonthValue, startDate, endDate, filters, emailSubjectTemplate, emailTemplateKey, minimumTimeIntervalBeforeResend,
                selectedVenueIds, sendFromEmailAddressOverride, sendFromEmailNameOverride } = this.state;
            const { saveCampaign, campaigns, venues } = this.props;
            const campaignId = isNew ? null : this.state.campaignId;
            const campaign = campaignId && !isNew ? campaigns.find(c => c.id === campaignId) : null;

            const hasFilterError = !this.isFilterGroupValid(filters);
            if (hasFilterError) {
                this.setState({ error: 'CampaignPage:correctFilterErrors' });
                return;
            }

            const editor = document.getElementById("htmlEditor") as HTMLIFrameElement;
            if (!editor) {
                this.setState({ error: 'unable to access editor iframe' });
                return;
            }

            const contentWindow = editor.contentWindow as any;
            if (!contentWindow || !contentWindow.saveEditor) {
                this.setState({ error: 'unable to access save hook in iframe' });
                return;
            }

            contentWindow.saveEditor();

            if (runInterval.value === MarketingCampaignRunInterval.Weekly && dayOfWeekToRun.value === Days.None) {
                this.setState({ error: 'CampaignPage:selectDaysToRun' });
                return;
            }

            const dataStr = sessionStorage.getItem(emailTemplateKey);
            const data = dataStr ? JSON.parse(dataStr) : {};
            const html = data && data.html ? data.html : '';
            const json = data && data.template ? JSON.stringify(data.template) : '';

            saveCampaign(isNew,
                campaignId,
                {
                    id: campaignId || '',
                    name: name.value,
                    clientEmailTemplateId: '',
                    state: campaignState.value,
                    runInterval: runInterval.value,
                    dayOfWeekToRun: dayOfWeekToRun.value,
                    dailyRunStartTime: dailyRunStartTime.value,
                    dailyRunEndTime: runInterval.value === MarketingCampaignRunInterval.Hourly || runInterval.value === MarketingCampaignRunInterval.Daily ? dailyRunEndTime.value : undefined,
                    dayOfMonthToRun: dayOfMonthToRun.value,
                    dayOfMonthValue: dayOfMonthToRun.value === DayOfMonth.SpecificDay ? dayOfMonthValue.value : 0,
                    startTime: startDate.value ? startDate.value.toDate() : new Date(),
                    endTime: endDate.value ? endDate.value.toDate() : null,
                    minimumTimeIntervalBeforeResend: minimumTimeIntervalBeforeResend.value,
                    emailSubjectTemplate: emailSubjectTemplate.value,
                    emailHtmlTemplate: html,
                    emailJsonTemplate: json,
                    sendFromEmailAddressOverride: sendFromEmailAddressOverride.value,
                    sendFromEmailNameOverride: sendFromEmailNameOverride.value,
                    venueFilter: venues.length > 1 ? selectedVenueIds : [],
                    filters: this.mapMarketingCampaignFilterGroup(filters),
                    totalEmails: campaign ? campaign.totalEmails : 0,
                    deliveredEmails: campaign ? campaign.deliveredEmails : 0,
                    openedEmails: campaign ? campaign.openedEmails : 0,
                    clickedEmails: campaign ? campaign.clickedEmails : 0,
                    bouncedEmails: campaign ? campaign.bouncedEmails : 0,
                    nextRunTime: null
                });

            this.setState({ validationError: null });
        }
    }

    mapMarketingCampaignFilterGroup = (group: FilterGroup): MarketingCampaignFilterGroup => ({
        filters: group.filters.map(this.mapMarketingCampaignFilter),
        type: group.type.value,
    })

    mapMarketingCampaignFilter = (filter: Filter): MarketingCampaignFilter => ({
        type: filter.type.value,
        comparison: filter.comparison.value,
        value1: filter.value1.value,
        value2: filter.value2.value,
        filterUnit: filter.filterUnit.value,
        subFilters: filter.subFilters ? this.mapMarketingCampaignFilterGroup(filter.subFilters) : null
    })

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);

    validateRunInterval = (val: MarketingCampaignRunInterval) => v.validate(val, 'runInterval', [v.required], this.props.validationErrors);

    validateDayOfWeekToRun = (runInterval: MarketingCampaignRunInterval, val: Days) => v.validate(val, 'dayOfWeekToRun', [v.required], this.props.validationErrors);
    validateDailyRunStartTime = (runInterval: MarketingCampaignRunInterval, val: Time) => v.validate(val, 'dailyRunStartTime', [v.required], this.props.validationErrors);
    validateDailyRunEndTime = (runInterval: MarketingCampaignRunInterval, endTime: Time, startTime: Time) => {
        if (runInterval === MarketingCampaignRunInterval.Hourly || runInterval === MarketingCampaignRunInterval.Daily) {
            if (endTime.val() <= startTime.val()) {
                return { controlId: 'dailyRunEndTime', value: endTime, isValid: false, errorMessageKey: 'CampaignPage:endTimeBeforeStartTime', hasValidation: true }
            } else {
                return v.validate(endTime, 'dailyRunEndTime', [v.required], this.props.validationErrors)
            }
        } else {
            return v.validate(endTime, 'dailyRunEndTime', [], this.props.validationErrors);
        }
    }

    validateDayOfMonthToRun = (runInterval: MarketingCampaignRunInterval, val: DayOfMonth) => v.validate(val, 'dayOfMonthToRun', [v.required], this.props.validationErrors);
    validateDayOfMonthValue = (runInterval: MarketingCampaignRunInterval, val: number) => v.validate(val, 'dayOfMonthValue', [v.required], this.props.validationErrors);

    validateCampaignState = (val: MarketingCampaignState) => v.validate(val, 'campaignState', [v.required], this.props.validationErrors);
    validateEmailSubjectTemplate = (val: string) => v.validate(val, 'emailSubject', [v.required], this.props.validationErrors);
    validateEmailHtmlTemplate = (val: string) => v.validate(val, 'emailHtmlTemplate', [v.required], this.props.validationErrors);
    validateEmailJsonTemplate = (val: string) => v.validate(val, 'emailJsonTemplate', [v.required], this.props.validationErrors);
    validateSendFromEmailAddressOverride = (val: string) => v.validate(val, 'sendFromEmailAddressOverride', [v.email], this.props.validationErrors);
    validateSendFromEmailNameOverride = (val: string) => v.validate(val, 'sendFromEmailNameOverride', [], this.props.validationErrors);

    validateStartDate = (startDate: moment.Moment | null, runInterval: MarketingCampaignRunInterval) => v.validate(startDate, 'startDate', [v.required], this.props.validationErrors);
    validateEndDate = (startDate: moment.Moment | null, endDate: moment.Moment | null) => (endDate && startDate && endDate <= startDate)
        ? { controlId: 'endDate', value: endDate, isValid: false, errorMessageKey: 'CampaignPage:endDateBeforeStartDate', hasValidation: true }
        : { controlId: 'endDate', value: endDate, isValid: true, hasValidation: true };

    validateMinimumTimeIntervalBeforeResend = (val: Interval) => v.validate(val, 'minimumTimeIntervalBeforeResend', [v.required], this.props.validationErrors);

    validateFilterGroupType = (key: string, val: MarketingCampaignFilterGroupType) => v.validate(val, `${key}_groupType`, [v.required], this.props.validationErrors)
    validateFilterGroupFirstValue = (key: string, val: string) => v.validate(val, `${key}_value1`, [v.required], this.props.validationErrors)

    validateFilterType = (key: string, val: MarketingCampaignFilterType) => v.validate(val, `${key}_type`, [v.required, val => val > 0 ? undefined :'validation:required'], this.props.validationErrors)
    validateFilterComparison = (key: string, val: MarketingCampaignFilterComparison) => v.validate(val, `${key}_comparison`, [v.required], this.props.validationErrors)
    validateFilterUnit = (key: string, val: MarketingCampaignFilterUnit) => v.validate(val, `${key}_unit`, [v.required], this.props.validationErrors)
    validateFilterValue = (key: string, val: string) => v.validate(val, `${key}_value1`, [v.required], this.props.validationErrors)
    validateFilterValue2 = (key: string, val: string | null, comparison: MarketingCampaignFilterComparison) => v.validate(val, `${key}_value2`, comparison === MarketingCampaignFilterComparison.Between ? [v.required] : [], this.props.validationErrors)

    onStartDateChanged = (startDate: moment.Moment) => this.setState(s => ({ startDate: ct.asFormValue('startDate', startDate), endDate: this.validateEndDate(startDate, s.endDate.value) }))
    onEndDateChanged = (endDate: moment.Moment) => this.setState(s => ({ startDate: s.startDate, endDate: this.validateEndDate(s.startDate.value, endDate) }))

    onTimesChanged = (startTime: Time, endTime: Time, runInterval: MarketingCampaignRunInterval) => {
        this.setState(s => ({ dailyRunStartTime: this.validateDailyRunStartTime(runInterval, startTime), dailyRunEndTime: this.validateDailyRunEndTime(runInterval, endTime, startTime) }))
    }

    onRunIntervalChanged = (val: string) => {
        const runInterval = parseInt(val);
        this.setState(state => ({
            runInterval: this.validateRunInterval(runInterval),
            dayOfWeekToRun: this.validateDayOfWeekToRun(runInterval, state.dayOfWeekToRun.value),
            dailyRunStartTime: this.validateDailyRunStartTime(runInterval, state.dailyRunStartTime.value),
            dailyRunEndTime: this.validateDailyRunEndTime(runInterval, state.dailyRunEndTime.value, state.dailyRunStartTime.value),
            dayOfMonthToRun: this.validateDayOfMonthToRun(runInterval, state.dayOfMonthToRun.value),
            dayOfMonthValue: this.validateDayOfMonthValue(runInterval, state.dayOfMonthValue.value),
            startDate: this.validateStartDate(state.startDate.value, runInterval),
            endDate: this.validateEndDate(state.startDate.value, state.endDate.value),
        }))
    }

    setDayOfWeekToRun = (day: Days, value: boolean) => {
        this.setState(prev => {
            let currentDays = prev.dayOfWeekToRun.value;

            if (value) currentDays |= day;
            else currentDays &= ~day

            return { dayOfWeekToRun: this.validateDayOfWeekToRun(prev.runInterval.value, currentDays) }
        })
    }

    onDayOfMonthToRunChanged = (dayOfMonth: string) => {
        const intVal = parseInt(dayOfMonth);
        this.setState(s => ({ dayOfMonthToRun: this.validateDayOfMonthValue(s.runInterval.value, intVal) }))
    }

    onFilterTypeChanged = (filterKey: string, val: string) => this.setState(s => ({ filters: this.updateFilter(s.filters, filterKey, f => ({ ...f, type: this.validateFilterType(filterKey, parseInt(val)), value1: this.validateFilterValue(filterKey, ''), value2: this.validateFilterValue2(filterKey, '', f.comparison.value) }))  }))
    onFilterComparisonChanged = (filterKey: string, val: string) => this.setState(s => ({ filters: this.updateFilter(s.filters, filterKey, f => ({ ...f, comparison: this.validateFilterComparison(filterKey, parseInt(val)), value2: this.validateFilterValue2(filterKey, f.value2.value, parseInt(val)) })) }))
    onfilterValueChanged = (filterKey: string, val: string) => this.setState(s => ({ filters: this.updateFilter(s.filters, filterKey, f => ({ ...f, value1: this.validateFilterValue(filterKey, val) })) }))
    onfilterValue2Changed = (filterKey: string, val: string) => this.setState(s => ({ filters: this.updateFilter(s.filters, filterKey, f => ({ ...f, value2: this.validateFilterValue2(filterKey, val, f.comparison.value) })) }))
    onFilterValuesChanged = (filterKey: string, val1: string, val2: string | null) => this.setState(s => ({ filters: this.updateFilter(s.filters, filterKey, f => ({ ...f, value1: this.validateFilterValue(filterKey, val1), value2: this.validateFilterValue2(filterKey, val2, f.comparison.value) })) }))
    onFilterUnitChanged = (filterKey: string, val: MarketingCampaignFilterUnit) => this.setState(s => ({ filters: this.updateFilter(s.filters, filterKey, f => ({ ...f, filterUnit: this.validateFilterUnit(filterKey, val) })) }))
    removeFilter = (groupKey: string, filterKey: string) => this.setState(s => ({ filters: this.removeFilterIfMatch(groupKey, filterKey, s.filters) }));

    onVenueSelectionChanged = (venueId: string, selected: boolean) => this.setState(s => ({ selectedVenueIds: selected ? s.selectedVenueIds.indexOf(venueId) < 0 ? s.selectedVenueIds.concat(venueId) : s.selectedVenueIds : s.selectedVenueIds.filter(id => id !== venueId) })) 

    updateFilterGroup = (group: FilterGroup, key: string, updateFn: (f: FilterGroup) => FilterGroup): FilterGroup => {
        return group.key === key ? updateFn(group) : { ...group, filters: group.filters ? group.filters.map(f => ({ ...f, subFilters: f.subFilters ? this.updateFilterGroup(f.subFilters, key, updateFn) : f.subFilters })) : group.filters }
    }

    updateFilter = (group: FilterGroup, filterKey: string, updateFn: (f: Filter) => Filter): FilterGroup => {
        return { ...group, filters: group.filters.map(f => f.key === filterKey ? updateFn(f) : {...f, subFilters: f.subFilters ? this.updateFilter(f.subFilters, filterKey, updateFn) : f.subFilters }) }
    }

    addFilterToGroup = (parentGroupKey: string, type: MarketingCampaignFilterGroupType) => this.setState(s => ({ filters: this.addGroupToFilterIfMatch(parentGroupKey, s.filters, type)}));

    addGroupToFilterIfMatch = (parentGroupKey: string, group: FilterGroup, type: MarketingCampaignFilterGroupType): FilterGroup => {
        return {
            ...group,
            filters: group.key === parentGroupKey
                ? (group.filters || []).concat(this.createFilter())
                : (group.filters || []).map(f => f.subFilters ? { ...f, subFilters: this.addGroupToFilterIfMatch(parentGroupKey, f.subFilters, type) } : f)
        } 
    }

    addSubFilterGroup = (parentGroupKey: string, filterKey: string, type: MarketingCampaignFilterGroupType) => this.setState(s => ({ filters: this.addSubFilterGroupIfMatch(parentGroupKey, filterKey, s.filters, type) }));

    addSubFilterGroupIfMatch = (parentGroupKey: string, filterKey: string, group: FilterGroup, type: MarketingCampaignFilterGroupType): FilterGroup => {
        return {
            ...group,
            filters: (group.filters || []).map(f => f.key === filterKey ? { ...f, subFilters: this.createFilterGroup(type) } : { ...f, subFilters: f.subFilters ? this.addSubFilterGroupIfMatch(parentGroupKey, filterKey, f.subFilters, type) : f.subFilters})
        }
    }

    removeFilterIfMatch = (parentGroupKey: string, filterKey: string, group: FilterGroup): FilterGroup => {
        if (group.key === parentGroupKey) {
            const filtered = (group.filters || []).filter(f => f.key !== filterKey);
            return {
                ...group,
                filters: filtered, type: filtered.length === 0 ? this.validateFilterGroupType(group.key, MarketingCampaignFilterGroupType.None) : group.type,
            }
        } else {
            return { ...group, filters: group.filters.map(f => f.subFilters ? { ...f, subFilters: this.removeFilterIfMatch(parentGroupKey, filterKey, f.subFilters) } : f )}
        }
    }

    render() {

        let message: any;
        const { t } = this.context;
        const { name, runInterval, campaignState, startDate, endDate, filters, selectedVenueIds, dayOfWeekToRun, dailyRunStartTime,
            dailyRunEndTime, dayOfMonthToRun, dayOfMonthValue, validationError, isNew, emailSubjectTemplate, emailTemplateId,
            minimumTimeIntervalBeforeResend, error, showTemplateLoading, sendFromEmailAddressOverride, sendFromEmailNameOverride } = this.state;
        const { clientId, saveError, saveComplete, venues, activityFormats, customerTags, customerCategories, membershipTypes, dateFormat, timeFormat } = this.props;

        if (saveError) {
            message = <ApiError error={saveError} />;
        } else if (validationError) {
            message = (<div className='alert alert-danger'>{validationError}</div>);
        } else if (!isNullOrEmpty(error)) {
            message = (<div className='alert alert-danger'>{t(error)}</div>);
        } else if (saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        const runIntervalOptions = Object.keys(MarketingCampaignRunInterval).filter(key => typeof MarketingCampaignRunInterval[key as any] === 'number').map(key => ({ key: MarketingCampaignRunInterval[key as any].toString(), name: t(`MarketingCampaignRunInterval:${key}`) }));

        const canEdit = campaignState.value === MarketingCampaignState.Draft || campaignState.value === MarketingCampaignState.Paused;
        return <div>
            <h2>{isNew ? t('CampaignPage:addCampaign') : name.value}</h2>

            {!canEdit ? <div className='alert alert-danger'>{t('CampaignPage:invalidCampaignState')}</div>  : null }

            <form className='data-form' onSubmit={this.saveCampaign} autoComplete='off'>
                <ct.TextBox id='name' labelKey='CampaignPage:name' placeholderKey='CampaignPage:name' value={name} callback={val => this.setState({ name: this.validateName(val) })} />

                <label>{t('CampaignPage:campaignState')}</label>
                <div>{t(`MarketingCampaignState:${MarketingCampaignState[campaignState.value]}`)}</div>

                <ct.Select id='runInterval' labelKey='CampaignPage:runInterval' value={({ ...runInterval, value: runInterval.value.toString() })} callback={this.onRunIntervalChanged} options={runIntervalOptions} />

                {this.renderRunOptions(runInterval.value, dayOfWeekToRun, dailyRunStartTime, dailyRunEndTime, dayOfMonthToRun, dayOfMonthValue, timeFormat)}

                {runInterval.value !== MarketingCampaignRunInterval.Manual
                    ? <>
                        <ct.DatePicker id='startDate' labelKey='CampaignPage:startDate' value={startDate} callback={this.onStartDateChanged} dateFormat={dateFormat} />
                        <ct.DatePicker id='endDate' labelKey='CampaignPage:endDate' value={endDate} callback={this.onEndDateChanged} dateFormat={dateFormat} />
                    </>
                    : null
                }

                <TimeInterval id='minimumTimeIntervalBeforeResend' labelKey='CampaignPage:minIntervalBetweenResends' value={minimumTimeIntervalBeforeResend} callback={val => this.setState({minimumTimeIntervalBeforeResend: this.validateMinimumTimeIntervalBeforeResend(val)}) } />

                {venues.length > 1
                    ? <>
                        <label>{t('CampaignPage:venueSelection')}</label>
                        <CampaignVenueSelection venues={venues} selectedVenueIds={selectedVenueIds} venueSelectionChanged={this.onVenueSelectionChanged} />
                    </>
                    : null}

                <label>{t('CampaignPage:customerFilters')}</label>
                <CampaignFilterGroup
                    group={filters}
                    level={0}
                    venues={venues}
                    selectedVenueIds={selectedVenueIds}
                    activityFormats={activityFormats}
                    customerTags={customerTags}
                    customerCategories={customerCategories}
                    membershipTypes={membershipTypes}
                    addFilterToGroup={this.addFilterToGroup}
                    addSubFilterGroup={this.addSubFilterGroup}
                    onFilterTypeChanged={this.onFilterTypeChanged}
                    onFilterComparisonChanged={this.onFilterComparisonChanged}
                    onfilterValueChanged={this.onfilterValueChanged}
                    onfilterValue2Changed={this.onfilterValue2Changed}
                    onFilterValuesChanged={this.onFilterValuesChanged}
                    onFilterUnitChanged={this.onFilterUnitChanged}
                    removeFilter={this.removeFilter}
                />

                <p/>
                <ct.TextBox id='subjectTemplate' labelKey='EmailTemplateForm:subjectTemplate' placeholderKey='EmailTemplateForm:subjectTemplate' value={emailSubjectTemplate} callback={val => this.setState({ emailSubjectTemplate: this.validateEmailSubjectTemplate(val) })} />

                <ct.TextBox id='sendFromEmailAddressOverride' labelKey='EmailTemplateForm:sendFromEmailAddressOverride' hintKey='EmailTemplateForm:sendFromEmailAddressOverrideHint' placeholderKey='EmailTemplateForm:sendFromEmailAddressOverride' value={sendFromEmailAddressOverride} callback={val => this.setState({ sendFromEmailAddressOverride: this.validateSendFromEmailAddressOverride(val) })} />
                <ct.TextBox id='sendFromEmailNameOverride' labelKey='EmailTemplateForm:sendFromEmailNameOverride' hintKey='EmailTemplateForm:sendFromEmailNameOverrideHint' placeholderKey='EmailTemplateForm:sendFromEmailNameOverride' value={sendFromEmailNameOverride} callback={val => this.setState({ sendFromEmailNameOverride: this.validateSendFromEmailNameOverride(val) })} />

                <div className='panel panel-info'>
                    <div className='panel-heading'>
                        <h3 className='panel-title'>{this.context.t('EmailTemplateForm:emailTags')}</h3></div>
                    <div className='panel-body'>
                        <span>{this.context.t('EmailTemplateForm:emailTagsText')}</span>
                        <ul className='plain-list'>
                            <li key='first_name'>{'{{first_name}}'}<ct.Help text={t('MarketingEmailTag:first_name')} /></li>
                            <li key='last_name'>{'{{last_name}}'}<ct.Help text={t('MarketingEmailTag:last_name')} /></li>
                            <li key='full_name'>{'{{full_name}}'}<ct.Help text={t('MarketingEmailTag:full_name')} /></li>
                        </ul>
                    </div>
                </div>

                <label>{t('CampaignPage:emailTemplate')}</label>
                <div className='email-template-editor-wrapper' style={{ position: 'relative' }}>
                    <iframe id='htmlEditor' title='Template editor' className='email-template-editor-frame' src={`/campaignEmail/${clientId}/template/${emailTemplateId}`} onLoad={() => this.setState({showTemplateLoading: false}) } />
                    {showTemplateLoading ? <div className='email-template-editor-frame-loading-overlay'>{t('EmailTemplateForm:loadingTemplate') }</div> : null}
                </div>

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={!canEdit}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }


    renderRunOptions = (runInterval: MarketingCampaignRunInterval, dayOfWeekToRun: ct.FormValue<Days>, dailyRunStartTime: ct.FormValue<Time>, dailyRunEndTime: ct.FormValue<Time>, dayOfMonthToRun: ct.FormValue<DayOfMonth>, dayOfMonthValue: ct.FormValue<number>, timeFormat: TimeFormat) => {
        if (runInterval === MarketingCampaignRunInterval.Manual) {
            return null;
        } else if (runInterval === MarketingCampaignRunInterval.Hourly || runInterval === MarketingCampaignRunInterval.Daily) {
            return <>
                {this.renderDaysOfWeekToRun(dayOfWeekToRun.value)}
                <div className='row form-group'>
                    <div className='col-xs-6'>{this.renderDailyRunStartTime(dailyRunStartTime, 'CampaignPage:dailyRunStartTime', dailyRunEndTime, runInterval, timeFormat)}</div>
                    <div className='col-xs-6'>{this.renderDailyRunEndTime(dailyRunEndTime, dailyRunStartTime, runInterval, timeFormat)}</div>
                </div>
            </>
        } else if (runInterval === MarketingCampaignRunInterval.Weekly) {
            return <>
                {this.renderDayOfWeekToRun(dayOfWeekToRun)}
                {this.renderDailyRunStartTime(dailyRunStartTime, 'CampaignPage:dailyRunTime', dailyRunEndTime, runInterval, timeFormat)}
            </>
        } else {
            return <>
                {this.dayOfMonthToRun(dayOfMonthToRun, dayOfMonthValue)}
                {this.renderDailyRunStartTime(dailyRunStartTime, 'CampaignPage:dailyRunTime', dailyRunEndTime, runInterval, timeFormat)}
            </>
        }
    }

    renderDaysOfWeekToRun = (dayOfWeekToRun: Days) => {
        const { t } = this.context;
        return <>
            <label>{t('CampaignPage:daysOfWeekToRun')}</label>
            <table className='table table-condensed' style={{ maxWidth: '400px'}}>
                <thead>
                    <tr key='dayOfWeekToRun_header'>
                        <th>{t('Global:Monday2ch')}</th>
                        <th>{t('Global:Tuesday2ch')}</th>
                        <th>{t('Global:Wednesday2ch')}</th>
                        <th>{t('Global:Thursday2ch')}</th>
                        <th>{t('Global:Friday2ch')}</th>
                        <th>{t('Global:Saturday2ch')}</th>
                        <th>{t('Global:Sunday2ch')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key='dayOfWeekToRun_selections'>
                        <td><input type='checkbox' checked={(dayOfWeekToRun & Days.Monday) === Days.Monday} onChange={x => this.setDayOfWeekToRun(Days.Monday, x.currentTarget.checked)} /></td>
                        <td><input type='checkbox' checked={(dayOfWeekToRun & Days.Tuesday) === Days.Tuesday} onChange={x => this.setDayOfWeekToRun(Days.Tuesday, x.currentTarget.checked)} /></td>
                        <td><input type='checkbox' checked={(dayOfWeekToRun & Days.Wednesday) === Days.Wednesday} onChange={x => this.setDayOfWeekToRun(Days.Wednesday, x.currentTarget.checked)} /></td>
                        <td><input type='checkbox' checked={(dayOfWeekToRun & Days.Thursday) === Days.Thursday} onChange={x => this.setDayOfWeekToRun(Days.Thursday, x.currentTarget.checked)} /></td>
                        <td><input type='checkbox' checked={(dayOfWeekToRun & Days.Friday) === Days.Friday} onChange={x => this.setDayOfWeekToRun(Days.Friday, x.currentTarget.checked)} /></td>
                        <td><input type='checkbox' checked={(dayOfWeekToRun & Days.Saturday) === Days.Saturday} onChange={x => this.setDayOfWeekToRun(Days.Saturday, x.currentTarget.checked)} /></td>
                        <td><input type='checkbox' checked={(dayOfWeekToRun & Days.Sunday) === Days.Sunday} onChange={x => this.setDayOfWeekToRun(Days.Sunday, x.currentTarget.checked)} /></td>
                    </tr>
                </tbody>
            </table>

        </>
    }

    renderDayOfWeekToRun = (dayOfWeekToRun: ct.FormValue<Days>) => {
        const { t } = this.context;
        const dayOfWeekToRunOptions = Object.keys(Days).filter(key => typeof Days[key as any] === 'number').map(key => ({ key: Days[key as any].toString(), name: t(`Days:${key}`) }));
        return <ct.Select id='dayOfWeekToRun' labelKey='CampaignPage:dayOfWeekToRun' value={{ ...dayOfWeekToRun, value: dayOfWeekToRun.value.toString() }} callback={val => this.setState(s => ({ dayOfWeekToRun: this.validateDayOfWeekToRun(s.runInterval.value, parseInt(val))  })) } options={dayOfWeekToRunOptions} />
    }

    dayOfMonthToRun = (dayOfMonthToRun: ct.FormValue<DayOfMonth>, dayOfMonthValue: ct.FormValue<number>) => {
        const { t } = this.context;
        const dayOfMonthToRunOptions = Object.keys(DayOfMonth).filter(key => typeof DayOfMonth[key as any] === 'number').map(key => ({ key: DayOfMonth[key as any].toString(), name: t(`DayOfMonth:${key}`) }));
        return <div className='row form-group'>
            <div className='col-xs-8'>
                <ct.Select id='dayOfMonthToRun' labelKey='CampaignPage:dayOfMonthToRun' value={{ ...dayOfMonthToRun, value: dayOfMonthToRun.value.toString() }} callback={val => this.onDayOfMonthToRunChanged(val)} options={dayOfMonthToRunOptions} />
            </div>
            <div className='col-xs-4'>
                {dayOfMonthToRun.value === DayOfMonth.SpecificDay ? <ct.NumberBox id='dayOfMonthValue' labelKey='CampaignPage:dayOfMonthValue' value={dayOfMonthValue} callback={val => this.setState(s => ({ dayOfMonthValue: this.validateDayOfMonthValue(s.runInterval.value, val || 0) }))} min='1' max='31' /> : null}
            </div>
        </div>
    }

    renderDailyRunStartTime = (dailyRunStartTime: ct.FormValue<Time>, labelKey: string, dailyRunEndTime: ct.FormValue<Time>, runInterval: MarketingCampaignRunInterval, timeFormat: TimeFormat) => {
        return <ct.Time id='dailyRunStartTime' labelKey={labelKey} value={dailyRunStartTime} timeFormat={timeFormat} callback={val => this.onTimesChanged(val ? val : Time.zero(), dailyRunEndTime.value, runInterval)} />
    }

    renderDailyRunEndTime = (dailyRunEndTime: ct.FormValue<Time>, dailyRunStartTime: ct.FormValue<Time>, runInterval: MarketingCampaignRunInterval, timeFormat: TimeFormat) => {
        return <ct.Time id='dailyRunEndTime' labelKey='CampaignPage:dailyRunEndTime' value={dailyRunEndTime} timeFormat={timeFormat} callback={val => this.onTimesChanged(dailyRunStartTime.value, val ? val : Time.zero(), runInterval)} />
    }
};

const mapStateToProps = (state: ApplicationState) => ({
    clientId: state.login.client.id,
    campaigns: state.campaigns.campaigns,
    saveComplete: state.campaigns.saveComplete,
    saveError: state.campaigns.saveError,
    validationErrors: state.campaigns.validationErrors,
    venues: state.venues.venues,
    activityFormats: state.activityFormats.activityFormats,
    customerTags: state.tags.tags.filter(t => t.customerCount > 0),
    customerCategories: state.customerCategories.customerCategories,
    membershipTypes: state.memberships.membershipTypes,
    dateFormat: state.venues.dateFormat,
    timeFormat: state.venues.timeFormat
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveCampaign: bindActionCreators(CampaignActions.actionCreators.saveCampaign, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(EditCampaignPage);
