import { DateFormat, TimeFormat } from "../store/pages/venues/types";
import { formatPosition } from "./util";

export { }

export const monthNames = ['Global:January', 'Global:February', 'Global:March', 'Global:April', 'Global:May', 'Global:June', 'Global:July', 'Global:August', 'Global:September', 'Global:October', 'Global:November', 'Global:December'];
export const monthNameAbbreviations = ['Global:JanAbbr', 'Global:FebAbbr', 'Global:MarAbbr', 'Global:AprAbbr', 'Global:MayAbbr', 'Global:JunAbbr', 'Global:JulAbbr', 'Global:AugAbbr', 'Global:SepAbbr', 'Global:OctAbbr', 'Global:NovAbbr', 'Global:DecAbbr'];
const dayOfWeekAbbreviations = ['Global:Sunday3ch', 'Global:Monday3ch', 'Global:Tuesday3ch', 'Global:Wednesday3ch', 'Global:Thursday3ch', 'Global:Friday3ch', 'Global:Saturday3ch'];

/* eslint-disable no-extend-native */

declare global {
    interface Date {
        addHours(hours: number): Date;
        addMinutes(minutes: number): Date;
        toShortTimeString(format: TimeFormat): string;
        toLongTimeString(format: TimeFormat): string;
        toFullTimeString(format: TimeFormat): string;
        toYMDDateString(): string;
        toYMDHMDateString(): string;
        toApiDateTimeString(): string;
        toApiDateOnlyString(): string;
        toShortDateString(format: DateFormat): string;
        toLongDateString(format: DateFormat, t: (val: string) => string): string;
        toAbbrDateString(format: DateFormat, t: (val: string) => string): string;
        toAbbrDateStringWithDay(format: DateFormat, t: (val: string) => string): string;
        toAbbrDateTimeString(timeFormat: TimeFormat, dateFormat: DateFormat, t: (val: string) => string): string;
        toAbbrMonthDayString(format: DateFormat, t: (val: string) => string): string;
        datePart(): Date;
        asUTC(): Date;
        daysDifference(other: Date): number;
        getLocalEpocDay(): number;
        isToday(): boolean;
        isFutureDay(): boolean;
        isSameDay(other: Date): boolean;
    }

    interface Array<T> {
        merge(other: Array<T>, equalityCheck: (a:T, b:T) => boolean): Array<T>;
    }
}

Date.prototype.datePart = function () {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate());
}


Date.prototype.addHours = function (hours: number) {
    return new Date(this.getTime() + (hours * 60 * 60 * 1000));
}

Date.prototype.addMinutes = function (minutes: number) {
    return new Date(this.getTime() + (minutes * 60 * 1000));
}

Date.prototype.toShortTimeString = function(format: TimeFormat) {
    const h = this.getHours();
    const m = this.getMinutes();

    if (format && format === TimeFormat.TwelveHour) {
        const hrs = h > 12 ? h - 12 : h;
        const mins = m < 10 ? `0${m}` : m.toString();
        const amPm = h >= 12 ? 'pm' : 'am';
        return `${hrs}:${mins}${amPm}`;
    } else {

        const hrs = h < 10 ? `0${h}` : h.toString();
        const mins = m < 10 ? `0${m}` : m.toString();
        return `${hrs}:${mins}`;
    }
}
 
Date.prototype.toFullTimeString = function (format: TimeFormat) {
    const h = this.getHours();
    const m = this.getMinutes();
    const s = this.getSeconds();

    const mins = m < 10 ? `0${m}` : m.toString();
    const secs = s < 10 ? `0${s}` : s.toString();

    if (format && format === TimeFormat.TwelveHour) {
        const hrs = h > 12 ? h - 12 : h;
        const mins = m < 10 ? `0${m}` : m.toString();
        const amPm = h >= 12 ? 'pm' : 'am';
        return `${hrs}:${mins}:${secs}${amPm}`;
    } else {
        const hrs = h < 10 ? `0${h}` : h.toString();
        return `${hrs}:${mins}:${secs}`;
    }
}

Date.prototype.toLongTimeString = function(format: TimeFormat) {
    const h = this.getHours();
    const m = this.getMinutes();
    const s = this.getSeconds();
    const ms = this.getMilliseconds();

    const mins = m < 10 ? `0${m}` : m.toString();
    const secs = s < 10 ? `0${s}` : s.toString();
    const msecs = ms < 10 ? `00${ms}` : ms < 100 ? `0${ms}` : ms.toString();

    if (format && format === TimeFormat.TwelveHour) {
        const hrs = h > 12 ? h - 12 : h;
        const mins = m < 10 ? `0${m}` : m.toString();
        const amPm = h >= 12 ? 'pm' : 'am';
        return `${hrs}:${mins}:${secs}.${msecs}${amPm}`;
    } else {
        const hrs = h < 10 ? `0${h}` : h.toString();
        return `${hrs}:${mins}:${secs}.${msecs}`;
    }
}

Date.prototype.toYMDDateString = function () {
    const y = this.getFullYear();
    const m = this.getMonth() + 1;
    const d = this.getDate();

    return `${y}-${m}-${d}`;
}

Date.prototype.toYMDHMDateString = function () {
    const y = this.getFullYear();
    const mo = this.getMonth() + 1;
    const d = this.getDate();
    const h = this.getHours();
    const mi = this.getMinutes();

    return `${y}-${mo}-${d} ${h}:${mi}`;
}

Date.prototype.toApiDateTimeString = function () {
    const y = this.getFullYear();
    const mo = this.getMonth() + 1;
    const d = this.getDate();
    const h = this.getHours();
    const mi = this.getMinutes();
    const secs = this.getSeconds();

    return `${y}-${twoDigits(mo)}-${twoDigits(d)}T${twoDigits(h)}:${twoDigits(mi)}:${twoDigits(secs)}`;
}

Date.prototype.toApiDateOnlyString = function () {
    const y = this.getFullYear();
    const mo = this.getMonth() + 1;
    const d = this.getDate();
    const h = this.getHours();
    const mi = this.getMinutes();
    const secs = this.getSeconds();

    return `${y}-${twoDigits(mo)}-${twoDigits(d)}`;
}

// TODO: Localize this
Date.prototype.toShortDateString = function (format: DateFormat) {
    const y = this.getFullYear();
    const m = this.getMonth() + 1;
    const d = this.getDate();

    return format === DateFormat.MDY ? `${m}/${d}/${y}` : `${d > 9 ? d : '0' + d}/${m > 9 ? m : '0' + m}/${y}`;
}

Date.prototype.toLongDateString = function (format: DateFormat, t: (val: string) => string) {
    const y = this.getFullYear();
    const m = t(monthNames[this.getMonth()]);
    const d = this.getDate();

    return format === DateFormat.MDY
        ? `${m} ${formatPosition(d, t)} ${y}`
        : `${d > 9 ? d : '0' + d} ${m} ${y}`;
}


Date.prototype.toAbbrDateString = function (format: DateFormat, t: (val: string) => string) {
    const y = this.getFullYear();
    const m = t(monthNameAbbreviations[this.getMonth()]);
    const d = this.getDate();

    return format === DateFormat.MDY
        ? `${m} ${formatPosition(d, t)} ${y}`
        : `${d > 9 ? d : '0' + d} ${m} ${y}`;
}

Date.prototype.toAbbrDateStringWithDay = function (format: DateFormat, t: (val: string) => string) {
    const dow = t(dayOfWeekAbbreviations[this.getDay()]);
    const y = this.getFullYear();
    const m = t(monthNameAbbreviations[this.getMonth()]);
    const d = this.getDate();

    return format === DateFormat.MDY
        ? `${dow} ${m} ${formatPosition(d, t)} ${y}`
        : `${dow} ${d > 9 ? d : '0' + d} ${m} ${y}`;
}

Date.prototype.toAbbrDateTimeString = function (timeFormat: TimeFormat, dateFormat: DateFormat, t: (val: string) => string) {
    return `${this.toAbbrDateString(dateFormat, t)} ${this.toShortTimeString(timeFormat)}`;
}

Date.prototype.toAbbrMonthDayString = function (format: DateFormat, t: (val: string) => string) {
    return format
        ? `${t(monthNameAbbreviations[this.getMonth()])} ${formatPosition(this.getDate(), t)}`
        : `${this.getDate()} ${t(monthNameAbbreviations[this.getMonth()])}`;
}

Date.prototype.getLocalEpocDay = function () {
    const epocMins = this.getTime() / 1000 / 60;
    const offset = this.getTimezoneOffset();
    const localMins = epocMins - offset;
    const epocDays = Math.floor(localMins / 60 / 24);
    return epocDays;
}

Date.prototype.asUTC = function () {
    var now = new Date();
    var nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    return new Date(nowUtc);
}

Date.prototype.daysDifference = function (other: Date) {
    var myTime = this.getLocalEpocDay();
    var otherTime = other.getLocalEpocDay();
    return myTime - otherTime;
}

Date.prototype.isToday = function () {
    const now = new Date();
    return now.getFullYear() === this.getFullYear() && now.getMonth() === this.getMonth() && now.getDate() === this.getDate();
}

Date.prototype.isFutureDay = function () {
    const now = new Date();
    var currentDay = Math.floor(now.getTime() / 1000 / 60 / 60 / 24);
    var thisDay = Math.floor(this.getTime() / 1000 / 60 / 60 / 24);
    return currentDay > thisDay;
}

Date.prototype.isSameDay = function (other: Date) {
    return this.getFullYear() === other.getFullYear() && this.getMonth() === other.getMonth() && this.getDate() === other.getDate();
}

Array.prototype.merge = function<T>(other: Array<T>, equalityCheck: (a: T, b: T) => boolean) {
    let merged:T[] = [];
    let newItems = Object.assign([], other);

    for (let i = 0; i < this.length; i++) {
        let matched = false;

        for (let j = 0; j < newItems.length; j++) {
            if (equalityCheck(this[i], newItems[j])) {
                merged.push(newItems[j])
                matched = true;
                newItems.splice(j, 1);
                j = newItems.length; // break out of the loop
            }

            if (!matched) {
                merged.push(this[i])
            }
        }
    }

    for (let i = 0; i < newItems.length; i++) {
        merged.push(newItems[i])
    }

    return merged;
}

export const flatten = <T>(arr: T[], result: T[] = []) => {
    for (let i = 0, length = arr.length; i < length; i++) {
        const value = arr[i];
        if (Array.isArray(value)) {
            flatten(value, result);
        } else {
            result.push(value);
        }
    }
    return result;
}

const twoDigits = (val: number) => val <= 9 ? `0${val}` : val.toString();