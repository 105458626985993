
import * as React from 'react';
import * as PropTypes from 'prop-types'

import Loading from '../../../global/loading';

import { CookieOptionsType, VenuePublicWebsiteSettings } from '../../../../store/pages/publicWebsiteSettings/types';
import PublicWebsitePagesList from './publicWebsitePagesList';
import GeneralPublicWebsiteSettingsForm from './generalPublicWebsiteSettingsForm'
import ResourceLabel from './resourceLabel';
import { clickHandler } from '../../../../utils/util';
import { Resource } from '../../../../store/pages/resources/types';

interface PublicWebsitesListProps {
    venueId: string;
    isLoadingPublicWebsitePages: boolean;
    publicWebsitePages: VenuePublicWebsiteSettings[];
    editPublicWebsitePages: () => void;
    resources: Resource[];
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
}

class PublicWebsitesList extends React.Component<PublicWebsitesListProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    isLoading = () => this.props.isLoadingPublicWebsitePages;

    viewWebsiteSettings = (websiteSettings: VenuePublicWebsiteSettings | null) => {
        const { venueId, showModal } = this.props;

        showModal(<PublicWebsitePagesList venueId={venueId} publicWebsiteId={websiteSettings ? websiteSettings.publicWebsiteId : 0 } />, 'PublicWebsitePagesList')
    }

    createWebsite = () => {
        const { venueId, resources, showModal, closeModal } = this.props;

        const newWebsiteSettings = this.createDefaultWebsiteSettings(venueId);

        showModal(<GeneralPublicWebsiteSettingsForm venueId={venueId} publicWebsiteSettings={newWebsiteSettings} resources={resources} close={closeModal} />, 'GeneralPublicWebsiteSettings')
    }

    createDefaultWebsiteSettings = (venueId: string): VenuePublicWebsiteSettings => ({
        venueId: venueId,
        publicWebsiteId: 0,
        publicWebsiteName: '',
        hideOnPublicWebsiteList: false,
        archived: false,
        resources: [],
        siteName: '',
        backgroundImageId: '',
        panelColour: '',
        panelOpacity: 100,
        panelTextColour: '',
        primaryButtonColour: '',
        primaryButtonTextColour: '',
        cookieOptionsType: CookieOptionsType.Banner,
        headerImageId: '',
        paymentPage: { heading: '', body: '', payButtonText: '', bodyDataTags: [] },
        onlineBooking: { noAvailabilityMessage: '', bookingConfirmationEmailTemplateId: '', upsellActivitiesPageHeader: '', completionPageContentTemplate: '', availableSlotButtonColour: '', availableSlotButtonTextColour: '', unavailableSlotButtonColour: '', unavailableSlotButtonTextColour: '', showArrivalTime: false, arrivalTimeMessage: '', alternateAvailabilityMessage: '', webBookingFeeId: null, completionPageTags: [], bookingFields: 5, requiredBookingFields: 5, bookingBanner: '', bookingBannerBackgroundColour: '#000000', bookingBannerBorderColour: '#000000' },
        onlineRegistration: { landingPageContent: '', participantsPageHeading: '', completionPageContent: '', registrationKioskId: '' },
        analyticsSettings: [],
        lastUpdatedTimestamp: 0
    })

    render() {
        const { t } = this.context;
        const { venueId, publicWebsitePages } = this.props;

        const websitesByResource = publicWebsitePages.filter(w => !w.archived).reduce<Map<string, { resourceName: string, count: number, isDefault: boolean }>>((rscCounts, pw) =>
        {
            for (var i = 0; i < pw.resources.length; i++) {
                const resource = pw.resources[i];
                const vals = rscCounts.get(resource.resourceId) || { resourceName: resource.resourceName,count: 0, isDefault: false};
                rscCounts.set(resource.resourceId, { resourceName: vals.resourceName, count: vals.count ? vals.count + 1 : 1, isDefault: vals.isDefault || resource.default });
            }
            return rscCounts;
        }, new Map<string, { resourceName: string, count: number, isDefault: boolean }>());

        const hasResourceOverlap = Array.from(websitesByResource.values()).filter(v => v.count > 1).length > 0;

        const nonDefaultResources = Array.from(websitesByResource.values()).filter(v => !v.isDefault).map(v => v.resourceName);

        return <section className='venue-item-list'>
            {this.isLoading()
                ? <Loading />
                : <>
                    <div className='at-panel'>
                        <table className='table table-condensed'>
                            <thead>
                                <tr>
                                    <th>{t('Global:name')}</th>
                                    <th>{t('PublicWebsitesList:website')}</th>
                                    <th>{t('PublicWebsitesList:defaultResources')}</th>
                                    <th>{t('PublicWebsitesList:otherResources')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {publicWebsitePages.filter(ws => ws.venueId === venueId).map(ws => <tr key={ws.publicWebsiteId}>
                                    <td style={{ verticalAlign: 'middle' }}><button className='btn btn-link' onClick={e => clickHandler(e, () => this.viewWebsiteSettings(ws)) }>{ws.publicWebsiteName}</button></td>
                                    <td style={{ verticalAlign: 'middle' }}>{ws.siteName}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{ws.resources.filter(r => r.default).map(r => <ResourceLabel key={r.resourceId} colour={r.colour} name={r.resourceName} />)}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{ws.resources.filter(r => !r.default).map(r => <ResourceLabel key={r.resourceId} colour={r.colour} name={r.resourceName} />)}</td>
                                    <td style={{ verticalAlign: 'middle' }} className='text-right'>{ws.archived ? <span className='label label-danger'>{t('Global:archived')}</span> : null}</td>
                                </tr>)}
                            </tbody>
                        </table>
                        <button className='btn btn-link' onClick={e => clickHandler(e, () => this.createWebsite())}>{t('PublicWebsitesList:addWebsite')}</button>
                    </div>
                    {hasResourceOverlap ? <div className='alert alert-warning' style={{ margin: '15px' }}>{t('PublicWebsitesList:overlappingResourceWarning')}</div> : null}
                    {nonDefaultResources.length > 0 ? <div className='alert alert-warning' style={{ margin: '15px' }}>{t('PublicWebsitesList:nonDefaultResource')}</div> : null}
                </>
            }
        </section>;
    }
}

export default PublicWebsitesList;
