
import * as React from 'react';
import * as PropTypes from 'prop-types'
import ReactQuill from './reactQuill';
import * as Quill from 'quill';
import Delta from 'quill-delta';
import { quillStandardToolbar } from './quillSettings';

import Datetime, { ViewMode } from 'react-datetime';
import moment from 'moment';

import * as gt from '../../store/global/types';
import {  isNullOrEmpty, linkHandler, clickHandler } from '../../utils/util';
import { country } from '../../store/global/countries';
import { ITranslationContext } from '../../translations';
import { Interval, IntervalUnit } from '../../store/global/types';
import { DateFormat, TimeFormat } from '../../store/pages/venues/types';

export interface BaseFormValue {
    controlId: string;
    isValid: boolean;
    hasValidation: boolean;
    errorMessageKey?: string;
}

export interface FormValue<T> extends BaseFormValue {
    value: T;
}

interface BasicInputProps<T> {
    id: string;
    labelKey: string;
    labelContent?: JSX.Element
    labelStyle?: React.CSSProperties;
    value: FormValue<T>;
    callback(newVal: T): void;
    hintKey?: string;
    classNames?: string;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    disabled?: boolean;
    helpTextKey?: string;
    minimal?: boolean;
    maxWidth?: number;
}

interface TypedInputProps<T> extends BasicInputProps<T> {
    placeholderKey?: string,
    autoComplete?: string;
    inputRef?: React.RefObject<HTMLInputElement>;  
}

interface InputProps extends TypedInputProps<string> {
    type: string;
    list?: string;
    pattern?: string;
}

interface TextBoxProperties extends TypedInputProps<string> {
    list?: string;
    maxLength?: number;
}

interface TextAreaProps extends TypedInputProps<string> {
    rows?: number;
    cols?: number;
    noMaxWidth?: boolean;
}

interface DatePickerProps extends BasicInputProps<moment.Moment | null> {
    timeFormat?: boolean | string | TimeFormat;
    dateFormat?: boolean | string | DateFormat;
    initialViewMode?: ViewMode
}

interface DateTimePickerProps extends BasicInputProps<moment.Moment | null> {
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}

interface HelpProps {
    text: string | JSX.Element;
}

export interface SelectOption {
    key: string;
    name: string;
    data?: any;
}

interface SelectProps extends BasicInputProps<string> {
    options: SelectOption[];
    renderOption?: (option: SelectOption) => JSX.Element | JSX.Element[] | string;
}

interface CountrySelectionProps extends TypedInputProps<number> {
    countries: country[];
}

interface ToggleProps {
    id: string;
    value: boolean;
    callback(newVal: boolean): void;
    onLabelKey: string;
    offLabelKey: string;
    classNames?: string;
    width?: number;
    style?: any;
    disabled?: boolean;
}

interface InputState {
    isValid: boolean;
    errorMessageKey: string;
}

interface DecimalNumberProps extends TypedInputProps<number | null> {
    min?: string;
    max?: string;
    step?: string;
}

interface IntNumericTextProps extends TypedInputProps<number> {
    min?: number;
    max?: number;
}

interface NumericTextProps extends TypedInputProps<number | string> {
    min?: number;
    max?: number;
}

interface NumberProps extends TypedInputProps<number | null> {
    min?: string;
    max?: string;
    step?: string;
}


const ErrorMsg = (props: { isValid: boolean, minimal: boolean | undefined, errorMessageKey: string | undefined, t: (key: string) => string }) => !props.minimal || (props.minimal && props.isValid && !isNullOrEmpty(props.errorMessageKey)) ? <span className='help-block' style={{ display: 'inline' }}>{props.isValid || !props.errorMessageKey ? '' : props.t(props.errorMessageKey)}</span> : null;
const Hint = (props: { text: string }) => <span className='help-block' style={{ color: '#0461b1' }}>{props.text}</span>

// Hack - dummy generic parameter needed to make compiler happy.
export const asFormValue = <T, _>(controlId: string, val: T, isValid: boolean = true, hasValidation: boolean = false, errorMessageKey?: string) => ({ controlId: controlId, value: val, isValid: isValid, hasValidation: hasValidation, errorMessageKey: errorMessageKey });

export const TextBox = (props: TextBoxProperties) => <Input {...props} type='text' />;
export const Password = (props: TypedInputProps<string>) => <Input {...props} type='password' />;
export const Email = (props: TypedInputProps<string>) => <Input {...props} type='email' />;
export const PhoneNumber = (props: TypedInputProps<string>) => <Input {...props} type='tel' />;
export const Url = (props: TypedInputProps<string>) => <Input {...props} type='url' />;

const localize = (key: string, t: (key: any, params?: any, comment?: string) => string) => isNullOrEmpty(key) ? '' : t(key);

export const DecimalNumberBox = (props: DecimalNumberProps) => {

    const { value, callback, ...inputProps } = props;
    const val = { ...value, value: value.value !== null && typeof value.value !== 'undefined' ? value.value.toString() : '' };

    return <InputInternal {...inputProps} type='number' value={val} onValueChanged={e => callback(isNullOrEmpty(e.target.value) ? null : e.target.valueAsNumber)} callback={v => { }} />;
}

export const IntNumericText = (props: IntNumericTextProps) => {

    const { value, callback, min, max, ...inputProps } = props;
    const val = { ...value, value: value.value !== null && typeof value.value !== 'undefined' ? value.value.toString() : '' };
    const cb = (newVal: string) => {
        const intVal = parseFloat(newVal);
        let val = isNaN(intVal) ? 0 : intVal;

        if (min && val < min) {
            val = min;
        } else if (max && val > max) {
            val = max;
        }

        callback(val);
    }

    return <Input {...inputProps} type='text' pattern='[0-9]+' value={val} callback={cb} />;
}

export const NumericText = (props: NumericTextProps) => {

    const { value, callback, min, max, ...inputProps } = props;
    const val = { ...value, value: value.value !== null && typeof value.value !== 'undefined' ? typeof value.value ==='string' ? value.value : value.value.toFixed(2) : '' };

    const cb = (newVal: string) => {
        const isValid = /^\d*\.{0,1}\d{0,2}$/.test(newVal); 
        //const isValid = !Number.isNaN(Number.parseFloat(newVal));
        callback(isValid ? newVal : val.value);
    }

    return <Input {...inputProps} type='text' pattern='^\d+(\.|\,)\d{2}$' value={val} callback={cb} />;
}

export const NumberBox = (props: NumberProps) => {

    const { value, callback, ...inputProps } = props;
    const val = { ...value, value: value.value !== null && typeof value.value !== 'undefined' ? value.value.toString() : '' };
    const cb = (newVal: string) => {
        const intVal = parseFloat(newVal);
        callback(isNaN(intVal) ? null : intVal);
    }

    return <Input {...inputProps} type='number' value={val} callback={cb} />;
}

export interface NumberRange { from: number | null; to: number | null; }

export interface NumericRangeProps extends BasicInputProps<NumberRange> { }

export const NumericRange = (props: NumericRangeProps, context: ITranslationContext) => {

    const onFromValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, callback } = props;
        const newNumericValue = parseFloat(event.currentTarget.value);
        callback({ from: !isNaN(newNumericValue) ? newNumericValue : null, to: value.value.to });
    }

    const onToValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, callback } = props;
        const newNumericValue = parseFloat(event.currentTarget.value);
        callback({ from: value.value.from, to: !isNaN(newNumericValue) ? newNumericValue : null });
    }

    const { t } = context;
    const { hintKey, id, value, classNames, labelKey, labelContent, labelStyle, helpTextKey, style, disabled, minimal } = props;

    const hint = !isNullOrEmpty(hintKey) ? <Hint text={t(hintKey)} /> : null;

    const showFeedback = value.hasValidation;
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : ''}`;
    const feedbackCls = `glyphicon ${value.isValid ? '' : ' glyphicon-exclamation-sign'} form-control-feedback`;
    const feedbackGlyph = showFeedback ? <span className={feedbackCls}></span> : null;
    const help = helpTextKey ? <Help text={t(helpTextKey)} /> : null;
    const inputProps = { disabled: (disabled || false), min: '0' };

    const fromValue = value.value.from ? value.value.from : undefined;
    const toValue = value.value.to ? value.value.to : undefined;

    return (
        <div className={cls} style={style}>
            <div className='control-wrapper'>
                {isNullOrEmpty(labelKey) ? null : <label htmlFor={id} style={labelStyle}>{localize(labelKey, t)}{labelContent}</label>}
                {help}
                <div style={({ display: 'flex', flexDirection: 'row', alignItems: 'center' })}>
                    <div style={({ flex: '1 auto' })}>
                        <input id={`${id}_from`} className='form-control' type='number' value={fromValue} onChange={onFromValueChanged} {...inputProps} />
                    </div>
                    <div style={({ margin: '0 5px' })}>{t('Global:to')}</div>
                    <div style={({ flex: '1 auto' })}>
                        <input id={`${id}_to`} className='form-control' type='number' value={toValue} onChange={onToValueChanged} {...inputProps} />
                    </div>
                </div>
                {feedbackGlyph}
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />
                {hint}
            </div>
        </div>);
}

NumericRange.contextTypes = {
    t: PropTypes.func
}


export const Country = (props: CountrySelectionProps) => {
    const { countries, value, callback, placeholderKey, ...selectProps } = props;
    const opts = countries.map(c => ({ key: c.id.toString(), name: c.name }));
    const selectVal = { ...value, value: value.value.toString() };
    const cb = (v: string) => callback(parseInt(v));
    return <Select {...selectProps} options={opts} value={selectVal} callback={cb} />;
}

export const Help = (props: HelpProps) => {
        return <span className='help-icon'><span className='glyphicon glyphicon-question-sign'></span><span className='help-text'>{props.text}</span></span>;
}


export const Input = (props: InputProps) => <InputInternal {...props} onValueChanged={(event: React.ChangeEvent<HTMLInputElement>) => props.callback(event.currentTarget.value)} />

interface InternalInputProps extends InputProps {
    onValueChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputInternal = (props: InternalInputProps, context: ITranslationContext) => {

    const { t } = context;
    const { hintKey, id, value, classNames, labelKey, labelContent, labelStyle, placeholderKey, helpTextKey, style, inputStyle, type, callback, inputRef, minimal, onValueChanged, ...otherProps } = props;

    const hint = hintKey ? <Hint text={t(hintKey)} /> : null;

    const showFeedback = value.hasValidation && (!minimal || !value.isValid);
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : minimal ? '' : ''}`;
    const feedbackCls = `glyphicon ${value.isValid ? '' : ' glyphicon-exclamation-sign'} form-control-feedback`;
    const feedbackGlyph = showFeedback ? <span className={feedbackCls}></span> : null;

    const help = helpTextKey ? <Help text={t(helpTextKey)} /> : null;

    const lblText = localize(labelKey, t);
    const label = isNullOrEmpty(lblText) ? null : <label htmlFor={id} style={labelStyle}>{lblText}{labelContent}</label>;

    return (
        <div className={cls} style={style}>
            <div className='control-wrapper'>
                {label}
                {help}
                <input id={id} className='form-control' type={type} placeholder={placeholderKey ? localize(placeholderKey, t) : undefined} value={value.value} onChange={onValueChanged} style={inputStyle} ref={inputRef} {...otherProps} />
                {feedbackGlyph}
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />
                {hint}
            </div>
        </div>
    );
}

InputInternal.contextTypes = {
    t: PropTypes.func
}

export const TextArea = (props: TextAreaProps, context: ITranslationContext) => {

    const onValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => props.callback(event.currentTarget.value);

    const { t } = context;
    const { id, value, classNames, style, labelKey, labelContent, labelStyle, hintKey, helpTextKey, placeholderKey, cols, rows, noMaxWidth, minimal, callback, ...otherProps } = props;
    const hint = hintKey ? <Hint text={t(hintKey)} /> : null;

    const showFeedback = value.hasValidation && (!minimal || !value.isValid);
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : 'has-feedback'}`;
    const feedbackCls = `glyphicon ${value.isValid ? '' : ' glyphicon-exclamation-sign'} form-control-feedback`;
    const feedbackGlyph = showFeedback ? <span className={feedbackCls}></span> : null;
    const help = helpTextKey ? <Help text={t(helpTextKey)} /> : null;

    const combinedStyle = noMaxWidth ? { ...style, maxWidth: '100%' } : style;

    const textAreaProps: any = {};
    if (rows)
        textAreaProps.rows = rows;

    if (cols)
        textAreaProps.cols = cols;

    return (
        <div className={cls} style={combinedStyle}>
            <div className='control-wrapper'>
                {!isNullOrEmpty(labelKey) ? <label htmlFor={id} style={labelStyle}>{localize(labelKey, t)}{labelContent}</label> : null}
                {help}
                <textarea id={id} className='form-control' placeholder={placeholderKey ? localize(placeholderKey, t) : undefined} value={value.value} onChange={onValueChanged} {...otherProps} {...textAreaProps} />
                {feedbackGlyph}
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />
                {hint}
            </div>
        </div>
    );
}

TextArea.contextTypes = {
    t: PropTypes.func
}

export const Checkbox = (props: TypedInputProps<boolean>, context: ITranslationContext) => {

    const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => props.callback(event.currentTarget.checked);

    const { t } = context;
    const { id, value, classNames, style, labelKey, labelContent, hintKey, callback, minimal, inputRef, maxWidth, ...otherProps } = props;
    const hint = hintKey ? <p><Hint text={t(hintKey)} /></p> : null;

    const cls = `${(classNames || '')} form-group ${value.hasValidation ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : ''}`;
    const wrapperStyle = maxWidth ? { ...style, maxWidth: `${maxWidth}px` } : style;

    return (
        <div className={cls} style={wrapperStyle}>
            <div className='control-wrapper'>
                <label>
                    <input type='checkbox' id={id} checked={value.value} onChange={onValueChanged} ref={inputRef} {...otherProps} />
                    <span style={({ marginLeft: '10px' })}>{localize(labelKey, t)}{labelContent}</span>
                </label>
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />
                {hint}
            </div>
        </div>);
}

Checkbox.contextTypes = {
    t: PropTypes.func
}

export const Toggle = (props: ToggleProps, context: ITranslationContext) => {

    const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => props.callback(event.currentTarget.checked);

    const { t } = context;
    const { id, value, classNames, style, offLabelKey, onLabelKey, width } = props;

    const buttonStyle: React.CSSProperties = width ? { width: `${width}px` } : {};
    const switchStyle: React.CSSProperties = width ? { right: `${value ? 0 : width - 39}px` } : {};

    return (
        <div className={classNames} style={style}>
            <div className='control-wrapper'>
                <div className="onoffswitch" style={buttonStyle}>
                    <input type="checkbox" id={id} name={id} checked={value} onChange={onValueChanged} className="onoffswitch-checkbox" />
                    <label className="onoffswitch-label" htmlFor={id}>
                        <span className="onoffswitch-inner">
                            <span className="onoffswitch-inner-on alert-success">{t(onLabelKey)}</span>
                            <span className="onoffswitch-inner-off alert-danger">{t(offLabelKey)}</span>
                        </span>
                        <span className="onoffswitch-switch" style={switchStyle}></span>
                    </label>
                </div>
            </div>
        </div>);
}

Toggle.contextTypes = {
    t: PropTypes.func
}

export const Select = (props: SelectProps, context: ITranslationContext) => {

    const { t } = context;
    const { id, value, options, classNames, style, labelKey, labelContent, labelStyle, hintKey, callback, renderOption, minimal, disabled } = props;

    const hint = hintKey ? <p><Hint text={t(hintKey)} /></p> : null;

    const showFeedback = value.hasValidation && (!minimal || !value.isValid);
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : minimal ? '' : 'has-feedback'}`;
    
    const lblText = localize(labelKey, t);
    const label = isNullOrEmpty(lblText) ? null : <label htmlFor={id} style={labelStyle}>{lblText}{labelContent}</label>;

    return (
        <div className={cls} style={style}>
            <div className='control-wrapper'>
                {label}
                <PlainSelect id={id} value={value.value} options={options} renderOption={renderOption} callback={callback} disabled={disabled} />
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />
                {hint}
            </div>
        </div>);
}

Select.contextTypes = {
    t: PropTypes.func
}

export interface PlainSelectProps {
    id: string;
    value: string;
    options: SelectOption[];
    renderOption?: (option: SelectOption) => JSX.Element | JSX.Element[] | string;
    callback(newVal: string): void;
    className?: string;
    buttonClassOverride?: string;
    styleOverride?: React.CSSProperties;
    disabled?: boolean;
}

const enableClickHandler = (handler: (e: Event) => void) => {
    document.addEventListener('click', handler);
}

const disableClickHandler = (handler: (e: Event) => void) => {
    document.removeEventListener('click', handler);
}

export const PlainSelect = (props: PlainSelectProps) => {
    const { id, value, options, renderOption, className, buttonClassOverride, styleOverride, callback, disabled } = props;

    const selectedItem = options.find(o => o.key === value);
    const optionRenderer = renderOption || (o => o.name);
    const btnStyle: React.CSSProperties = { width: '100%', display: 'flex', alignItems: 'center', padding: '5px 12px' };

    const popupRef = React.useRef(null);

    const [isOpen, setIsOpen] = React.useState(false);

    const mouseClickHandler = React.useCallback((e: Event) => {
        const path = e.composedPath();
        const pathMatch = path.findIndex(x => x === popupRef.current) >= 0;
        if (!pathMatch) {
            setIsOpen(false);
        }
    }, [popupRef, setIsOpen]);

    const onClick = (o: SelectOption) => {
        callback(o.key);
        setIsOpen(false);
    }

    React.useEffect(() => {
        if (isOpen) {
            enableClickHandler(mouseClickHandler);
        } else {
            disableClickHandler(mouseClickHandler);
        }
    }, [isOpen, popupRef])

    return <div className={`dropdown ${isOpen ? ' open' : ''} ${className ? className : 'form-control'}`} style={styleOverride ? styleOverride : { padding: '0' } }>
        <button type="button" className={buttonClassOverride ? buttonClassOverride : "btn btn-plain"} id={id} aria-haspopup="true" aria-expanded={isOpen ? 'true' : 'false'} style={btnStyle} disabled={disabled} onClick={e => clickHandler(e, () => setIsOpen(!isOpen)) }>
            <div className='text-left' style={({ flex: '1 1 auto', overflow: 'hidden' })}>{selectedItem ? <>{optionRenderer(selectedItem)}</> : <>&nbsp;</>}</div>
            <div className="caret" style={{ marginLeft: '8px' }}></div>
        </button>
        <ul key={`${id}_options`} className="dropdown-menu" aria-labelledby="{id}" ref={popupRef}>
            {options.map(o => <li key={o.key}><a href="#" onClick={e => clickHandler(e, () => onClick(o))}>{optionRenderer(o)}</a></li>)}
        </ul>
    </div>
}

export interface MultiSelectOption extends SelectOption {
    selected: boolean;
}

export interface MultiSelectProps {
    id: string;
    options: MultiSelectOption[];
    renderSelections?: (options: MultiSelectOption[]) => JSX.Element;
    renderOption?: (option: MultiSelectOption) => JSX.Element | string;
    callback(itemKey: string, selected: boolean): void;
    styleOverride?: React.CSSProperties;
    labelKey: string;
    labelContent?: JSX.Element
    labelStyle?: React.CSSProperties;
    hintKey?: string;
    classNames?: string;
    style?: React.CSSProperties;
    helpTextKey?: string;
    disabled?: boolean;
}

export const MultiSelect = (props: MultiSelectProps, context: ITranslationContext) => {
    const { t } = context;
    const { id, options, renderSelections, renderOption, classNames, style, labelKey, labelContent, labelStyle, hintKey, styleOverride, callback } = props;

    const selectionsRenderer = renderSelections || ((opts: MultiSelectOption[]) => opts.filter(o => o.selected).map(o => <span key={o.key} className='sep'>{o.name}</span>))
    const optionRenderer = renderOption || (o => o.name);
    const btnStyle: React.CSSProperties = { width: '100%', display: 'flex', alignItems: 'center', padding: '5px 12px', minHeight: '32px' };

    const popupRef = React.useRef(null);

    const [isOpen, setIsOpen] = React.useState(false);

    const mouseClickHandler = React.useCallback((e: Event) => {
        const path = e.composedPath();
        const pathMatch = path.findIndex(x => x === popupRef.current) >= 0;
        if (!pathMatch) {
            setIsOpen(false);
        }
    }, [popupRef, setIsOpen]);

    const onClick = (o: MultiSelectOption) => {
        callback(o.key, !o.selected);
        setIsOpen(false);
    }

    React.useEffect(() => {
        if (isOpen) {
            enableClickHandler(mouseClickHandler);
        } else {
            disableClickHandler(mouseClickHandler);
        }
    }, [isOpen, popupRef])

    const cls = `${(classNames || '')} form-group`;
    const hint = hintKey ? <p><Hint text={t(hintKey)} /></p> : null;
    const lblText = localize(labelKey, t);
    const label = isNullOrEmpty(lblText) ? null : <label htmlFor={id} style={labelStyle}>{lblText}{labelContent}</label>;

    return (
        <div className={cls} style={style}>
            <div className='control-wrapper'>
                {label}
                <div className={`dropdown ${isOpen ? ' open' : ''} form-control`} style={styleOverride ? { ...styleOverride, height: 'auto' } : { padding: '0', height: 'auto' }}>
                    <button type="button" className='btn btn-plain' id={id} aria-haspopup="true" aria-expanded={isOpen ? 'true' : 'false'} style={btnStyle} onClick={e => clickHandler(e, () => setIsOpen(!isOpen))}>
                        <div className='text-left' style={({ flex: '1 1 auto', overflow: 'none', display: 'flex', flexWrap: 'wrap' })}>{selectionsRenderer(options)}</div>
                        <div className="caret" style={{ marginLeft: '8px' }}></div>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="{id}" ref={popupRef}>
                        {options.map(o => <li key={o.key} style={{ whiteSpace: 'nowrap' }}>
                            <label>
                                <input type='checkbox' id={`${o.key}_check`} checked={o.selected} onChange={() => onClick(o)} style={{ margin: '0 6px'}} />{optionRenderer(o)}
                            </label>
                        </li>)}
                    </ul>
                </div>
                {hint}
            </div>
        </div>);
}

MultiSelect.contextTypes = {
    t: PropTypes.func
}

export interface TimeProps extends BasicInputProps<gt.Time | null> {
    timeFormat?: TimeFormat;
}

export const mapTimeFormat = (timeFormat?: boolean | string | TimeFormat) => {
    if (typeof (timeFormat) === 'string') return timeFormat;
    if (typeof (timeFormat) === 'boolean') return timeFormat;
    if (timeFormat && timeFormat === TimeFormat.TwelveHour) return "h:mm a";
    return "HH:mm";
}

export const mapDateFormat = (dateFormat?: boolean | string | DateFormat) => {
    if (typeof (dateFormat) === 'string') return dateFormat;
    if (typeof (dateFormat) === 'boolean') return dateFormat;
    if (dateFormat && dateFormat === DateFormat.MDY) return "M/DD/YYYY";
    return "D/MM/YYYY";
}

const mapLongDateFormat = (dateFormat?: boolean | string | DateFormat) => {
    if (typeof (dateFormat) === 'string') return dateFormat;
    if (typeof (dateFormat) === 'boolean') return dateFormat;
    if (dateFormat && dateFormat === DateFormat.MDY) return "MMM D YYYY";
    return "D MMM YYYY";
}

export const Time = (props: TimeProps, context: ITranslationContext) => {

    const onTimeChanged = (val: string | moment.Moment | React.ChangeEvent<any>) => {
        if (moment.isMoment(val)) {
            const tm = val as moment.Moment;
            const time = new gt.Time(tm.hour(), tm.minute(), 0);
            props.callback(time);
        } else if (typeof (val) === "string" && val === "") {
            props.callback(null);
        }
    }

    const { t } = context;
    const { id, value, classNames, style, labelKey, labelContent, labelStyle, hintKey, disabled, minimal } = props;
    const hint = hintKey ? <p><Hint text={t(hintKey)} /></p> : null;

    const showFeedback = value.hasValidation;
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : minimal ? '' : 'has-feedback'}`;
   
    const tm = value.value;
    const time = tm ? moment({ h: tm.getHours(), m: tm.getMinutes(), s: tm.getSeconds() }) : undefined;
    const dateTimeProps = { id: id, disabled: (disabled || false) };

    const lblText = localize(labelKey, t);
    const label = isNullOrEmpty(lblText) ? null : <label htmlFor={id} className='time-label' style={labelStyle}>{lblText}{labelContent}</label>;

    const timeFormat = mapTimeFormat(props.timeFormat);

    return (
        <div className={cls} style={style} >
            <div className='control-wrapper'>
                {label}
                <div>
                    <Datetime value={time} onChange={val => onTimeChanged(val)} dateFormat={false} className='rdt-inline' inputProps={dateTimeProps} timeFormat={timeFormat} />
                </div>
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />                {hint}
            </div>
        </div>);
}

Time.contextTypes = {
    t: PropTypes.func
}

export const DatePicker = (props: DatePickerProps, context: ITranslationContext) => {

    const onDateChanged = (val: string | moment.Moment | React.ChangeEvent<any>) => {
        props.callback(moment.isMoment(val) ? val : null);
    }

    const { t } = context;
    const { hintKey, id, value, classNames, labelKey, labelContent, labelStyle, helpTextKey, style, disabled, callback, initialViewMode, dateFormat, timeFormat, minimal, ...otherProps } = props;

    const hint = hintKey ? <Hint text={t(hintKey)} /> : null;

    const showFeedback = value.hasValidation;
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : 'has-feedback'}`;
    const feedbackCls = `glyphicon ${value.isValid ? '' : ' glyphicon-exclamation-sign'} form-control-feedback`;
    const feedbackGlyph = showFeedback ? <span className={feedbackCls}></span> : null;
   
    const help = helpTextKey ? <Help text={t(helpTextKey)} /> : null;

    const dt = value.value;
    const date = dt ? moment(dt) : undefined;
    const dateTimeProps = { disabled: (disabled || false) };
    const viewMode = initialViewMode ? initialViewMode : 'days';

    const tmFormat = typeof (props.timeFormat) === 'undefined' ? false : mapTimeFormat(props.timeFormat);
    const dtFormat = typeof (props.dateFormat) === 'undefined' ? false : mapLongDateFormat(props.dateFormat);

    return (
        <div className={cls} style={style}>
            <div className='control-wrapper'>
                {isNullOrEmpty(labelKey) ? null : <label htmlFor={id} style={labelStyle}>{localize(labelKey, t)}{labelContent}</label>}
                {help}
                <Datetime value={date} onChange={val => onDateChanged(val)} timeFormat={tmFormat} closeOnSelect={true} inputProps={dateTimeProps} dateFormat={dtFormat} initialViewMode={viewMode} {...otherProps} />
                {feedbackGlyph}
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />
                {hint}
            </div>
        </div>);
}

DatePicker.contextTypes = {
    t: PropTypes.func
}

export interface DateRange { from: moment.Moment | null; to: moment.Moment | null; }

export interface DateRangePickerProps extends BasicInputProps<DateRange> {
    dateFormat?: boolean | string | DateFormat;
    timeFormat?: boolean | string | TimeFormat;
    viewMode?: Datetime.ViewMode;
}

export const DateRangePicker = (props: DateRangePickerProps, context: ITranslationContext) => {

    const onFromDateChanged = (val: string | moment.Moment | React.ChangeEvent<any>) => {
        const { value, callback } = props;
        callback({ from: moment.isMoment(val) ? val : null, to: value.value.to });
    }

    const onToDateChanged = (val: string | moment.Moment | React.ChangeEvent<any>) => {
        const { value, callback } = props;
        callback({ from: value.value.from, to: moment.isMoment(val) ? val : null });
    }

    const { t } = context;
    const { hintKey, id, value, classNames, labelKey, labelContent, labelStyle, helpTextKey, style, disabled, minimal, viewMode } = props;

    const hint = hintKey ? <Hint text={t(hintKey)} /> : null;

    const showFeedback = value.hasValidation;
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : ''}`;
    const feedbackCls = `glyphicon ${value.isValid ? '' : ' glyphicon-exclamation-sign'} form-control-feedback`;
    const feedbackGlyph = showFeedback ? <span className={feedbackCls}></span> : null;

    const help = helpTextKey ? <Help text={t(helpTextKey)} /> : null;

    const fromDt = value.value.from;
    const fromDate = fromDt ? moment(fromDt) : undefined;

    const toDt = value.value.to;
    const toDate = toDt ? moment(toDt) : undefined;

    const dateTimeProps = { disabled: (disabled || false) };

    const timeFormat = mapTimeFormat(props.timeFormat);
    const dateFormat = mapDateFormat(props.dateFormat);
    return (
        <div className={cls} style={style}>
            <div className='control-wrapper'>
                {isNullOrEmpty(labelKey) ? null : <label htmlFor={id} style={labelStyle}>{localize(labelKey, t)}{labelContent}</label>}
                {help}
                <div style={({ display: 'flex', flexDirection: 'row', alignItems: 'center' })}>
                    <div style={({ flex: '1 auto' })}>
                        <Datetime value={fromDate} onChange={val => onFromDateChanged(val)} timeFormat={timeFormat} dateFormat={dateFormat} initialViewMode={viewMode} closeOnSelect={true} inputProps={dateTimeProps} />
                    </div>
                    <div style={({ margin: '0 5px' })}>{t('Global:to')}</div>
                    <div style={({ flex: '1 auto' })}>
                        <Datetime value={toDate} onChange={val => onToDateChanged(val)} timeFormat={timeFormat} dateFormat={dateFormat} initialViewMode={viewMode} closeOnSelect={true} inputProps={dateTimeProps} />
                    </div>
                </div>
                {feedbackGlyph}
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />
                {hint}
            </div>
        </div>);
}

DateRangePicker.contextTypes = {
    t: PropTypes.func
}


export const DateTimePicker = (props: DateTimePickerProps, context: ITranslationContext) => {

    const onDateChanged = (val: string | moment.Moment | React.ChangeEvent<any>) => {
        const { value, callback } = props;
        if (moment.isMoment(val)) {
            const newValArg = { year: val.year(), month: val.month(), date: val.date(), hour: moment.isMoment(value.value) ? value.value.hour() : 0, minute: moment.isMoment(value.value) ? value.value.minute() : 0 };
            const newVal = moment(newValArg);
            callback(newVal);
        } else {
            callback(null);
        }
    }

    const onTimeChanged = (val: string | moment.Moment | React.ChangeEvent<any>) => {
        const { value, callback } = props;

        if (moment.isMoment(value.value)) {
            const newValArg = { year: value.value.year(), month: value.value.month(), date: value.value.date(), hour: moment.isMoment(val) ? val.hour() : 0, minute: moment.isMoment(val) ? val.minute() : 0 };
            const newVal = moment(newValArg);
            callback(newVal);
        }
        else {
            callback(null);
        }
    }

    const { t } = context;
    const { hintKey, id, value, classNames, labelKey, labelContent, labelStyle, helpTextKey, style, disabled, minimal } = props;

    const hint = hintKey ? <Hint text={t(hintKey)} /> : null;

    const showFeedback = value.hasValidation;
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : ''}`;
    const feedbackCls = `glyphicon ${value.isValid ? '' : ' glyphicon-exclamation-sign'} form-control-feedback`;
    const feedbackGlyph = showFeedback ? <span className={feedbackCls}></span> : null;
    
    const help = helpTextKey ? <Help text={t(helpTextKey)} /> : null;

    const val = value.value ? value.value : undefined;

    const dateProps = { disabled: (disabled || false) };
    const timeProps = { disabled: (disabled || !moment.isMoment(val)) };

    const timeFormat = mapTimeFormat(props.timeFormat);
    const dateFormat = mapDateFormat(props.dateFormat);

    return (
        <div className={cls} style={style}>
            <div className='control-wrapper'>
                {isNullOrEmpty(labelKey) ? null : <label htmlFor={id} style={labelStyle}>{localize(labelKey, t)}{labelContent}</label>}
                {help}
                <div style={({ display: 'flex', flexDirection: 'row', alignItems: 'center' })}>
                    <div style={({ flex: '1 auto' })}>
                        <Datetime value={val} onChange={val => onDateChanged(val)} timeFormat={false} dateFormat={dateFormat} initialViewMode='days' closeOnSelect={true} inputProps={dateProps} />
                    </div>
                    <div style={({ flex: '1 auto' })}>
                        <Datetime value={val} onChange={val => onTimeChanged(val)} timeFormat={timeFormat} dateFormat={false} initialViewMode='time' closeOnSelect={true} inputProps={timeProps} />
                    </div>
                </div>
                {feedbackGlyph}
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t} />
                {hint}
            </div>
        </div>);
}

DateTimePicker.contextTypes = {
    t: PropTypes.func
}

export const TimeInterval = (props: TypedInputProps<Interval>, context: ITranslationContext) => {

    const onValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, callback } = props;
        callback({ value: event.currentTarget.valueAsNumber, unit: value.value.unit });
    }

    const onUnitChanged = (val: string) => {
        const { value, callback } = props;
        
        callback({ value: value.value.value, unit: parseInt(val) });
    }

    const { t } = context;
    const { hintKey, id, value, classNames, labelKey, labelContent, labelStyle, helpTextKey, style, minimal } = props;

    const hint = hintKey ? <Hint text={t(hintKey)} /> : null;

    const showFeedback = value.hasValidation;
    const cls = `${(classNames || '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : ''}`;
    const feedbackCls = `glyphicon ${value.isValid ? '' : ' glyphicon-exclamation-sign'} form-control-feedback`;
    const feedbackGlyph = showFeedback ? <span className={feedbackCls}></span> : null;
  
    const help = helpTextKey ? <Help text={t(helpTextKey)} /> : null;
    const unitOptions = Object.keys(IntervalUnit).filter(key => typeof IntervalUnit[key as any] === 'number').map(key => ({ key: IntervalUnit[key as any].toString(), name: t(`IntervalUnit:${key}`) }));

    return (
        <div className={cls} style={style}>
            <div className='control-wrapper'>
                {isNullOrEmpty(labelKey) ? null : <label htmlFor={id} style={labelStyle}>{localize(labelKey, t)}{labelContent}</label>}
                {help}
                <div style={({ display: 'flex', flexDirection: 'row', alignItems: 'center' })}>
                    <div style={({ flex: '1 auto' })}>
                        <input id={`${id}_value`} className='form-control' type='number' value={value.value.value} onChange={onValueChanged} />
                    </div>
                    <div style={({ flex: '1 auto' })}>
                        <PlainSelect id={`${id}_unit`} value={value.value.unit.toString()} options={unitOptions} callback={onUnitChanged} />
                    </div>
                </div>
                {feedbackGlyph}
                <ErrorMsg isValid={value.isValid} minimal={minimal} errorMessageKey={value.errorMessageKey} t={t}  />
                {hint}
            </div>
        </div>);
}

TimeInterval.contextTypes = {
    t: PropTypes.func
}

export interface ColoursProps {
    colours: string[];
    selectedColour: string;
    colourChanged: (colour: string) => void;
}

export const Colours = (props: ColoursProps, context: ITranslationContext) => {
    const colourSelection = props.colours.map((c, ix) => {
        const colourStyle = { backgroundColor: c };
        // eslint-disable-next-line
        return <a key={ix.toString()} className='colour-selection' style={colourStyle} onClick={e => linkHandler(e, () => props.colourChanged(c))}>
            <div className={`${props.selectedColour === c ? 'colour-selection-selected' : ''}`}></div></a>;
    });

    const errMsgStyle = { display: 'inline' };
    const errorMsg = <span className='help-block' style={errMsgStyle}>{isNullOrEmpty(props.selectedColour) ? context.t('Global:selectColourError') : ''}</span>;

    return (<div className={`form-group ${isNullOrEmpty(props.selectedColour) ? 'has-feedback has-error' : ''}`}>
        {colourSelection}
        {errorMsg}
    </div>);
}

Colours.contextTypes = {
    t: PropTypes.func
}


export interface HtmlInputProps extends BasicInputProps<string> {
    modules?: Quill.StringMap;
    quillClassName?: string;
    inlineStyles?: boolean;
    editorStyle?: React.CSSProperties;
}

export const HtmlInput = (props: HtmlInputProps, context: ITranslationContext) => {

    const htmlChanged = (val: string, delta: Delta, source: Quill.Sources, editor: any) => {
        if (source === 'user') {
            props.callback(val);
        }
    }

    const { t } = context;
    const { id, value, classNames, style, labelKey, labelContent, labelStyle, hintKey, helpTextKey, modules, quillClassName, editorStyle, inlineStyles } = props;
    const hint = !isNullOrEmpty(hintKey) ? <span className='help-block'>{t(hintKey)}</span> : null;

    const showFeedback = value.hasValidation;
    const cls = `${(classNames ? classNames : '')} form-group ${showFeedback ? ` has-feedback${value.isValid ? ' has-success' : ' has-error'}` : 'has-feedback'}`;
    const errMsgStyle = { display: 'inline' };
    const errorMsg = value.isValid ? null : <span className='help-block' style={errMsgStyle}>{t(value.errorMessageKey)}</span>;

    const mods = modules ? modules : quillStandardToolbar;

    const quillCls = quillClassName ? quillClassName : 'quill';

    const help = helpTextKey ? <Help text={t(helpTextKey)} /> : null;

    if (inlineStyles) {
        // configure Quill to use inline styles so the email's format properly
        var DirectionAttribute = ReactQuill.Quill.import('attributors/attribute/direction');
        ReactQuill.Quill.register(DirectionAttribute, true);

        var AlignClass = ReactQuill.Quill.import('attributors/class/align');
        ReactQuill.Quill.register(AlignClass, true);

        var BackgroundClass = ReactQuill.Quill.import('attributors/class/background');
        ReactQuill.Quill.register(BackgroundClass, true);

        var ColorClass = ReactQuill.Quill.import('attributors/class/color');
        ReactQuill.Quill.register(ColorClass, true);

        var DirectionClass = ReactQuill.Quill.import('attributors/class/direction');
        ReactQuill.Quill.register(DirectionClass, true);

        var FontClass = ReactQuill.Quill.import('attributors/class/font');
        ReactQuill.Quill.register(FontClass, true);

        var SizeClass = ReactQuill.Quill.import('attributors/class/size');
        ReactQuill.Quill.register(SizeClass, true);

        var AlignStyle = ReactQuill.Quill.import('attributors/style/align');
        ReactQuill.Quill.register(AlignStyle, true);

        var BackgroundStyle = ReactQuill.Quill.import('attributors/style/background');
        ReactQuill.Quill.register(BackgroundStyle, true);

        var ColorStyle = ReactQuill.Quill.import('attributors/style/color');
        ReactQuill.Quill.register(ColorStyle, true);

        var DirectionStyle = ReactQuill.Quill.import('attributors/style/direction');
        ReactQuill.Quill.register(DirectionStyle, true);

        var FontStyle = ReactQuill.Quill.import('attributors/style/font');
        ReactQuill.Quill.register(FontStyle, true);

        var SizeStyle = ReactQuill.Quill.import('attributors/style/size');
        ReactQuill.Quill.register(SizeStyle, true);
    }
    // create new Quill instance here...

    return (
        <div className={cls} style={({ ...style, maxWidth: '1000px' })}>
            <div className='control-wrapper'>
                {!isNullOrEmpty(labelKey) ? <label htmlFor={id} style={labelStyle}>{localize(labelKey, t)}{labelContent}</label> : null}
                {help}
                <ReactQuill className={quillCls} value={value.value} onChange={htmlChanged} modules={mods} editorStyle={editorStyle} />
                {errorMsg}
                {hint}
            </div>
        </div>
    );
}

HtmlInput.contextTypes = {
    t: PropTypes.func
}