
export enum PublicWebStepCategory {
    None = 0,
    Booking = 1,
    Registration = 2,
    Payment = 4,
    All = 0b11111111111111111111111111111111
}

export enum PublicWebStep {
    None = 0,
    SiteHome = 1,
    Privacy = 2,
    Error = 3,
    ProductSelection = 110,
    CheckAvailability = 120,
    GetAvailability = 125,
    SelectAvailability = 130,
    AdditionalProducts = 140,
    SelectAdditionalProducts = 145,
    AdditionalFields = 150,
    SaveAdditionalFields = 155,
    BookingSummary = 160,
    ConfirmBookingSummary = 165,
    CustomerEmail = 170,
    CheckCustomerEmail = 175,
    ExistingCustomer = 180,
    SelectExistingCustomer = 185,
    SendLoginLink = 190,
    LoginWithCode = 195,
    NewCustomer = 200,
    SubmitNewCustomer = 205,
    BookingPayment = 220,
    InitiatePayment = 225,
    BookingPayWithPaymentsense = 240,
    RetryPaymentsensePayment = 241,
    CompleteBookingPaymentsensePayment = 245,
    BookingPayWithLibertyPay = 246,
    RetryLibertyPayPayment = 247,
    CompleteBookingLibertyPayPayment = 248,
    PayBookingWithStripe = 250,
    RetryBookingStripePayment = 251,
    HandleBookingStripePaymentResponse = 255,
    CheckForBookingStripePaymentResponse = 256,
    ViewBookingTerms = 260,
    BookingComplete = 280,
    BookingExpired = 299,
    RestartBooking = 300,
    Payment = 350,
    PaymentsensePayment = 360,
    PaymentsenseResponse = 362,
    LibertyPayPayment = 363,
    LibertyPayResponse = 364,
    StripePayment = 370,
    StripePaymentResponse = 372,
    StartRegistration = 500,
    RegistrationCheckEmail = 510,
    RegistrationExistingCustomer = 520,
    RegistrationExistingCustomerSelection = 525,
    RegistrationSendLoginLink = 530,
    RegistrationLoginWithCode = 540,
    RegistrationNewCustomer = 550,
    RegistrationCreateNewCustomer = 555,
    RegistrationCustomerDetails = 560,
    RegistrationSaveCustomerDetails = 565,
    RegistrationContactPreferences = 570,
    RegistrationUpdateContactPreferences = 575,
    RegistrationConfirmParticipants = 580,
    RegistrationUpdateParticipants = 585,
    RegistrationSelectChildren = 590,
    RegistrationRegisterChildren = 595,
    RegistrationBriefing = 600,
    RegistrationConfirmBriefing = 605,
    RegistrationTermsAndConditions = 610,
    RegistrationConfirmTermsAndConditions = 615,
    RegistrationComplete = 620
}