
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

import { ITranslationContext } from '../../../translations';
import { isNullOrEmpty } from '../../../utils/util';
import * as ht from './types'
import { PaymentStatus } from '../../../store/pages/pointOfSale/types';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface RecentPaymentProps {
    pymt: ht.RecentPayment
    paidDateTime: Date | null
    selectedVenueId: string;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

const RecentPayment = (props: RecentPaymentProps, context: ITranslationContext) => {
    const { pymt, paidDateTime, selectedVenueId, timeFormat, dateFormat } = props;
    const { t } = context;

    let status: JSX.Element | null = null;
    if (pymt.void) {
        status = <span className='label label-danger'>{t('PointOfSale:voidPayment')}</span>
        //} else if (pymt.gatewayPaymentStatus !== null && pymt.gatewayPaymentStatus !== GatewayPaymentStatus.None && pymt.gatewayPaymentStatus !== GatewayPaymentStatus.Success) {
        //    status = <span className='label label-warning'>{t(`GatewayPaymentStatus:${GatewayPaymentStatus[pymt.gatewayPaymentStatus]}`)}</span>
    } else if (pymt.paymentStatus !== PaymentStatus.None && pymt.paymentStatus !== PaymentStatus.Success && pymt.paymentStatus !== PaymentStatus.Scheduled) {
        status = <span className={`label label-${pymt.paymentStatus === PaymentStatus.GatewayCancelled ? 'danger' : 'warning'}`}>{t(`PaymentStatus:${PaymentStatus[pymt.paymentStatus]}`)}</span>
    }

    return <li key={`${pymt.paymentId}_row1`}>
        <div className='row'>
            <div className='col-md-4 col-xs-4'>{paidDateTime ? paidDateTime.toAbbrDateTimeString(timeFormat, dateFormat, t) : '-'} {status}</div>
            <div className='col-md-5 col-xs-5'>{pymt.paymentDescription}</div>
            <div className='col-md-3 col-xs-3 text-right'>{t('Global:currencySymbol')}{pymt.paymentAmount.toFixed(2)}</div>
        </div>
        <div className='row small'>
            <div className='col-md-6 col-xs-6'>{pymt.paymentMethodName}</div>
            <div className='col-md-6 col-xs-6 text-right'>{pymt.paidBy}</div>
        </div>
        <div className='row small'>
            <div className='col-md-12 text-center'>{formatInfo(pymt)}</div>
        </div>
        <div className='row small'>
            <div className='col-md-6 col-xs-6'>{pymt.eventId ? <Link to={{ pathname: `/diary/${selectedVenueId}/event/${pymt.eventId}`, state: { bookingId: pymt.bookingId } }}>{pymt.eventName}</Link> : formatPurchaseInfo(pymt, t)}</div>
            <div className='col-md-6 col-xs-6 text-right'>{pymt.eventDate ? pymt.eventDate.toAbbrDateTimeString(timeFormat, dateFormat, t) : null}</div>
        </div>
    </li>
}

const formatInfo = (payment: ht.RecentPayment) => {
    return [`${payment.customerFirstName} ${payment.customerLastName}`, payment.companyName, payment.customerPhoneNumber, payment.customerEmailAddress]
        .filter(x => !isNullOrEmpty(x))
        .join(' | ');
}

const formatPurchaseInfo = (payment: ht.RecentPayment, t: (key: any, params?: any, comment?: string) => string) => {
    if (payment.isVoucherPurchase) return t('RecentPayment.voucher');
    else if (payment.isMembershipPurchase) return t('RecentPayment.membership');
    return '';
}


RecentPayment.contextTypes = {
    t: PropTypes.func
}

export default RecentPayment;