
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import * as PropTypes from 'prop-types'
import { ApplicationState } from '../../../store';
import * as ct from '../../../store/pages/campaigns/types';
import * as CampaignActions from '../../../store/pages/campaigns/actions';
import Loading from '../../global/loading';
import CampaignState from './campaignState';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';
import { formatPercentage } from '../../../utils/util';

interface LocalProps { }

interface LocalState {
    isLoading: boolean;
    campaigns: ct.MarketingCampaign[];
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
}

interface Actions {
    loadCampaigns: (showComplete: boolean) => void;
}

interface CampaignsPageState
{
    showComplete: boolean
}

type CampaignsPageProps = LocalState & Actions & LocalProps;

class CampaignsPage extends React.Component<CampaignsPageProps, CampaignsPageState> {

    constructor(props: CampaignsPageProps) {
        super(props);

        this.state = { showComplete: false };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    reloadCampaigns = () => this.props.loadCampaigns(this.state.showComplete);

    componentDidMount() {
        const { campaigns, isLoading, loadCampaigns } = this.props;

        if (!isLoading && campaigns.length === 0) {
            loadCampaigns(this.state.showComplete);
        }
    }

    toggleShowComplete = (showComplete: boolean) => this.setState({ showComplete: showComplete }, () => this.props.loadCampaigns(showComplete));

    render() {
        const { isLoading } = this.props;
        const { showComplete } = this.state;
        const { t } = this.context;

        const body = isLoading ? <Loading /> : this.renderCampaignsTable();

        return <section className='campaigns-page'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='campaigns_title'>{this.context.t('CampaignsPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <label>
                        <input type='checkbox' checked={showComplete} onChange={e => this.toggleShowComplete(e.currentTarget.checked)} />
                        <span style={({ marginLeft: '10px' })}>{t('Global:showComplete')}</span>
                    </label>
                    <Link className='btn btn-primary' to='/campaign/new' >{t('CampaignsPage:addCampaign')}</Link>
                </div>
            </header>
            <div className='clearfix' />
            {body}
        </section>;
    }

    campaignComparer = (c1: ct.MarketingCampaign, c2: ct.MarketingCampaign) => {
        if (c1.state !== c2.state) return c1.state - c2.state;
        return c2.startTime.getTime() - c1.startTime.getTime();
    }

    renderCampaignsTable = () => {
        const { t } = this.context;
        const { campaigns, timeFormat, dateFormat } = this.props;

        const rows = campaigns.sort(this.campaignComparer).map(c =>
            <tr key={c.id}>
                <td><Link to={`/campaign/${c.id}/view`} className='pull-left'>{c.name}</Link></td>
                <td>{t(`MarketingCampaignRunInterval:${ct.MarketingCampaignRunInterval[c.runInterval]}`)}</td>
                <td><CampaignState state={c.state} /></td>
                <td>{c.startTime.toShortDateString(dateFormat)}</td>
                <td>{c.endTime ? c.endTime.toShortDateString(dateFormat) : null}</td>
                <td>{c.nextRunTime ? c.nextRunTime.toAbbrDateTimeString(timeFormat, dateFormat, t) : null}</td>
                <td className='text-right'><span className='campaign-total'>{c.totalEmails}</span></td>
                <td className='text-right'><span className='campaign-total'>{c.openedEmails}</span> {formatPercentage(c.openedEmails, c.totalEmails)}</td>
                <td className='text-right'><span className='campaign-total'>{c.clickedEmails}</span> {formatPercentage(c.clickedEmails, c.totalEmails)}</td>
                <td className='text-right'><span className='campaign-total'>{c.bouncedEmails}</span> {formatPercentage(c.bouncedEmails, c.totalEmails)}</td>
                <td><Link className='btn btn-sm btn-primary' to={{ pathname: '/campaign/new', state: { copyFrom: c } }}>{t('Global:copy')}</Link></td>
            </tr>
        );

        return <table className='table table-condensed'>
            <thead>
                <tr key='campaigns-header'>
                    <th>{t('CampaignsPage:nameHeading')}</th>
                    <th>{t('CampaignsPage:runIntervalHeading')}</th>
                    <th>{t('CampaignsPage:statusHeading')}</th>
                    <th>{t('CampaignsPage:startHeading')}</th>
                    <th>{t('CampaignsPage:endHeading')}</th>
                    <th>{t('CampaignsPage:nextRunHeading')}</th>
                    <th className='text-right'>{t('CampaignsPage:sentHeading')}</th>
                    <th className='text-right'>{t('CampaignsPage:openedHeading')}</th>
                    <th className='text-right'>{t('CampaignsPage:clickedHeading')}</th>
                    <th className='text-right'>{t('CampaignsPage:bouncedHeading')}</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;
    }
};

const mapStateToProps = (state: ApplicationState) => {
    const venueId = state.venues.selectedVenueId;
    const venue = state.venues.venues.find(v => v.id === venueId);
    return {
        isLoading: state.campaigns.isLoading,
        campaigns: state.campaigns.campaigns,
        timeFormat: venue ? venue.timeFormat : TimeFormat.TwentyFourHour
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadCampaigns: bindActionCreators(CampaignActions.actionCreators.loadCampaigns, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignsPage);

