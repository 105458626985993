
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import * as api from '../../../store/apiClient';
import ApiError from '../../global/apiError';
import { ApplicationState } from '../../../store';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { VenuePublicWebsiteSettings } from '../../../store/pages/publicWebsiteSettings/types';
import * as PublicWebsiteSettingsActions from '../../../store/pages/publicWebsiteSettings/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import { clickHandler } from '../../../utils/util';
import { ValidationError } from '../../../store/global/types';

interface LocalProps {
    venueId: string;
    publicWebsiteSettings: VenuePublicWebsiteSettings;
    close: () => void;
}

interface MappedState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

interface MappedActions {
    savePublicWebsiteSettings: (publicWebsiteSettings: VenuePublicWebsiteSettings, backgroundImg: File | null, headerImg: File | null)  => void;
}

type PaymentPageFormProps = MappedState & MappedActions & LocalProps;

interface PaymentPageFormState {
    heading: ct.FormValue<string>;
    body: ct.FormValue<string>;
    payButtonText: ct.FormValue<string>;
}

class PaymentPageForm extends React.Component<PaymentPageFormProps, PaymentPageFormState> {

    constructor(props: PaymentPageFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: PaymentPageFormProps): PaymentPageFormState {

        const payPageSettings = this.props.publicWebsiteSettings.paymentPage;

        return {
            heading: this.validateHeading(payPageSettings.heading),
            body: this.validateBody(payPageSettings.body),
            payButtonText: this.validatePayButtonText(payPageSettings.payButtonText),
        };
    }

    pageBodyChanged = (val: string) => this.setState({ body: this.validateBody(val) });

    validateHeading = (val: string) => v.validate(val, 'heading', [v.required], this.props.validationErrors);
    validateBody = (val: string) => v.validate(val, 'body', [v.required], this.props.validationErrors);
    validatePayButtonText = (val: string) => v.validate(val, 'payButtonText', [v.required], this.props.validationErrors);

    componentDidUpdate(prevProps: PaymentPageFormProps) {
        // Only update state if resource has changed
        const { saveComplete: prevSaveComplete } = prevProps;
        const { saveComplete } = this.props;
        const prevPayPageSettings = prevProps.publicWebsiteSettings.paymentPage;
        const nextpayPageSettings = this.props.publicWebsiteSettings.paymentPage;

        if (prevPayPageSettings.heading !== nextpayPageSettings.heading || prevPayPageSettings.body !== nextpayPageSettings.body || prevPayPageSettings.payButtonText !== nextpayPageSettings.payButtonText) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevSaveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private savePaymentPageSettings = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.close();
    }

    private save = () => {
        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {
            const { publicWebsiteSettings } = this.props;
            const { heading, body, payButtonText } = this.state;

            const websiteSettings = {
                ...publicWebsiteSettings,
                paymentPage: { heading: heading.value, body: body.value, payButtonText: payButtonText.value, bodyDataTags: [] }
            };

            this.props.savePublicWebsiteSettings(websiteSettings, null, null);
        }
    }

    render() {
        const t = this.context.t;
        const { saveError, saveComplete } = this.props;
        const dataTags = this.props.publicWebsiteSettings.paymentPage.bodyDataTags;
        const { heading, body, payButtonText } = this.state;

        const message = saveError ? <ApiError error={saveError} /> : saveComplete ? <div className='bg-success'>{t('Global:saveComplete')}</div> : null;
 
        return <div className='paymentPageForm'>
            <h2 className='paymentPageForm_title'>{t('PublicWebsitePageList:title')}</h2>

            <div className='row'>
                <div className='col-md-8'>
                    <form className='data-form' onSubmit={this.savePaymentPageSettings} autoComplete='off'>
                        <ct.TextBox id='heading' labelKey='PaymentPageForm:heading' placeholderKey='PaymentPageForm:heading' value={heading} callback={val => this.setState({ heading: this.validateHeading(val) })} />

                        <ct.HtmlInput id='body' labelKey='PaymentPageForm:body' value={body} callback={this.pageBodyChanged} inlineStyles={true} />

                        <ct.TextBox id='payButtonText' labelKey='PaymentPageForm:payButtonText' placeholderKey='PaymentPageForm:payButtonText' value={payButtonText} callback={val => this.setState({ payButtonText: this.validatePayButtonText(val) })} />

                        {message}

                        <p />
                        <div className='btn-toolbar'>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                            <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                        </div>
                    </form>

                    <h4 style={({marginTop: '20px'})}>{t('Global:preview')}</h4>
                    <div className='panel  panel-default'>
                        <div className='panel-body'>
                            <section>
                                <h1 className="display-4">{heading.value}</h1>

                                <div dangerouslySetInnerHTML={{__html: body.value}} />
                               
                                {payButtonText.value ? <input type="submit" value={payButtonText.value} className="btn btn-primary" /> : null }
                            </section>

                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='panel panel-info'>
                        <div className='panel-heading'>
                            <h3 className='panel-title'>{this.context.t('PaymentPageForm:dataTags')}</h3></div>
                        <div className='panel-body'>
                            <span>{this.context.t('PaymentPageForm:dataTagsText')}</span>
                            <ul className='plain-list'>
                                {dataTags.map(tag => <li key={tag.key}>{`{{${tag.key}}}`} <ct.Help text={this.context.t(`PaymentPageTag:${tag.key}`)} /></li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.publicWebsiteSettings.isSaving,
    saveComplete: state.publicWebsiteSettings.saveComplete,
    saveError: state.publicWebsiteSettings.saveError,
    validationErrors: state.publicWebsiteSettings.validationErrors,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    savePublicWebsiteSettings: bindActionCreators(PublicWebsiteSettingsActions.actionCreators.savePublicWebsiteSettings, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,                    // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(PaymentPageForm);
