
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../../global/controls';
import * as auth from '../../../../utils/auth';

import { PaymentGateway, PaymentGatewayType } from '../../../../store/pages/paymentGateways/types';
import { Setting } from './paymentMethodForm';
import { isNullOrEmpty, clickHandler } from '../../../../utils/util';
import ExternalWindowCoordinator from '../../../global/externalWindowCoordinator';
import GeneralGatewaySettings from './generalGatewaySettings';
import WorldpayGatewaySettings from './worldpayGatewaySettings';

interface PaymentMethodGatewaySettingsProps {
    paymentMethodIntegrationKey: string | null;
    gateway?: PaymentGateway | null;
    settings: Setting[];
    pageRoot: string;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
    closeModal: () => void;
    settingChanged: (key: string, val: string) => void;
    onPaymentMethodChanged: () => void;
}

class PaymentMethodGatewaySettings extends React.Component<PaymentMethodGatewaySettingsProps, {}> {

    static contextTypes = {
        t: PropTypes.func
    }

    retryConnectToStripe = (stripeClinetId: string) => {
        this.props.closeModal();
        this.connectToStripe(stripeClinetId);
    }

    connectToStripe = (stripeClinetId: string) => {
        const { paymentMethodIntegrationKey, pageRoot, showModal, onPaymentMethodChanged } = this.props;
        const { t } = this.context;

        const returnUrl = `${pageRoot}/stripe/connect`;

        const body = (
            <div className='pos-process-gateway-payment-panel'>
                <h4 className='text-center'>{t('PaymentMethodForm:connectingToStripeHeader')}</h4>
                <div className='text-center'>{t('PaymentMethodForm:connectingToStripeText')}</div>
            </div>
            )

        showModal(<ExternalWindowCoordinator
            urlToOpen={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClinetId}&scope=read_write&state=${paymentMethodIntegrationKey}&redirect_uri=${returnUrl}`}
            windowName='stripeConnect'
            content={body}
            popupBlockedMessageKey='PaymentMethodForm:PopupBlockedMessage'
            retryAction={() => this.retryConnectToStripe(stripeClinetId)}
            completionCallback={onPaymentMethodChanged}
        />, 'ExternalWindowCoordinator');
    }

    render() {
        const { t } = this.context;
        const { paymentMethodIntegrationKey, gateway, settings, settingChanged } = this.props;

        if (!gateway) {
            return null;
        }

        if (gateway.type === PaymentGatewayType.Stripe) {
            const userIdSettings = settings.find(s => s.key === 'Stripe user id');
            const stripeClinetIdSetting = gateway.globalSettings.find(g => g.key === 'client_id');
            const stripeClinetId = stripeClinetIdSetting && !isNullOrEmpty(stripeClinetIdSetting.unsecuredValue) ? stripeClinetIdSetting.unsecuredValue : null;
            const testModeSetting = settings.find(s => s.key === 'test_mode');
            const isTestMode = testModeSetting ? { ...testModeSetting.value, value: testModeSetting.value.value === "true" } : ct.asFormValue('testMode', false);

            if (userIdSettings && userIdSettings.hasValue && userIdSettings.value) {
                return <div>
                    <div className='label label-success' style={{ fontSize: '100%' }}>{t('PaymentMethodForm:stripeConnected')} <span>{userIdSettings.value.value}</span></div>

                    <p />
                    <ct.Checkbox id='testMode' labelKey='PaymentMethodForm:testMode' value={isTestMode} callback={val => {
                        settingChanged("test_mode", val ? "true" : "false")
                    }} />
                </div>
            } else {
                if (!stripeClinetId) {
                    return <div>{t('PaymentMethodForm:missingConfiguration')}</div>
                }

                if (!paymentMethodIntegrationKey) {
                    return <div className='alert alert-warning'>{t('PaymentMethodForm:saveBeforeStrpeConnectMessage')}</div>
                }

                return (
                    <div>
                        <div>{t('PaymentMethodForm:connectWithStripeText')}</div>
                        <div>
                            <button className='btn btn-primary' onClick={e => clickHandler(e, () => this.connectToStripe(stripeClinetId))}>{t('PaymentMethodForm:connectWithStripe')}</button>
                            <div className='alert alert-warning'>{t('PaymentMethodForm:saveBeforeConnectingStripe')}</div>
                        </div>

                        {auth.isSystemAdmin() ?
                            <div>
                                <div>{t('PaymentMethodForm:orEnterClientId')}</div>
                                {settings.filter(s => s.key === 'Stripe user id').map(s => (
                                    <ct.TextBox key={s.key} id={`setting_${s.key}`} labelKey={s.key} placeholderKey={s.hasValue && s.secure && isNullOrEmpty(s.value.value) ? '******' : s.key} value={({ ...s.value, value: (s.value.value || '') })} callback={val => settingChanged(s.key, val)} />
                                ))}
                            </div> : null}
                    </div>
                )
            }
        }

        if (gateway.type === PaymentGatewayType.WorldpayBg350) {
            return <WorldpayGatewaySettings gateway = { gateway } settings = { settings } settingChanged = { settingChanged } />
        } else {
            return <GeneralGatewaySettings gateway={gateway} settings={settings} settingChanged={settingChanged} />
        }
    }
}

export default PaymentMethodGatewaySettings;